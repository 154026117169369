import { useState, useEffect, useCallback } from "react";
import { filterCountriesByYearAndEmissions } from "../utils/dataFormatting";
import { isNotEmptyObject } from "../utils/common";
import { handleSubSectorsData } from "../utils/subSectors";
import { unzipFile } from "../utils/unzip";

const useSubSectors = (
    selectedYear,
    activeScenario,
    selectedSector,
    selectedCountry,
    countryCoordinatesAndEmissions,
    countries_stats,
    subsectors_all,
    sectors_compositions
) => {
    const [subSectors, setSubSectors] = useState({});
    const [sortedRankings, setSortedRankings] = useState([]);
    const populationSelectedYear = filterCountriesByYearAndEmissions(
        selectedYear,
        countries_stats
    ).find(({ iso3c }) => iso3c === "WLD").pop;

    const handleSubSectors = useCallback(() => {
        if (
            countryCoordinatesAndEmissions &&
            isNotEmptyObject(countryCoordinatesAndEmissions) &&
            isNotEmptyObject(selectedSector)
        ) {
            const sortedRankings = countryCoordinatesAndEmissions.features
                .map((country) => {
                    return {
                        name: country.properties.name,
                        file_url: country.file_url,
                        id: country.id,
                        population: country.population,
                        emissions: country.emissions,
                        selectedSector:
                            country.sectors?.filter(
                                ({ sector }) => sector === selectedSector.type
                            )[0][activeScenario] / country.population
                    };
                })
                .filter(({ selectedSector }) => !isNaN(selectedSector))
                .sort(
                    (countryA, countryB) =>
                        countryA?.selectedSector - countryB?.selectedSector
                );
            setSortedRankings(sortedRankings);
        }
        if (isNotEmptyObject(selectedSector) && selectedCountry.iso3c) {
            unzipFile(
                `../data/countries/subsectors_${selectedSector.iso3c}.json.gz`
            ).then(({ data }) => {
                const subSectors = handleSubSectorsData(
                    data,
                    selectedYear,
                    activeScenario,
                    selectedSector,
                    sectors_compositions
                );
                setSubSectors(subSectors);
            });
        } else if (selectedSector.type) {
            let subSectors = handleSubSectorsData(
                subsectors_all,
                selectedYear,
                activeScenario,
                selectedSector,
                sectors_compositions
            );
            subSectors = {
                ...subSectors,
                population: populationSelectedYear
            };
            setSubSectors(subSectors);
        }
    }, [
        selectedSector,
        selectedYear,
        activeScenario,
        selectedCountry,
        sectors_compositions,
        subsectors_all,
        populationSelectedYear,
        countryCoordinatesAndEmissions
    ]);

    useEffect(() => {
        handleSubSectors();
    }, [handleSubSectors]);

    return { subSectors, sortedRankings };
};

export default useSubSectors;
