import useMousePosition from "../../../../custom-hooks/useMousePosition";
import Tooltip from "./Tooltip";
import { object } from "prop-types";

const TooltipContainer = ({
    tooltipInfo,
    activeScenario,
    typeOfMapEmissions,
    areSouthAfricaSubnationalsActive
}) => {
    const position = useMousePosition();

    return (
        tooltipInfo && (
            <Tooltip
                tooltipInfo={tooltipInfo}
                activeScenario={activeScenario}
                typeOfMapEmissions={typeOfMapEmissions}
                position={position}
                areSouthAfricaSubnationalsActive={
                    areSouthAfricaSubnationalsActive
                }
            />
        )
    );
};

TooltipContainer.propTypes = {
    tooltipInfo: object
};

TooltipContainer.defaultProps = {
    tooltipInfo: null
};

export default TooltipContainer;
