import _ from "lodash";

export const filterCountriesByYearAndEmissions = (
    selectedYear,
    data,
    activeScenario,
    type
) =>
    type === "globalCarbonEmissions"
        ? data
              .filter(({ year, gas }) => year <= selectedYear && gas === "CO2")
              .reduce((accumulator, itemB) => {
                  return accumulator + itemB[activeScenario];
              }, 0)
        : type === "totalEmissions"
        ? data.filter(({ gas }) => gas === "CO2")
        : data.filter(({ year }) => year === selectedYear);

export const groupByCountryAndSumSectors = (
    data,
    property,
    selectedYear,
    activeScenario
) =>
    (data = _.map(_.groupBy(data, property), (grouped, id) => {
        return {
            id,
            sectors: grouped,
            year: selectedYear,
            emissions: _.sumBy(grouped, activeScenario)
        };
    }));

export const groupSubSectors = (data, property, activeScenario) =>
    (data = _.map(_.groupBy(data, property), (grouped, id) => {
        return {
            iso3c: grouped[0].iso3c,
            subsector: grouped[0].subsector,
            base: grouped[0].base,
            ndc: grouped[0].ndc,
            o_1p5c: grouped[0].o_1p5c,
            sector: grouped[0].sector,
            subsector_long_name: grouped[0].subsector_long_name,
            subsector_short_name: grouped[0].subsector_short_name,
            year: grouped[0].year,
            emissions: _.sumBy(grouped, activeScenario)
        };
    }));

export const sortByEmissions = (data) =>
    data.sort((itemA, itemB) => itemB.emissions - itemA.emissions);
