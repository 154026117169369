export const scenarioOptions = [
  {
    id: 'base',
    title: 'Business as usual',
    text: 'In this scenario, future country emissions evolve similar to past trends',
  },
  {
    id: 'ndc',
    title: 'Do as we promised',
    text: 'In this scenario, countries fully implement the NDCs they made to fight climate change.',
  },
  {
    id: 'o_1p5c',
    title: 'We achieve the goal',
    text: 'In this scenario, you will see what is necessary to reach the Paris Agreement goal',
  },
];
