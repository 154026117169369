import React, { useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { formatEmissions } from '../../utils/common';
require('highcharts/modules/accessibility')(Highcharts);

const Chart = ({
  country,
  businessAsUsual,
  doAsWePromised,
  weAchieveTheGoal,
  implementationGap,
  ambitionGap,
  countryName,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(true);

  const options = {
    chart: {
      type: 'area',
      height: 300,
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      line: {
        marker: {
          enabled: false,
        },
        zIndex: 5,
      },
      area: {
        stacking: true,
        lineWidth: 0,
        shadow: false,
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
        showInLegend: false,
      },
    },
    title: {
      text: countryName,
      style: {
        color: '#EB6C2D',
        fontSize: '16px',
      },
    },
    subtitle: {
      text: null,
    },
    xAxis: {
      // categories: [2020, 2035, 2050],
      // tickInterval: 15,
      lineWidth: 0,
      minorGridLineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      min: 2015,
      max: 2050,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      formatter: function () {
        const hoveredYear = this.points[0].x;
        const hoveredImplementationGap = implementationGap[country].find(
          (item) => item[0] === hoveredYear
        );
        const hoveredAmbitionGap = ambitionGap[country].find(
          (item) => item[0] === hoveredYear
        );
        if (hoveredYear < 2020) return setIsTooltipVisible(false);
        else {
          setIsTooltipVisible(true);
          return `
          <div id='tooltip'>
            <div>
              <b>Year: </b>
              <p>${hoveredYear}</p>
            </div>
            <div>
              <b>Implementation gap:</b>
              <p>${formatEmissions(hoveredImplementationGap[1], true)}</p>
            </div>
            <hr />
            <div>
              <b>Ambition gap:</b>
              <p>${formatEmissions(hoveredAmbitionGap[1], true)}</p>
            </div>
          </div>
        `;
        }
      },
      animation: true,
      shared: true,
      useHTML: true,
      shadow: true,
      backgroundColor: '#252525',
      zIndex: 10,
      borderColor: 'none',
      borderRadius: 10,
      style: {
        color: 'white',
        display: isTooltipVisible ? 'flex' : 'none',
        flexDirection: 'column',
        fontSize: '12px',
        gap: '10px',
        width: '300px',
        height: '1000px',
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Business as usual',
        color: '#CF4D0C',
        data: businessAsUsual[country],
        tooltip: {
          pointFormat: '',
        },
        type: 'line',
      },
      {
        name: 'Do as we promise',
        color: '#375EC0',
        data: doAsWePromised[country],
        tooltip: {
          pointFormat: '',
        },
        type: 'line',
      },
      {
        name: 'We achieve the goal',
        color: '#186B3E',
        data: weAchieveTheGoal[country],
        tooltip: {
          pointFormat: '',
        },
        type: 'line',
      },
      {
        name: 'Implementation gap',
        fillColor: '#FFCDB4',
        color: 'transparent',
        marker: {
          enabled: false,
        },
        tooltip: {
          pointFormat: 'X',
        },
        type: 'area',
        data: implementationGap[country],
      },
      {
        name: 'Ambition gap',
        fillColor: '#DAF0EC',
        data: ambitionGap[country],
        color: 'transparent',
        marker: {
          enabled: false,
        },
        tooltip: {
          pointFormat: 'Y',
        },
        type: 'area',
      },
      {
        id: 'transparent2',
        fillColor: 'rgba(255,255,255,0)',
        color: 'transparent2',
        marker: {
          enabled: false,
        },
        data: weAchieveTheGoal[country],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Chart;
