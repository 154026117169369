import { handleCountryEmissionsDetails } from "../utils/countries";
import { useEffect, useState, useCallback } from "react";
import { isNotEmptyObject } from "../utils/common";
import {
    filterCountriesByYearAndEmissions,
    groupByCountryAndSumSectors,
    sortByEmissions
} from "../utils/dataFormatting";

const useLoadCountriesEmissions = (
    selectedYear,
    activeScenario,
    selectedCountry,
    countries_geojson,
    emissions_main,
    countries_flags,
    countries_stats
) => {
    const [totalEmissions, setTotalEmissions] = useState({});
    const [countryCoordinatesAndEmissions, setCountryCoordinatesAndEmissions] =
        useState({});

    const handleEmissions = (obj, updatedEmissions, index) =>
        Math.sign(obj < 0) === Math.sign(updatedEmissions[index].emissions < 0);

    const handleSectorsEmissions = useCallback(() => {
        if (!isNotEmptyObject(selectedCountry)) {
            const emissions = handleCountryEmissionsDetails(
                "WLD",
                countries_flags,
                emissions_main,
                selectedYear,
                activeScenario,
                countries_stats
            );
            setTotalEmissions(emissions);
        } else {
            const updatedEmissions = handleCountryEmissionsDetails(
                selectedCountry.iso3c,
                countries_flags,
                emissions_main,
                selectedYear,
                activeScenario,
                countries_stats
            );
            const updatedEmissionsSectors = handleCountryEmissionsDetails(
                selectedCountry.iso3c,
                countries_flags,
                emissions_main,
                selectedYear,
                activeScenario,
                countries_stats
            ).sectors.map((sector) => sector);
            setTotalEmissions((prevEmissions) => {
                let updatedState = prevEmissions.sectors.map((obj, i) => {
                    return {
                        name: obj.name,
                        emissions: handleEmissions(
                            obj,
                            updatedEmissionsSectors,
                            i
                        )
                            ? updatedEmissionsSectors[i].emissions
                            : 0,
                        isPlant: handleEmissions(
                            obj,
                            updatedEmissionsSectors,
                            i
                        )
                            ? updatedEmissionsSectors[i].isPlant
                            : obj.isPlant
                    };
                });
                updatedState = {
                    ...handleCountryEmissionsDetails(
                        selectedCountry.iso3c,
                        countries_flags,
                        emissions_main,
                        selectedYear,
                        activeScenario,
                        countries_stats
                    ),
                    sectors: updatedState
                };
                return updatedState;
            });
            setTimeout(() => setTotalEmissions(updatedEmissions), 1500);
        }
    }, [
        activeScenario,
        selectedYear,
        emissions_main,
        countries_stats,
        selectedCountry,
        countries_flags
    ]);

    const handleCountryCoordinatesAndEmissions = useCallback(() => {
        let finalData = filterCountriesByYearAndEmissions(
            selectedYear,
            emissions_main
        );
        finalData = groupByCountryAndSumSectors(
            finalData,
            "iso3c",
            selectedYear,
            activeScenario
        );
        finalData = finalData.map((item) => {
            return {
                ...item,
                population: countries_stats.find(
                    ({ iso3c, year }) =>
                        iso3c === item.id && year === selectedYear
                )?.pop,
                file_url: countries_flags.find(({ iso3c }) => iso3c === item.id)
                    ?.file_url
            };
        });
        finalData = sortByEmissions(finalData);
        const countryCoordinatesAndEmissions = countries_geojson.map(
            (country) => ({
                ...country,
                ...finalData.find(({ id }) => id === country.id)
            })
        );
        setCountryCoordinatesAndEmissions({
            features: countryCoordinatesAndEmissions
        });
    }, [
        activeScenario,
        selectedYear,
        emissions_main,
        countries_geojson,
        countries_stats,
        countries_flags
    ]);

    useEffect(() => {
        handleCountryCoordinatesAndEmissions();
        handleSectorsEmissions();
    }, [handleSectorsEmissions, handleCountryCoordinatesAndEmissions]);

    return { totalEmissions, countryCoordinatesAndEmissions };
};

export default useLoadCountriesEmissions;
