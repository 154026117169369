import { secondsPerYear, formatDigits } from "./common";
import {
    filterCountriesByYearAndEmissions,
    groupByCountryAndSumSectors,
    sortByEmissions
} from "./dataFormatting";
import { theme } from "twin.macro";

export const centerMap = [48.210033, 16.363449];
export const southAfricaCenterMap = [-30.5595, 22.9375];

export const matrixClassificationsIncomeText = [
    { text: "Low Income", id: "low_income" },
    { text: "Middle Income", id: "middle_income" },
    { text: "High Income", id: "high_income" }
];

export const matrixClassificationsText = [
    { text: "Low Emissions", id: "low_emissions" },
    { text: "Middle Emissions", id: "middle_emissions" },
    { text: "High Emissions", id: "high_emissions" }
];

export const matrixClassificationsIncomeBoxes = [
    {
        text: "low_emissions/low_income",
        id: "LE_LI",
        color: theme`colors.teal.200`
    },
    {
        text: "low_emissions/middle_income",
        id: "LE_MI",
        color: theme`colors.teal.300`
    },
    {
        text: "low_emissions/high_income",
        id: "LE_HI",
        color: theme`colors.teal.500`
    },
    {
        text: "middle_emissions/low_income",
        id: "ME_LI",
        color: theme`colors.orange.100`
    },
    {
        text: "middle_emissions/middle_income",
        id: "ME_MI",
        color: theme`colors.orange.200`
    },
    {
        text: "middle_emissions/high_income",
        id: "ME_HI",
        color: theme`colors.orange.300`
    },
    {
        text: "high_emissions/low_income",
        id: "HE_LI",
        color: theme`colors.orange.400`
    },
    {
        text: "high_emissions/middle_income",
        id: "HE_MI",
        color: theme`colors.orange.500`
    },
    {
        text: "high_emissions/high_income",
        id: "HE_HI",
        color: theme`colors.orange.600`
    }
];

export const findMatchingIds = (array1, array2) => {
    const matchingObjects = [];

    array1.forEach((obj1) => {
        const match = array2.find((obj2) => obj2.id === obj1.id);

        if (match) {
            matchingObjects.push(obj1);
        }
    });

    return matchingObjects;
};

export let regionColorsEmissions = [
    {
        name: "NA",
        color: theme`colors.gray.200`
    },
    {
        name: "veryLow",
        color: "#FAC2A6"
    },
    {
        name: "low",
        color: "#F4AC87"
    },
    {
        name: "middle",
        color: "#ED9569"
    },
    {
        name: "high",
        color: "#E47E4C"
    },
    {
        name: "veryHigh",
        color: "#DA662F"
    },
    {
        name: "max",
        color: "#CF4D0C"
    }
];

export let countryColorsEmissions = [
    {
        name: "NA",
        color: theme`colors.gray.200`
    },
    {
        name: "veryLow",
        color: "#FAC2A6"
    },
    {
        name: "low",
        color: "#F4AC87"
    },
    {
        name: "middle",
        color: "#ED9569"
    },
    {
        name: "high",
        color: "#E47E4C"
    },
    {
        name: "veryHigh",
        color: "#DA662F"
    },
    {
        name: "max",
        color: "#CF4D0C"
    }
];

export const handleLegendFormat = (range) =>
    range >= 0 ? range ** 2 : range ** 2 * -1;

export const handleCountriesColor = (
    selectedYear,
    activeScenario,
    emissions_main,
    countries_geojson,
    typeOfMapEmissions,
    countries_flags,
    countries_stats,
    classifications_income
) => {
    let finalData = filterCountriesByYearAndEmissions(
        selectedYear,
        emissions_main
    );
    finalData = groupByCountryAndSumSectors(
        finalData,
        "iso3c",
        selectedYear,
        activeScenario
    );
    finalData = finalData.map((item) => {
        return {
            ...item,
            emissionsNotSquared: item.emissions,
            population: countries_stats.find(
                ({ iso3c, year }) => iso3c === item.id && year === selectedYear
            )?.pop,
            file_url: countries_flags.find(({ iso3c }) => iso3c === item.id)
                ?.file_url
        };
    });

    finalData = finalData.map((country) => {
        const legendContent =
            typeOfMapEmissions === "total"
                ? country.emissions
                : country.emissions / country.population;

        const sign = Math.sign(legendContent);
        return {
            ...country,
            emissions:
                sign >= 0
                    ? Math.sqrt(Math.abs(legendContent))
                    : Math.sqrt(Math.abs(legendContent)) * -1
        };
    });
    finalData = sortByEmissions(finalData);
    const highestEmissionsCountry = finalData[1].emissions;
    const lowestEmissionsCountry = finalData[finalData.length - 1].emissions;
    const range = (highestEmissionsCountry - lowestEmissionsCountry) / 6;
    countryColorsEmissions = countryColorsEmissions.map((emission, i) => {
        const legendFirstRange = lowestEmissionsCountry + range * (i - 1);
        const legendFirstRangeSquared = handleLegendFormat(legendFirstRange);
        const legendSecondRange = lowestEmissionsCountry + range * i;
        const legendSecondRangeSquared = handleLegendFormat(legendSecondRange);

        const legendRange = `${formatDigits(
            legendFirstRangeSquared
        )} - ${formatDigits(legendSecondRangeSquared)}`;
        return {
            ...emission,
            text: i === 0 ? "NA" : legendRange
        };
    });
    let countryCoordinatesAndEmissions = countries_geojson.map((country) => ({
        ...country,
        ...finalData.find(({ id }) => id === country.id),
        income: classifications_income.find(({ iso3c }) => country.id === iso3c)
            ?.income,
        classifications: classifications_income.find(
            ({ iso3c }) => country.id === iso3c
        )
            ? classifications_income.find(({ iso3c }) => country.id === iso3c)[
                  activeScenario
              ]
            : undefined
    }));

    countryCoordinatesAndEmissions = countryCoordinatesAndEmissions.map(
        (country) => ({
            ...country,
            fillColor:
                typeOfMapEmissions === "income"
                    ? matrixClassificationsIncomeBoxes.find(
                          ({ text }) =>
                              text ===
                              `${country.classifications}/${country.income}`
                      )?.color || theme`colors.gray.200`
                    : getEmissionsColor(
                          finalData.find(({ id }) => id === country.id)
                              ?.emissions,
                          range,
                          lowestEmissionsCountry
                      )
        })
    );

    return {
        countryCoordinatesAndEmissions,
        range,
        lowestEmissionsCountry,
        countryColorsEmissions
    };
};

const returnEmissionsColor = (type) =>
    countryColorsEmissions.find(({ name }) => name === type).color;

export const getEmissionsColor = (emission, range, minimumEmissions) => {
    if (!emission) return returnEmissionsColor("NA");
    return emission <= minimumEmissions + range
        ? returnEmissionsColor("veryLow")
        : emission <= minimumEmissions + range * 2
        ? returnEmissionsColor("low")
        : emission <= minimumEmissions + range * 3
        ? returnEmissionsColor("middle")
        : emission <= minimumEmissions + range * 4
        ? returnEmissionsColor("high")
        : emission <= minimumEmissions + range * 5
        ? returnEmissionsColor("veryHigh")
        : returnEmissionsColor("max");
};

export const countryStyle = ({ fillColor }) => ({
    fillColor,
    weight: 1,
    opacity: 1,
    color: "white",
    fillOpacity: 1
});

export const setOpacityMap = (geoJson, type) => {
    type === "reset"
        ? geoJson.setStyle({
              fillOpacity: 1
          })
        : geoJson.setStyle({
              fillOpacity: 0.1
          });
};

export const getCountryInfo = (
    target,
    selectedYear,
    activeScenario,
    emissions_main,
    countries_flags,
    countries_stats,
    classifications_income
) => {
    const filteredCountry = emissions_main
        .filter(({ iso3c, year }) => iso3c === target && year === selectedYear)
        .sort((itemA, itemB) => itemB[activeScenario] - itemA[activeScenario]);
    const emissionsSelectedCountry = filteredCountry.reduce(
        (accumulator, itemB) => {
            return accumulator + itemB[activeScenario];
        },
        0
    );
    if (filteredCountry.length > 0) {
        const selectedCountryInfo = countries_flags.find(
            (countryFlag) => countryFlag.iso3c === filteredCountry[0].iso3c
        );
        const highestSectorEmissionSelectedCountry = filteredCountry.reduce(
            (a, b) => (a[activeScenario] > b[activeScenario] ? a : b)
        );
        const populationSelectedCountry = countries_stats.find(
            ({ iso3c, year }) =>
                iso3c === filteredCountry[0].iso3c && year === selectedYear
        ).pop;
        let selectedCountry = {
            year: selectedYear,
            sectors: filteredCountry,
            iso3c: filteredCountry[0].iso3c,
            type: filteredCountry[0].iso3c,
            emissions: emissionsSelectedCountry,
            name: selectedCountryInfo.name,
            file_url: selectedCountryInfo.file_url,
            highest_emissions: highestSectorEmissionSelectedCountry,
            population: populationSelectedCountry,
            emissions_per_person:
                emissionsSelectedCountry / populationSelectedCountry,
            emissions_per_second: emissionsSelectedCountry / secondsPerYear,
            highest_emissions_per_person:
                highestSectorEmissionSelectedCountry[activeScenario] /
                populationSelectedCountry
        };
        if (target !== "WLD") {
            selectedCountry = {
                ...selectedCountry,
                income: classifications_income.find(
                    ({ iso3c }) => iso3c === target
                )?.income,
                classifications: classifications_income.find(
                    ({ iso3c }) => iso3c === target
                )?.[activeScenario]
            };
        }

        return selectedCountry;
    }
};

export const getSubregionsInfo = (subRegion, selectedYear, activeScenario) => {
    const totalEmissions = subRegion.subRegionInfo.reduce(
        (accumulator, curr) => accumulator + curr[activeScenario],
        0
    );
    const sortedSectors = subRegion.subRegionInfoGrouped
        .map((sector) => {
            sector = {
                name: sector[0].sector,
                total: sector.reduce(
                    (accumulator, curr) => accumulator + curr[activeScenario],
                    0
                ),
                grouped: sector.sort(
                    (itemA, itemB) =>
                        itemB[activeScenario] - itemA[activeScenario]
                )
            };
            return sector;
        })
        .sort((itemA, itemB) => itemB.total - itemA.total);

    const selectedSubRegion = {
        year: selectedYear,
        sectors: subRegion.subRegionInfo,
        iso3c: subRegion.properties.CC_1,
        type: subRegion.properties.CC_1,
        emissions: totalEmissions,
        name: subRegion.properties.NAME_1,
        highest_emissions: sortedSectors[0],
        emissions_per_second: totalEmissions / secondsPerYear,
        groupedSectors: sortedSectors
    };
    return selectedSubRegion;
};
