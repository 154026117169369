import tw, { css } from "twin.macro";

export const isNotEmptyObject = (object) =>
    object && Object.keys(object).length > 0;

export const formatDigits = (value) =>
    Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: value > 1_000_000_000 ? 1 : 0
    }).format(value);

export const formatEmissions = (value, compact = false) => {
    if ((value >= 1000000000 || value <= -1000000000) && compact) {
        value = `${(value / 1000000000).toFixed(1).toLocaleString("en-US")} GT`;
    } else if ((value >= 1000000 || value <= -1000000) && compact) {
        value = `${(value / 1000000).toFixed(1).toLocaleString("en-US")} MT`;
    } else if ((value >= 1000 || value <= -1000) && compact) {
        value = `${(value / 1000).toFixed(1).toLocaleString("en-US")} KT`;
    } else if (value && compact) {
        value = `${value.toFixed(1).toLocaleString("en-US")} tons`;
    } else value = value.toLocaleString("en-US");

    return value;
};

export const currentDay = new Date().getDate();
export const currentMonth = new Date().toLocaleString("en-GB", {
    month: "long"
});
export const currentYear = new Date().getFullYear();
export const secondsPerYear = 31556926;

export const avoidBodyScrollOnMobile = (width, value) =>
    (document.body.style.overflow = width <= 768 && value);

export const handleHoverStyle = (width, moved) =>
    width > 768 &&
    css`
        &:hover {
            > span {
                ${tw`block bg-gray-600 border border-gray-200 absolute rounded-[0.25rem] p-[0.5rem 0.75rem] w-[18.75rem] text-white text-xs left-[50%] translate-x-[-50%] translate-y-[0.5rem] z-[2]`};
                ${moved && tw`top-[-15%]`}
            }
        }
    `;

export const fetchOptions = {
    mode: "cors"
};

export const formatRegionName = (region) => {
    const firstLetter = region.charAt(0).toUpperCase();
    const restOfName = region.slice(1).replace(/[A-Z]/g, " $&");
    return firstLetter + restOfName;
};
