import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { debounce } from "lodash";
import Results from "./Results";
import Selected from "./Selected";
import EmptyResults from "../Main/EmissionClockMap/Card/EmptyResults";
import Details from "../Main/EmissionClockMap/Card/Details";
import useOutsideClick from "../../custom-hooks/useOutsideClick";
import { Input } from "../Input/Input";
import { isNotEmptyObject } from "../../utils/common";
import { oneOfType, func, shape, instanceOf, string, bool } from "prop-types";
import SubnationalEmissions from "../Main/EmissionClockMap/SubnationalEmissions";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import SubRegionDetails from "../Main/EmissionClockMap/Card/SubRegionDetails";

const InputSearch = forwardRef(
    (
        {
            handleResetRegion,
            countries_flags,
            handleSearch,
            activeIcon,
            searchTerm,
            handleCountryEmissions,
            handleSubregionsEmissions,
            handleToggleSwitch,
            selectedCountry,
            isNotFromMap,
            name,
            activeScenario,
            handleSelectedSector,
            subSectors,
            closeDetailsCard,
            resetSearch,
            cardInfo,
            typeOfMapEmissions,
            emissions_cumulative,
            subnationalsearchTerm,
            handleSubnationalSearch,
            southAfricaSubregionsCoordinatesAndEmissions,
            selectedRegion,
            areSouthAfricaSubnationalsActive,
            setAreSouthAfricaSubnationalsActive,
            subRegionCardInfo,
            resetSubnationalSearch
        },
        searchRef
    ) => {
        const isSouthAfricaSelected = selectedCountry?.iso3c === "ZAF";
        const [resultsRef, isVisible, setIsVisible] = useOutsideClick();
        const [suggestions, setSuggestions] = useState(countries_flags);
        const [errorMsg, setErrorMsg] = useState("");

        const processRequest = useCallback(
            (searchValue) => {
                const results = countries_flags.filter(({ name }) =>
                    name.toLowerCase().includes(searchValue?.toLowerCase())
                );
                setSuggestions(results);
                setErrorMsg("");
            },
            [countries_flags]
        );

        const handleClickToCountry = useCallback(
            () => setIsVisible(false),
            [setIsVisible]
        );

        useEffect(
            () => (searchRef.current = debounce(processRequest, 300)),
            [searchRef, processRequest]
        );

        useEffect(() => {
            if (!searchTerm && isVisible) {
                setSuggestions(countries_flags);
            }
        }, [searchTerm, isVisible]);

        return (
            <>
                <div
                    css={[
                        tw`p-[1.7rem 1rem 1.2rem] relative md:p-0 mt-[-0.5rem]`,
                        isNotFromMap && tw`border-0 p-0 w-[15.75rem]`,
                        isVisible &&
                            tw`md:h-[calc(100% - 17rem)] md:fixed md:bg-white md:z-[401] md:top-[10rem]`
                    ]}
                >
                    <Input
                        tw="p-[0.8rem]"
                        type="search"
                        value={searchTerm}
                        name={name || "search_countries_wec"}
                        onChange={handleSearch}
                        bgImage={activeIcon}
                        autoComplete="search_countries_wec"
                        placeholder={"Search for a country"}
                        onFocus={() => setIsVisible(true)}
                        title="search_countries_wec"
                        aria-label="search_countries_wec"
                    />
                    {isVisible && (
                        <Results
                            ref={resultsRef}
                            suggestions={suggestions}
                            errorMsg={errorMsg}
                            handleCountryEmissions={handleCountryEmissions}
                            isNotFromMap={isNotFromMap}
                            handleClickToCountry={handleClickToCountry}
                        />
                    )}
                    {isNotEmptyObject(selectedCountry) && (
                        <Selected
                            selectedCountry={selectedCountry}
                            isNotFromMap={isNotFromMap}
                            resetSearch={resetSearch}
                            name={name}
                        />
                    )}
                </div>
                {!isNotFromMap &&
                    isSouthAfricaSelected &&
                    typeOfMapEmissions === "total" && (
                        <SubnationalEmissions
                            handleResetRegion={handleResetRegion}
                            handleToggleSwitch={handleToggleSwitch}
                            subnationalsearchTerm={subnationalsearchTerm}
                            handleSubnationalSearch={handleSubnationalSearch}
                            activeIcon={activeIcon}
                            selectedCountry={selectedCountry}
                            selectedRegion={selectedRegion}
                            resetSubnationalSearch={resetSubnationalSearch}
                            southAfricaSubregionsCoordinatesAndEmissions={
                                southAfricaSubregionsCoordinatesAndEmissions
                            }
                            handleSubregionsEmissions={
                                handleSubregionsEmissions
                            }
                            areSouthAfricaSubnationalsActive={
                                areSouthAfricaSubnationalsActive
                            }
                            setAreSouthAfricaSubnationalsActive={
                                setAreSouthAfricaSubnationalsActive
                            }
                            isNotFromMap={isNotFromMap}
                            searchRef={searchRef}
                            setErrorMsg={setErrorMsg}
                        />
                    )}
                {selectedRegion.name ? (
                    <SubRegionDetails
                        selectedSubRegion={selectedRegion}
                        activeScenario={activeScenario}
                        closeDetailsCard={closeDetailsCard}
                        subRegionCardInfo={subRegionCardInfo}
                        typeOfMapEmissions={typeOfMapEmissions}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                        activeIcon={activeIcon}
                        setIsVisible={setIsVisible}
                    />
                ) : (
                    !isNotFromMap &&
                    (isNotEmptyObject(selectedCountry) ? (
                        <Details
                            selectedCountry={selectedCountry}
                            activeScenario={activeScenario}
                            handleSelectedSector={handleSelectedSector}
                            subSectors={subSectors}
                            closeDetailsCard={closeDetailsCard}
                            cardInfo={cardInfo}
                            typeOfMapEmissions={typeOfMapEmissions}
                            emissions_cumulative={emissions_cumulative}
                            searchTerm={searchTerm}
                            handleSearch={handleSearch}
                            activeIcon={activeIcon}
                            setIsVisible={setIsVisible}
                        />
                    ) : (
                        <EmptyResults />
                    ))
                )}
            </>
        );
    }
);

InputSearch.propTypes = {
    handleSearch: func.isRequired,
    handleCountryEmissions: func.isRequired,
    handleSubnationalSearch: func.isRequired,
    isNotFromMap: bool.isRequired,
    activeIcon: string.isRequired,
    searchTerm: string.isRequired,
    subnationalsearchTerm: string.isRequired,
    selectedCountry: shape({
        name: string,
        file_url: string
    }).isRequired,
    selectedRegion: shape({
        name: string,
        iso3c: string
    }).isRequired,
    searchRef: oneOfType([func, shape({ current: instanceOf(Element) })])
};

InputSearch.defaultProps = {
    handleSearch: () => {},
    handleCountryEmissions: () => {},
    handleSubnationalSearch: () => {},
    activeIcon: "search",
    searchTerm: "",
    subnationalsearchTerm: "",
    isNotFromMap: false,
    selectedCountry: { name: "", file_url: "" },
    selectedRegion: { name: "", iso3c: "" }
};

export default InputSearch;
