import React, { useRef, useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import {
    ToggleSwitch,
    ToggleSwitchLabel
} from "../../ToggleSwitch/ToggleSwitch";
import { Input } from "../../Input/Input";
import Results from "../../Search/Results";
import useOutsideClickSubregions from "../../../custom-hooks/useOutsideClickSubRegions";
import { formatRegionName } from "../../../utils/common";
import search from "../../../assets/images/search.svg";
import reset from "../../../assets/images/reset.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const SubnationalEmissions = ({
    handleResetRegion,
    southAfricaSubregionsCoordinatesAndEmissions,
    handleSubregionsEmissions,
    isNotFromMap,
    areSouthAfricaSubnationalsActive,
    handleToggleSwitch,
    selectedRegion
}) => {
    const [activeIcon, setActiveIcon] = useState(search);
    const searchSouthAfricaRef = useRef();
    const [resultsRefSubRegions, isVisibleSubRegions, setIsVisibleSubRegions] =
        useOutsideClickSubregions();
    const [subnationalsearchTerm, setSubnationalsearchTerm] = useState("");
    const suggestions = southAfricaSubregionsCoordinatesAndEmissions?.features
        ?.map((data) => ({
            name: formatRegionName(data.properties.NAME_1),
            iso3c: data.properties.CC_1,
            file_url: null
        }))
        .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name));

    const [southAfricaSuggestions, setSouthAfricaSuggestions] =
        useState(suggestions);
    const [errorMsg, setErrorMsg] = useState("");

    const processRegionRequest = useCallback((searchValue) => {
        const results = suggestions.filter(({ name }) => {
            return name.toLowerCase().includes(searchValue?.toLowerCase());
        });
        setSouthAfricaSuggestions(results);
        setErrorMsg("");
    }, []);

    useEffect(
        () =>
            (searchSouthAfricaRef.current = debounce(
                processRegionRequest,
                300
            )),
        [searchSouthAfricaRef, processRegionRequest]
    );

    const resetSubnationalSearch = () => {
        setIsVisibleSubRegions(false);
        setActiveIcon(search);
        setSubnationalsearchTerm("");
        searchSouthAfricaRef.current("");
        handleResetRegion();
    };

    const handleSubnationalSearch = ({ target }) => {
        setIsVisibleSubRegions(true);
        const { value } = target;
        setActiveIcon(value === "" ? search : reset);
        setSubnationalsearchTerm(value);
        searchSouthAfricaRef.current(value);
    };

    const hideResults = useCallback(
        () => setIsVisibleSubRegions(false),
        [setIsVisibleSubRegions]
    );

    return (
        <div tw="p-[1rem 1rem 2rem] relative">
            <div tw="flex justify-start items-center gap-[1rem]">
                <ToggleSwitch
                    tw="hidden"
                    type="checkbox"
                    id="subnational"
                    onChange={handleToggleSwitch}
                />
                <ToggleSwitchLabel htmlFor="subnational" />
                <label tw="cursor-pointer text-gray-700" htmlFor="subnational">
                    See subnational emissions
                </label>
            </div>
            {areSouthAfricaSubnationalsActive && (
                <Input
                    tw="p-[0.8rem]"
                    type="search"
                    value={subnationalsearchTerm}
                    name={"search_subnational_wec"}
                    onChange={handleSubnationalSearch}
                    onFocus={() => setIsVisibleSubRegions(true)}
                    bgImage={activeIcon}
                    autoComplete="search_subnational_wec"
                    placeholder={"Search for a sub-region"}
                    title="search_subnational_wec"
                    aria-label="search_subnational_wec"
                />
            )}
            {isVisibleSubRegions && areSouthAfricaSubnationalsActive && (
                <Results
                    suggestions={southAfricaSuggestions}
                    errorMsg={errorMsg}
                    handleCountryEmissions={handleSubregionsEmissions}
                    isNotFromMap={isNotFromMap}
                    areSouthAfricaSubnationalsActive={
                        areSouthAfricaSubnationalsActive
                    }
                    hideResults={hideResults}
                />
            )}
            {selectedRegion.name && areSouthAfricaSubnationalsActive && (
                <div
                    className="selected"
                    css={[
                        !isNotFromMap &&
                            tw`left-[1rem] top-[2.5rem ] right-[1rem] md:left-0 md:right-0 md:top-0 md:bottom-0`
                    ]}
                    tw="flex justify-between border-b border-gray-700 p-[0.8rem] bg-white items-center absolute"
                >
                    <p
                        style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                        css={[
                            tw`overflow-hidden`,
                            !isNotFromMap && tw`w-[14rem] md:w-full`
                        ]}
                    >
                        {selectedRegion.name}
                    </p>
                    <img
                        css={[
                            tw`cursor-pointer`,
                            !isNotFromMap && tw`md:hidden`
                        ]}
                        tw="cursor-pointer"
                        onClick={resetSubnationalSearch}
                        src={reset}
                        alt="ResetselectedRegion search"
                    />
                </div>
            )}
        </div>
    );
};

export default SubnationalEmissions;
