import globe from '../../assets/images/footer/socials/globe.svg';
import facebook from '../../assets/images/footer/socials/facebook.svg';
import instagram from '../../assets/images/footer/socials/instagram.svg';
import linkedin from '../../assets/images/footer/socials/linkedin.svg';
import twitter from '../../assets/images/footer/socials/twitter.svg';

export const links = [
  { name: 'globe', image: globe, url: 'https://worlddata.io/' },
  {
    name: 'linkedIn',
    image: linkedin,
    url: 'https://www.linkedin.com/company/world-data-lab',
  },
  { name: 'twitter', image: twitter, url: 'https://twitter.com/worlddatalab' },
  {
    name: 'facebook',
    image: facebook,
    url: 'https://www.facebook.com/worlddatalab/',
  },
  {
    name: 'instagram',
    image: instagram,
    url: 'https://instagram.com/worlddatalab',
  },
];
