import React, { forwardRef } from "react";
import InputSearch from "../../../Search/InputSearch";
import {
    oneOfType,
    func,
    shape,
    instanceOf,
    string,
    number,
    arrayOf,
    object
} from "prop-types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const EmissionClockMapDetailsSearch = forwardRef(
    (
        {
            countries_flags,
            handleSearch,
            handleResetRegion,
            handleCountryEmissions,
            handleSubregionsEmissions,
            activeIcon,
            searchTerm,
            selectedCountry,
            activeScenario,
            handleSelectedSector,
            subSectors,
            closeDetailsCard,
            resetSearch,
            cardInfo,
            typeOfMapEmissions,
            emissions_cumulative,
            southAfricaSubregionsCoordinatesAndEmissions,
            subnationalsearchTerm,
            handleSubnationalSearch,
            selectedRegion,
            areSouthAfricaSubnationalsActive,
            setAreSouthAfricaSubnationalsActive,
            handleToggleSwitch,
            subRegionCardInfo,
            resetSubnationalSearch
        },
        searchRef
    ) => (
        <>
            <h4 tw="font-semiBold text-gray-800 p-[1rem 1rem 0]">
                Search for a country
            </h4>
            <InputSearch
                handleToggleSwitch={handleToggleSwitch}
                countries_flags={countries_flags}
                southAfricaSubregionsCoordinatesAndEmissions={
                    southAfricaSubregionsCoordinatesAndEmissions
                }
                handleSearch={handleSearch}
                searchTerm={searchTerm}
                selectedCountry={selectedCountry}
                handleCountryEmissions={handleCountryEmissions}
                handleSubregionsEmissions={handleSubregionsEmissions}
                activeIcon={activeIcon}
                ref={searchRef}
                handleResetRegion={handleResetRegion}
                activeScenario={activeScenario}
                handleSelectedSector={handleSelectedSector}
                subSectors={subSectors}
                closeDetailsCard={closeDetailsCard}
                resetSearch={resetSearch}
                cardInfo={cardInfo}
                typeOfMapEmissions={typeOfMapEmissions}
                emissions_cumulative={emissions_cumulative}
                subnationalsearchTerm={subnationalsearchTerm}
                handleSubnationalSearch={handleSubnationalSearch}
                selectedRegion={selectedRegion}
                areSouthAfricaSubnationalsActive={
                    areSouthAfricaSubnationalsActive
                }
                setAreSouthAfricaSubnationalsActive={
                    setAreSouthAfricaSubnationalsActive
                }
                subRegionCardInfo={subRegionCardInfo}
                resetSubnationalSearch={resetSubnationalSearch}
            />
        </>
    )
);

EmissionClockMapDetailsSearch.propTypes = {
    handleSearch: func.isRequired,
    handleCountryEmissions: func.isRequired,
    activeIcon: string.isRequired,
    searchTerm: string.isRequired,
    subnationalsearchTerm: string.isRequired,
    searchRef: oneOfType([
        func,
        shape({ current: instanceOf(Element).isRequired })
    ]),
    sectors: shape({
        year: number.isRequired,
        tons: number.isRequired
    }).isRequired,
    activeScenario: shape({
        id: string.isRequired,
        text: string.isRequired,
        title: string.isRequired
    }).isRequired,
    selectedCountry: oneOfType([
        shape({
            year: number.isRequired,
            emissions: number.isRequired,
            emissions_per_person: number.isRequired,
            emissions_per_second: number.isRequired,
            file_url: string.isRequired,
            highest_emissions: shape({
                year: number.isRequired,
                iso3c: string.isRequired,
                sector: string.isRequired,
                base: number.isRequired,
                ndc: number.isRequired,
                o_1p5c: number.isRequired
            }),
            highest_emissions_per_person: number.isRequired,
            iso3c: string.isRequired,
            name: string.isRequired,
            population: number.isRequired,
            sectors: arrayOf(object),
            type: string.isRequired
        }),
        object
    ]).isRequired,
    selectedRegion: oneOfType([
        shape({
            year: number.isRequired,
            emissions: number.isRequired,
            emissions_per_person: number.isRequired,
            emissions_per_second: number.isRequired,
            file_url: string.isRequired,
            highest_emissions: shape({
                year: number.isRequired,
                iso3c: string.isRequired,
                sector: string.isRequired,
                base: number.isRequired,
                ndc: number.isRequired,
                o_1p5c: number.isRequired
            }),
            highest_emissions_per_person: number.isRequired,
            iso3c: string.isRequired,
            name: string.isRequired,
            population: number.isRequired,
            sectors: arrayOf(object),
            type: string.isRequired
        }),
        object
    ]).isRequired,
    closeDetailsCard: func.isRequired,
    handleSelectedSector: func.isRequired,
    subSectors: oneOfType([
        shape({
            categories: arrayOf(object),
            country: string.isRequired,
            emissions: number.isRequired,
            population: number.isRequired,
            sector: string.isRequired,
            year: number.isRequired
        }),
        object
    ]).isRequired
};

EmissionClockMapDetailsSearch.defaultProps = {
    handleSearch: () => {},
    handleCountryEmissions: () => {},
    activeIcon: "search",
    searchTerm: "",
    subnationalsearchTerm: "",
    handleSubnationalSearch: () => {},
    sectors: { year: 2022, tons: 100 },
    activeScenario: {
        id: "base",
        text: "In this scenario, states continue with their current trajectory",
        title: "Business as usual"
    },
    selectedCountry: {},
    selectedRegion: {},
    closeDetailsCard: () => {},
    handleSelectedSector: () => {},
    subSectors: {}
};

export default EmissionClockMapDetailsSearch;
