import EmissionClockGlobeHeader from './EmissionClockGlobeHeader';
import EmissionClockGlobeSpinning from './EmissionClockGlobeSpinning';
import useIntersectionObserver from '../../../custom-hooks/useIntersectionObserver';
import useOnWindowBlur from '../../../custom-hooks/useOnWindowBlur';
import { filterCountriesByYearAndEmissions } from '../../../utils/dataFormatting';
import { currentYear, secondsPerYear } from '../../../utils/common';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const globalTons = 2000;

const EmissionClockGlobe = ({
  activeScenario,
  selectedYear,
  width,
  toggleModal,
  emissions_world,
  country_sector_probabilities,
  countries_flags,
}) => {
  const [spinningGlobeRef, isVisible] = useIntersectionObserver();
  const isWindowOnBlur = useOnWindowBlur();

  const emissionsCurrentYear = filterCountriesByYearAndEmissions(
    selectedYear,
    emissions_world,
    activeScenario.id
  ).reduce((acc, item) => {
    if (currentYear === selectedYear) {
      return acc + item.base;
    } else {
      return acc + item[activeScenario.id];
    }
  }, 0);

  const spinningSpeed =
    (secondsPerYear / emissionsCurrentYear) * globalTons * 24;

  return (
    <section
      tw="text-center bg-lightBlue h-[100vh] overflow-hidden"
      id="emissions"
      ref={spinningGlobeRef}
    >
      <EmissionClockGlobeHeader
        toggleModal={toggleModal}
        activeScenario={activeScenario}
        selectedYear={selectedYear}
        width={width}
        emissions_world={emissions_world}
        globalTons={globalTons}
      />
      <EmissionClockGlobeSpinning
        activeScenario={activeScenario}
        selectedYear={selectedYear}
        isVisible={isVisible}
        isWindowOnBlur={isWindowOnBlur}
        spinningSpeed={spinningSpeed}
        country_sector_probabilities={country_sector_probabilities}
        countries_flags={countries_flags}
      />
    </section>
  );
};

export default EmissionClockGlobe;
