import searchDetails from '../../../../assets/images/searchDetails.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const Emptyresults = () => (
  <div tw="flex flex-col justify-around h-full">
    <img
      tw="m-[1.125rem auto] md:hidden"
      width={140}
      alt="Search country"
      src={searchDetails}
    />
    <p tw="text-sm text-gray-500 p-[0 2.68rem 3.56rem] text-center md:hidden">
      Type or select a country on the map to view more emissions details
    </p>
  </div>
);

export default Emptyresults;
