import EmissionsClockCounter from "./EmissionsClockCounter";
import EmissionsClockCounterCurrentYear from "./EmissionsClockCounterCurrentYear";
import { handleHoverStyle } from "../../../utils/common";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import DynamicSvg from "../../DynamicSvg/DynamicSvg";
import tooltipIcon from "../../../assets/images/tooltipIcon.svg";

const EmissionClockGlobeHeader = ({
    toggleModal,
    activeScenario,
    selectedYear,
    width,
    emissions_world,
    globalTons
}) => (
    <>
        <DynamicSvg iconName="cloudAbove" />
        <DynamicSvg iconName="cloudBelow" />
        <div tw="w-full top-0 absolute z-[2] p-[10.5vh 1rem 0] bg-white h-[13.75rem] md:h-auto">
            <div tw="flex items-center justify-center gap-[1rem] border-b border-b-[#FFCDB4] pb-[0.5rem] w-full max-w-[50rem] m-[0 auto 1rem] sm:justify-between sm:items-end sm:text-left">
                <h1 tw="text-gray-800 z-[2] md:text-[0.875rem]">
                    Projected{" "}
                    <span
                        css={[
                            tw`font-bold text-orange-400 cursor-pointer relative`,
                            handleHoverStyle(width)
                        ]}
                        style={{
                            textDecoration: "underline dashed",
                            textUnderlinePosition: "under"
                        }}
                        title="What are aggregate tons?"
                        message="We measure greenhouse gases in CO2 equivalents. CO2 equivalents are used to convert non-CO2 emissions to equivalents values in CO2 emissions. For the convertions we use convertions rates assuming 100-year global warming potentials."
                        onClick={width <= 768 ? toggleModal : undefined}
                    >
                        tons
                        <span tw="hidden">
                            We measure greenhouse gases in CO2 equivalents. CO2
                            equivalents are used to convert non-CO2 emissions to
                            equivalents values in CO2 emissions. For the
                            convertions we use convertions rates assuming
                            100-year global warming potentials.
                        </span>
                    </span>
                    <br tw="hidden sm:block" />
                    {" of "}
                    <span
                        css={[
                            tw`font-bold text-orange-400 cursor-pointer relative`,
                            handleHoverStyle(width)
                        ]}
                        style={{
                            textDecoration: "underline dashed",
                            textUnderlinePosition: "under"
                        }}
                        title="What are greenhouse gases?"
                        message="A greenhouse gas is any gas that has the property of absorbing infrared radiation (net heat energy) emitted from Earth's surface and reradiating it back to Earth's surface, thus contributing to global warming."
                        onClick={width <= 768 ? toggleModal : undefined}
                    >
                        GHG emissions
                        <span tw="hidden">
                            GHG stands for greenhouse gas. A greenhouse gas is
                            any gas that has the property ofabsorbing infrared
                            radiation (net heat energy) emitted from Earth's
                            surface and reradiating it back to Earth's surface,
                            thus contributing to global warming.
                        </span>
                    </span>
                    {/* <span
                    css={[
                        tw`font-bold text-orange-400 cursor-pointer relative`,
                        handleHoverStyle(width)
                    ]}
                    style={{
                        textDecoration: "underline dashed",
                        textUnderlinePosition: "under"
                    }}
                    title="CO₂ equivalents?"
                    message="CO₂ equivalents are used to convert emissions of non-CO2 gases to
                    equivalent values in CO2 emissions. For the conversions, we use
                    conversion rates assuming 100-year global warming potentials."
                    onClick={width <= 768 ? toggleModal : undefined}
                >
                    <small tw="text-gray-800">(</small>
                    CO₂ equivalents
                    <small tw="text-gray-800">)</small>
                    <span tw="hidden">
                        CO₂ equivalents are used to convert emissions of non-CO2
                        gases to equivalent values in CO2 emissions. For the
                        conversions, we use conversion rates assuming 100-year
                        global warming potentials.
                    </span>
                </span> */}{" "}
                    in {selectedYear}:
                </h1>
                <EmissionsClockCounterCurrentYear
                    activeScenario={activeScenario}
                    selectedYear={selectedYear}
                    emissions_world={emissions_world}
                    globalTons={globalTons}
                />
            </div>
            <h1>GHG emissions so far this year:</h1>
            <div
                tw="flex justify-center items-center"
                data-testid="emission-flip-number"
            >
                <EmissionsClockCounter
                    activeScenario={activeScenario}
                    selectedYear={selectedYear}
                    emissions_world={emissions_world}
                    globalTons={globalTons}
                />
                <span
                    css={[tw`cursor-pointer relative`, handleHoverStyle(width)]}
                    title="Greenhouse gases emitted in the current year"
                    message="This is how much greenhouse gases we are emitting in the current year, each icon is representing 2,000 tons of CO₂ equivalents"
                    onClick={width <= 768 ? toggleModal : undefined}
                >
                    <img
                        alt="Tooltip icon"
                        src={tooltipIcon}
                        tw="pl-[0.75rem] mt-[-1rem]"
                    />
                    <span tw="hidden">
                        This is how much greenhouse gases we are emitting up to
                        this moment in the selected year, each icon is
                        representing 2,000 tons of CO₂ equivalents
                    </span>
                </span>
            </div>
        </div>
    </>
);

export default EmissionClockGlobeHeader;
