import { Col } from '../../../assets/styles/grid';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockMoreHeader = () => (
  <Col as="article" col={12} md={4} tw="md:p-[1rem] max-w-[23.37rem]">
    <h2 tw="text-teal-500 font-semiBold text-[1.75rem] md:text-lg">
      What now ?
    </h2>
    <p tw="mt-[1rem] text-gray-800 md:text-xs">
      Here are further information about the World Emissions Clock, climate
      change and what can be done about it.
    </p>
  </Col>
);

export default EmissionClockMoreHeader;
