import { formatEmissions } from "../../../../utils/common";
import DynamicSvg from "../../../DynamicSvg/DynamicSvg";
import incomeImg from "../../../../assets/images/incomeImg.svg";
import {
    matrixClassificationsIncomeText,
    matrixClassificationsText,
    matrixClassificationsIncomeBoxes
} from "../../../../utils/leafletMap.js";
/** @jsxImportSource @emotion/react */
import tw, { css, theme } from "twin.macro";

const Tooltip = ({
    tooltipInfo,
    position,
    activeScenario,
    typeOfMapEmissions,
    areSouthAfricaSubnationalsActive
}) => (
    <div
        css={[
            tw`rounded-[0.5rem] shadow max-w-[18.75rem] bg-white fixed z-[1001]`,
            css({ left: `${position.x + 20}px`, top: `${position.y - 60}px` })
        ]}
    >
        <div tw="flex p-[1.125rem 1rem 0.5rem] items-center">
            {tooltipInfo.file_url && (
                <img width={25} src={tooltipInfo.file_url} alt="Flag" />
            )}
            <h4 tw="font-semiBold text-gray-700 text-lg ml-[0.5rem]">
                {tooltipInfo.name}
            </h4>
        </div>
        {typeOfMapEmissions === "income" &&
        !areSouthAfricaSubnationalsActive ? (
            <div tw="font-semiBold text-xs text-gray-500 p-[0 1rem 1.125rem] ">
                <p tw="font-regular">Emissions per person</p>
                <p tw="text-xs text-orange-400 my-[0.25rem]">
                    {formatEmissions(tooltipInfo.emissions_per_person, true)}{" "}
                    <span tw="font-light">(CO2 equivalent)</span>
                </p>
                <p
                    css={[
                        tw`text-xs p-[0.25rem] rounded-[0.25rem] font-regular max-w-max`,
                        [
                            "low_emissions/high_income",
                            "high_emissions/middle_income",
                            "high_emissions/high_income"
                        ].includes(
                            `${tooltipInfo.classifications}/${tooltipInfo.income}`
                        )
                            ? tw`text-white`
                            : tw`text-orange-800`
                    ]}
                    style={{
                        background: matrixClassificationsIncomeBoxes.find(
                            ({ text }) =>
                                text ===
                                `${tooltipInfo.classifications}/${tooltipInfo.income}`
                        ).color
                    }}
                >
                    {
                        matrixClassificationsText.find(
                            ({ id }) => id === tooltipInfo.classifications
                        )?.text
                    }
                </p>
            </div>
        ) : (
            <>
                <p tw="text-sm text-gray-500 p-[0 1rem]">
                    GHG emissions in {tooltipInfo.year}
                </p>
                <p tw="font-semiBold text-orange-400 p-[0.25rem 1rem 1rem]">
                    {formatEmissions(
                        typeOfMapEmissions === "total"
                            ? tooltipInfo.emissions
                            : tooltipInfo.emissions_per_person,
                        true
                    )}{" "}
                    <span tw="font-regular">(CO₂ equivalent)</span>
                </p>
            </>
        )}
        {typeOfMapEmissions !== "income" ? (
            <div tw="font-semiBold text-xs text-gray-500 p-[1rem 1rem 1.125rem] border-t border-gray-200">
                <p>
                    {tooltipInfo.highest_emissions.sector}
                    <span tw="font-regular"> is the main emitting sector</span>
                </p>
                <div tw="flex pt-[0.625rem] items-center">
                    <DynamicSvg
                        iconName={
                            tooltipInfo.highest_emissions.name ||
                            tooltipInfo.highest_emissions.sector
                        }
                        size={24}
                        fill={`${theme`colors.orange.400`}`}
                    />
                    <p tw="font-semiBold text-orange-400 ml-[0.5rem]">
                        {formatEmissions(
                            typeOfMapEmissions === "total"
                                ? tooltipInfo.highest_emissions.total ||
                                      tooltipInfo.highest_emissions[
                                          activeScenario.id
                                      ] ||
                                      0
                                : tooltipInfo.highest_emissions_per_person,
                            true
                        )}{" "}
                        <span tw="font-regular">(CO₂ equivalent)</span>
                    </p>
                </div>
            </div>
        ) : (
            !areSouthAfricaSubnationalsActive && (
                <div tw="font-semiBold text-xs text-gray-500 p-[1rem 1rem 1.125rem] border-t border-gray-200">
                    <p tw="font-regular">Income classification</p>
                    <div
                        css={[
                            tw`mt-1 flex gap-4 text-orange-800 items-center font-semiBold rounded px-1 max-w-max`,
                            [
                                "low_emissions/high_income",
                                "high_emissions/middle_income",
                                "high_emissions/high_income"
                            ].includes(
                                `${tooltipInfo.classifications}/${tooltipInfo.income}`
                            )
                                ? tw`text-white`
                                : tw`text-orange-800`
                        ]}
                        style={{
                            background: matrixClassificationsIncomeBoxes.find(
                                ({ text }) =>
                                    text ===
                                    `${tooltipInfo.classifications}/${tooltipInfo.income}`
                            ).color
                        }}
                    >
                        <DynamicSvg
                            iconName="IncomeTag"
                            fill={
                                [
                                    "low_emissions/high_income",
                                    "high_emissions/middle_income",
                                    "high_emissions/high_income"
                                ].includes(
                                    `${tooltipInfo.classifications}/${tooltipInfo.income}`
                                )
                                    ? "white"
                                    : theme`colors.orange.800`
                            }
                        />
                        {
                            matrixClassificationsIncomeText.find(
                                ({ id }) => id === tooltipInfo.income
                            )?.text
                        }
                    </div>
                </div>
            )
        )}
    </div>
);

export default Tooltip;
