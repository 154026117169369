import DynamicSvg from '../../../DynamicSvg/DynamicSvg';
import Subsectors from './Subsectors';
import Rankings from './Rankings';
import { Button } from '../../../Button/Button';
import {
  formatEmissions,
  isNotEmptyObject,
  secondsPerYear,
} from '../../../../utils/common';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';

const EmissionClockCardDetails = ({
  totalEmissions,
  selectedSectorList,
  selectedSector,
  closeDetailsCard,
  handleActiveSectorType,
  handleSelectedSector,
  subSectors,
  sortedRankings,
  activeScenario,
}) => {
  const filteredCategories = totalEmissions.sectors.filter(
    ({ name }) => name !== subSectors.sector
  );

  const handleCategories = () =>
    filteredCategories.map(({ name, emissions }) => (
      <button
        key={name}
        tw="bg-white shadow rounded-[0.5rem] text-sm font-medium text-orange-400 flex items-center flex-col w-[7rem] h-[7rem] justify-center"
        name={name}
        onClick={handleSelectedSector}
      >
        <DynamicSvg
          iconName={name}
          size={28}
          fill={`${theme`colors.orange.400`}`}
        />
        <p>{formatEmissions(emissions, true)}</p>
      </button>
    ));

  return (
    <aside
      css={[
        tw`bg-white w-full h-full flex transition-all top-0 duration-1000 absolute text-white translate-x-[-101%] lg:flex-wrap gap-[1.5rem] justify-between md:top-[4.25rem] md:z-[1002] md:bottom-0 opacity-0`,
        isNotEmptyObject(selectedSector) &&
          tw`translate-x-0 md:fixed opacity-100`,
      ]}
      className="scrollable scrollableSectors"
    >
      <article tw="flex bg-gradient-to-b from-[#FA915C] to-orange-400 rounded-[1rem] relative w-full md:rounded-none md:flex-wrap md:block md:h-full">
        <div tw="flex-1 sm:flex-none p-[2rem] border-r-[0.06rem] border-r-gray-50 md:border-0 md:p-[1rem 1rem 0]">
          <div tw="flex items-center">
            <DynamicSvg
              iconName={subSectors.sector}
              size={44}
              fill={`${theme`colors.white`}`}
            />
            {subSectors?.sector && (
              <h4 tw="font-bold text-xl ml-[0.5rem] capitalize">
                {subSectors?.sector === 'Energy'
                  ? 'Energy Systems'
                  : subSectors?.sector}
              </h4>
            )}
          </div>
          <div tw=" m-[2.5rem 0 0.5rem] md:m-[1.5rem 0 0]">
            {isNotEmptyObject(subSectors) && (
              <h4 tw="text-xl font-semiBold">
                {formatEmissions(subSectors.emissions, true)}
              </h4>
            )}
            <h4 tw="text-gray-50 text-sm">
              Total amount of GHG emissions in CO₂eq
            </h4>
          </div>
          <div tw=" m-[2.5rem 0 0.5rem] md:m-[1.5rem 0 0]">
            <p tw="text-xl font-semiBold">
              {isNotEmptyObject(subSectors) &&
                formatEmissions(
                  Math.round(subSectors.emissions / secondsPerYear * 10) / 10
                )}{' '}
              <span tw="text-gray-50">tons/s</span>
            </p>
            <p tw="text-gray-50 text-sm">GHG emissions per second in CO₂eq</p>
          </div>
        </div>
        <div tw="flex-1 sm:flex-none p-[2rem] md:p-[1rem 1rem 0] sm:w-full sm:px-0">
          <div tw="flex md:border md:border-white gap-[1.5rem] md:gap-0">
            <Button
              tw="max-w-[5.93rem] md:max-w-[unset] md:rounded-none md:border-0"
              type={
                selectedSectorList === 'Subsector'
                  ? 'emissionFocus'
                  : 'emission'
              }
              onClick={handleActiveSectorType}
              name="Subsector"
            >
              Subsector
            </Button>
            <Button
              tw="max-w-[4.875rem] md:max-w-[unset] md:rounded-none md:border-0 md:border-l md:border-l-white"
              type={
                selectedSectorList === 'Ranking' ? 'emissionFocus' : 'emission'
              }
              onClick={handleActiveSectorType}
              name="Ranking"
            >
              Ranking
            </Button>
          </div>
          <h4 tw="text-gray-50 font-bold mt-[2.125rem] sm:p-[1rem] sm:m-0">
            {selectedSectorList === 'Subsector'
              ? 'Subsectors'
              : 'Countries that emit the least per person'}
          </h4>
          {isNotEmptyObject(subSectors) &&
            selectedSectorList === 'Subsector' && (
              <Subsectors subSectors={subSectors} />
            )}
          {selectedSectorList === 'Ranking' && (
            <Rankings
              sortedRankings={sortedRankings}
              totalEmissions={totalEmissions}
              activeScenario={activeScenario}
              closeDetailsCard={closeDetailsCard}
            />
          )}
        </div>
        <Button
          tw="text-sm font-semiBold max-w-[5rem] absolute top-[0.81rem] right-[2rem] border-0 text-orange-400 md:hidden"
          type="emission"
          onClick={closeDetailsCard}
        >
          Close X
        </Button>
        {isNotEmptyObject(selectedSector) && (
          <div tw="hidden md:flex flex-col p-[1.5rem] items-center border-t border-gray-200 bg-white md:absolute md:bottom-[67px] md:w-full">
            <Button
              type="modal"
              onClick={closeDetailsCard}
              tw="max-w-[5.93rem]"
            >
              Close
            </Button>
          </div>
        )}
      </article>
      <article tw="flex flex-col gap-[1rem 0] justify-between lg:w-full lg:flex-row lg:justify-evenly md:hidden">
        {handleCategories()}
      </article>
    </aside>
  );
};

export default EmissionClockCardDetails;
