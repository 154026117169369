import { Button } from "../../../Button/Button";
import { list } from "./EmissionsTypeButtonList";
import { bool } from "prop-types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const EmissionClockMapDetailsHeader = ({
    handleTypeOfMapEmissions,
    typeOfMapEmissions,
    mobile,
    areSouthAfricaSubnationalsActive
}) => (
    <div>
        <h2
            css={[
                tw`p-[1rem] text-gray-800 text-sm font-semiBold md:hidden`,
                mobile && tw`hidden md:flex`
            ]}
        >
            Type of emissions:
        </h2>
        <div
            css={[
                tw`p-[0 1rem 1.35rem] flex md:flex-wrap gap-[1rem] md:hidden`,
                mobile && tw`hidden md:flex lg:flex-nowrap xs:flex-wrap`
            ]}
        >
            {list.map(({ id, text }) => (
                <Button
                    key={id}
                    type={typeOfMapEmissions === id ? "primary" : "secondary"}
                    css={[
                        tw`text-sm px-[0.5rem] max-w-max min-w-[131px]`,
                        id !== "total" &&
                            areSouthAfricaSubnationalsActive &&
                            tw`hidden`
                    ]}
                    onClick={handleTypeOfMapEmissions}
                    name={id}
                >
                    {text}
                </Button>
            ))}
        </div>
    </div>
);

EmissionClockMapDetailsHeader.propTypes = {
    mobile: bool.isRequired
};

EmissionClockMapDetailsHeader.defaultProps = {
    mobile: false
};

export default EmissionClockMapDetailsHeader;
