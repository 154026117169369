import React, { Component } from "react";
import ErrorFallback from "./ErrorFallback.jsx";
import { GlobalStyles } from "twin.macro";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <>
                    <GlobalStyles />
                    <ErrorFallback />
                </>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
