import React, { useState, useCallback, useEffect } from "react";
import { Grid, Col, Container } from "../../assets/styles/grid";
import Logo from "../../components/Header/Logo";
import Navbar from "../../components/Header/Navbar";
import EmissionClockGlobe from "../../components/Main/EmissionClockGlobe/EmissionClockGlobe";
import EmissionClockMap from "../../components/Main/EmissionClockMap/EmissionClockMap";
import EmissionClockSectors from "../../components/Main/EmissionClockSectors/EmissionClockSectors";
import EmissionClockCompare from "../../components/Main/EmissionClockCompare/EmissionClockCompare";
import EmissionClockMore from "../../components/Main/EmissionClockMore/EmissionClockMore";
import FooterSignature from "../../components/Footer/FooterSignature";
import FooterSocials from "../../components/Footer/FooterSocials";
import FooterSupported from "../../components/Footer/FooterSupported";
import { Button } from "../../components/Button/Button";
import { ModalMobile } from "../../components/Modal/ModalMobile";
import PredictFutureBar from "../../components/PredictFutureBar/PredictFutureBar";
import { yearRange } from "../../utils/slider";
import { isNotEmptyObject } from "../../utils/common";
import { handleCountriesColor } from "../../utils/leafletMap";
import { scenarioOptions } from "../../components/Drop/DropOptions";
import useWindowSize from "../../custom-hooks/useWindowsSize";
import useLoadEmissionsWorld from "../../custom-hooks/useLoadEmissionsWorld";
import SocialSidebar from "../../components/SocialSidebar/SocialSidebar";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import useSouthAfricaEmissions from "../../custom-hooks/useSouthAfricaEmissions";

const EmissionClock = ({
    emissions_world,
    emissions_main,
    countries_stats,
    country_sector_probabilities,
    sectors_compositions,
    subsectors_all,
    countries_flags,
    countries_geojson,
    countries_maximums_emissions,
    emissions_cumulative,
    temperature_increase,
    south_africa_geojson,
    classifications_income
}) => {
    const { width } = useWindowSize();
    const [isScenarioDropOpen, setIsScenarioDropOpen] = useState(false);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [isTimelineClicked, setTimelineClicked] = useState(false);
    const [selectedYearMobile, setSelectedYearMobile] = useState(
        new Date().getFullYear()
    );
    const [activeScenario, setActiveScenario] = useState(scenarioOptions[0]);
    const {
        globalCarbonEmissions,
        showWarning,
        areCurrentYearEmissionsOverBudget,
        temperature
    } = useLoadEmissionsWorld(
        selectedYear,
        activeScenario.id,
        emissions_world,
        temperature_increase
    );
    const {
        southAfricaSubregionsCoordinatesAndEmissions,
        handleRegionsColor,
        areSubRegionsLoading,
        prevSouthAfricaSubregionsCoordinatesAndEmissions
    } = useSouthAfricaEmissions(
        south_africa_geojson,
        selectedYear,
        activeScenario.id,
        sectors_compositions
    );
    const [isLoading, setIsLoading] = useState(false);
    const [typeOfMapEmissions, setTypeOfMapEmissions] = useState("total");
    const [countryCoordinatesAndEmissions, setCountryCoordinatesAndEmissions] =
        useState({});

    const [
        prevCountryCoordinatesAndEmissions,
        setPrevCountryCoordinatesAndEmissions
    ] = useState({});
    const [modal, setModal] = useState({
        isActive: false,
        message: ""
    });

    classifications_income = classifications_income.filter(
        ({ year }) => year === selectedYear
    );

    const toggleModal = useCallback(
        ({ currentTarget }) =>
            setModal({
                isActive: !modal.isActive,
                title: currentTarget.getAttribute("title"),
                message: currentTarget.getAttribute("message")
            }),
        [modal]
    );

    const handleSetIsScenarioDropOpen = useCallback(
        () => setIsScenarioDropOpen(!isScenarioDropOpen),
        [isScenarioDropOpen]
    );

    const handleSetActiveScenario = useCallback(
        ({ target }) => {
            const activeScenario = scenarioOptions.find(
                ({ id }) => id === target.value
            );
            activeScenario && setActiveScenario(activeScenario);
            handleSetIsScenarioDropOpen();
        },
        [handleSetIsScenarioDropOpen]
    );

    const handleSetSelectedYear = useCallback(
        (year) => {
            setSelectedYearMobile(year);
            if (
                year.toString().length === 4 &&
                year >= yearRange.start &&
                year <= yearRange.end
            ) {
                if (selectedYear === year) return;
                setSelectedYear(year);
            }
        },
        [selectedYear]
    );

    const handleTimelineInteraction = useCallback(
        (isButtonClicked) => setTimelineClicked(isButtonClicked),
        []
    );

    const handleTypeOfMapEmissions = useCallback(
        ({ target }) => setTypeOfMapEmissions(target.name),
        []
    );

    const handleMapEmissions = useCallback(() => {
        setIsLoading(true);

        const countriesColor = handleCountriesColor(
            selectedYear,
            activeScenario.id,
            emissions_main,
            countries_geojson,
            typeOfMapEmissions,
            countries_flags,
            countries_stats,
            classifications_income
        );
        setPrevCountryCoordinatesAndEmissions({
            features: countryCoordinatesAndEmissions.features,
            minimumEmissions: countryCoordinatesAndEmissions.minimumEmissions,
            range: countryCoordinatesAndEmissions.range
        });
        setCountryCoordinatesAndEmissions({
            features: countriesColor.countryCoordinatesAndEmissions,
            minimumEmissions: countriesColor.lowestEmissionsCountry,
            range: countriesColor.range
        });
        setTimeout(() => setIsLoading(false), 5);
    }, [
        activeScenario,
        selectedYear,
        emissions_main,
        typeOfMapEmissions,
        countries_geojson,
        countries_flags,
        countries_stats
    ]);

    useEffect(() => {
        handleMapEmissions();
        handleRegionsColor();
    }, [handleMapEmissions, handleRegionsColor]);

    return (
        <>
            <SocialSidebar />
            <Container tw="border-b border-gray-50 fixed w-full z-[1] bg-white shadow left-0 right-0">
                <Grid as="header" cols={12} alignItems="center">
                    <Col col={5} lg={4} md={3} tw="flex">
                        <Logo />
                    </Col>
                    <Col
                        as="nav"
                        col={7}
                        lg={8}
                        md={1}
                        tw="md:justify-self-end"
                    >
                        <Navbar />
                    </Col>
                </Grid>
            </Container>
            <Container as="main" tw="relative z-[0] m-auto overflow-hidden">
                <EmissionClockGlobe
                    toggleModal={toggleModal}
                    activeScenario={activeScenario}
                    selectedYear={selectedYear}
                    width={width}
                    emissions_world={emissions_world}
                    country_sector_probabilities={country_sector_probabilities}
                    countries_flags={countries_flags}
                />
                {isNotEmptyObject(countryCoordinatesAndEmissions) &&
                    isNotEmptyObject(
                        southAfricaSubregionsCoordinatesAndEmissions
                    ) && (
                        <EmissionClockMap
                            classifications_income={classifications_income}
                            globalCarbonEmissions={globalCarbonEmissions}
                            temperature={temperature}
                            toggleModal={toggleModal}
                            selectedYear={selectedYear}
                            activeScenario={activeScenario}
                            emissions_main={emissions_main}
                            countries_flags={countries_flags}
                            countries_geojson={countries_geojson}
                            countries_stats={countries_stats}
                            subsectors_all={subsectors_all}
                            southAfricaSubregionsCoordinatesAndEmissions={
                                southAfricaSubregionsCoordinatesAndEmissions
                            }
                            areSubRegionsLoading={areSubRegionsLoading}
                            prevSouthAfricaSubregionsCoordinatesAndEmissions={
                                prevSouthAfricaSubregionsCoordinatesAndEmissions
                            }
                            sectors_compositions={sectors_compositions}
                            countryCoordinatesAndEmissions={
                                countryCoordinatesAndEmissions
                            }
                            prevCountryCoordinatesAndEmissions={
                                prevCountryCoordinatesAndEmissions
                            }
                            isLoading={isLoading}
                            handleTypeOfMapEmissions={handleTypeOfMapEmissions}
                            typeOfMapEmissions={typeOfMapEmissions}
                            emissions_cumulative={emissions_cumulative}
                            temperature_increase={temperature_increase}
                        />
                    )}
                <EmissionClockSectors
                    globalCarbonEmissions={globalCarbonEmissions}
                    isTimelineClicked={isTimelineClicked}
                    activeScenario={activeScenario}
                    selectedYear={selectedYear}
                    countries_flags={countries_flags}
                    countries_geojson={countries_geojson}
                    emissions_main={emissions_main}
                    countries_stats={countries_stats}
                    subsectors_all={subsectors_all}
                    sectors_compositions={sectors_compositions}
                    countries_maximums_emissions={countries_maximums_emissions}
                />
                <EmissionClockCompare
                    selectedYear={selectedYear}
                    activeScenario={activeScenario}
                    emissions_main={emissions_main}
                    countries_flags={countries_flags}
                    countries_stats={countries_stats}
                />
                <EmissionClockMore />
                <Container
                    as="section"
                    tw="bg-teal-100 fixed lg:p-[1rem] w-full bottom-0 z-[1004] md:bg-white shadow md:p-0"
                >
                    <PredictFutureBar
                        selectedYear={selectedYear}
                        handleSetSelectedYear={handleSetSelectedYear}
                        handleTimelineInteraction={handleTimelineInteraction}
                        activeScenario={activeScenario}
                        handleSetActiveScenario={handleSetActiveScenario}
                        scenarioOptions={scenarioOptions}
                        globalCarbonEmissions={globalCarbonEmissions}
                        areCurrentYearEmissionsOverBudget={
                            areCurrentYearEmissionsOverBudget
                        }
                        showWarning={showWarning}
                        handleSetIsScenarioDropOpen={
                            handleSetIsScenarioDropOpen
                        }
                        isScenarioDropOpen={isScenarioDropOpen}
                        selectedYearMobile={selectedYearMobile}
                    />
                </Container>
            </Container>
            <Container as="footer" tw="mt-[7rem] text-white">
                <div tw="bg-teal-600">
                    <Grid cols={12}>
                        <Col col={4} md={12}>
                            <FooterSignature />
                        </Col>
                        <Col col={8} md={12}>
                            <FooterSocials />
                        </Col>
                    </Grid>
                </div>
                <Grid cols={12} md={4} tw="mb-[8rem]">
                    <Col col={12} md={12}>
                        <FooterSupported />
                    </Col>
                </Grid>
            </Container>
            {width <= 768 && (
                <ModalMobile show={modal.isActive} type="message">
                    <div tw="p-[1.5rem 1rem]">
                        <h5 tw="mb-[1rem] font-semiBold text-xl">
                            {modal.title}
                        </h5>
                        <p>{modal.message}</p>
                    </div>
                    <div tw="flex flex-col p-[1.5rem] items-center border-t border-gray-200">
                        <Button
                            tw="max-w-[5.93rem] hidden md:block"
                            type="modal"
                            onClick={toggleModal}
                        >
                            Close
                        </Button>
                    </div>
                </ModalMobile>
            )}
        </>
    );
};

export default EmissionClock;
