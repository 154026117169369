import tw from 'twin.macro';

export const ButtonType = {
  primary: tw`bg-teal-500 text-white`,
  secondary: tw`text-teal-500 border border-teal-500`,
  emission: tw`bg-white border border-gray-100 text-orange-400 font-semiBold px-[0.75rem] text-sm`,
  emissionFocus: tw`bg-orange-500 text-white font-semiBold px-[0.75rem] text-sm`,
  hover: tw`bg-teal-100 text-teal-500`,
  focus: tw`bg-teal-800 text-white`,
  modal: tw`bg-white border border-teal-500 text-teal-500`,
  sectors: tw`text-teal-800 rounded-[0.5rem] w-auto h-auto p-[1rem]`,
  sectorsFocus: tw`bg-orange-100`,
};

export const ButtonSize = {
  sm: tw`p-[2 4] text-xs`,
  lg: tw`p-[3 6] text-lg`,
};
