import React from 'react';
import logo from '../../assets/images/header/logo.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const Logo = () => (
  <>
    <img tw="pr-4" src={logo} alt="World Data Emissions Logo" />
    <h1 tw="text-[0.625rem] text-teal-500">
      WORLD EMISSIONS CLOCK
      <span tw="block text-gray-500">by WORLD DATA LAB</span>
    </h1>
  </>
);

export default Logo;
