import { useCallback } from "react";
import FlipNumbers from "react-flip-numbers";
import useWindowSize from "../../../custom-hooks/useWindowsSize";
import { formatEmissions, currentYear } from "../../../utils/common";
import { filterCountriesByYearAndEmissions } from "../../../utils/dataFormatting";
import { theme } from "twin.macro";

const EmissionsClockCounter = ({
    activeScenario,
    selectedYear,
    emissions_world
}) => {
    const { width } = useWindowSize();

    const calculateCurrentYearEmissions = useCallback(() => {
        const emissionsCurrentYear = filterCountriesByYearAndEmissions(
            selectedYear,
            emissions_world,
            activeScenario.id
        ).reduce((acc, item) => {
            if (currentYear === selectedYear) {
                return acc + item.base;
            } else {
                return acc + item[activeScenario.id];
            }
        }, 0);

        return {
            emissionsCurrentYear
        };
    }, [activeScenario, selectedYear, emissions_world]);

    const currentYearStyles = {
        color: theme`colors.orange.400`,
        fontSize: width <= 768 ? "1rem" : "1.5rem",
        fontWeight: 600,
        fontFamily: "Work Sans"
    };

    return (
        calculateCurrentYearEmissions().emissionsCurrentYear && (
            <FlipNumbers
                height={width <= 768 ? 15 : 30}
                width={width <= 768 ? 10 : 15}
                color={theme`colors.orange.400`}
                play
                perspective={100000}
                numbers={`${formatEmissions(
                    calculateCurrentYearEmissions().emissionsCurrentYear
                ).slice(0, -4)},000`}
                numberStyle={currentYearStyles}
                nonNumberStyle={currentYearStyles}
            />
        )
    );
};

export default EmissionsClockCounter;
