import { useState, useEffect, useCallback } from "react";
import { unzipFile } from "../utils/unzip";
import { sortByEmissions } from "../utils/dataFormatting";
import { formatDigits } from "../utils/common";
import {
    regionColorsEmissions,
    handleLegendFormat,
    getEmissionsColor
} from "../utils/leafletMap";

const useSouthAfricaEmissions = (
    south_africa_geojson,
    selectedYear,
    activeScenario,
    sectorsCompositions
) => {
    const [
        southAfricaSubregionsCoordinatesAndEmissions,
        setSouthAfricaSubregionsCoordinatesAndEmissions
    ] = useState({});
    const [
        prevSouthAfricaSubregionsCoordinatesAndEmissions,
        setPrevSouthAfricaSubregionsCoordinatesAndEmissions
    ] = useState({});
    const [areSubRegionsLoading, setAreSubRegionsLoading] = useState(false);

    const handleRegionsColor = useCallback(() => {
        setAreSubRegionsLoading(true);
        let updatedSouthAfricaSubregionsCoordinatesAndEmissions = [];
        south_africa_geojson.map((item) =>
            unzipFile(
                `data/subCountries/subsectors_${item.properties.CC_1}.json.gz`
            ).then(({ data }) => {
                let filteredData = data.filter(
                    (item) => item.year === selectedYear
                );
                filteredData = filteredData.map((item) => {
                    const findSubSector = sectorsCompositions.find(
                        ({ subsector_short_name }) =>
                            subsector_short_name === item.subsector
                    );
                    return {
                        ...item,
                        subsector_long_name: findSubSector?.subsector_long_name,
                        subsector_short_name:
                            findSubSector?.subsector_short_name,
                        emissions: item[activeScenario]
                    };
                });
                updatedSouthAfricaSubregionsCoordinatesAndEmissions.push({
                    ...item,
                    subRegionInfoGrouped: Object.values(
                        [
                            filteredData.reduce((acc, item) => {
                                acc[item.sector] = acc[item.sector] || [];
                                acc[item.sector].push(item);
                                return acc;
                            }, {})
                        ][0]
                    ),
                    subRegionInfo: [...filteredData]
                });
                if (
                    updatedSouthAfricaSubregionsCoordinatesAndEmissions.length ===
                    9
                ) {
                    updatedSouthAfricaSubregionsCoordinatesAndEmissions =
                        updatedSouthAfricaSubregionsCoordinatesAndEmissions.map(
                            (country) => {
                                return {
                                    ...country,
                                    emissions: country.subRegionInfo.reduce(
                                        (accumulator, curr) =>
                                            accumulator + curr[activeScenario],
                                        0
                                    )
                                };
                            }
                        );

                    updatedSouthAfricaSubregionsCoordinatesAndEmissions =
                        updatedSouthAfricaSubregionsCoordinatesAndEmissions.map(
                            (country) => {
                                const legendContent = country.emissions;
                                const sign = Math.sign(legendContent);
                                return {
                                    ...country,
                                    emissions:
                                        sign >= 0
                                            ? Math.sqrt(Math.abs(legendContent))
                                            : Math.sqrt(
                                                  Math.abs(legendContent)
                                              ) * -1
                                };
                            }
                        );
                    updatedSouthAfricaSubregionsCoordinatesAndEmissions =
                        sortByEmissions(
                            updatedSouthAfricaSubregionsCoordinatesAndEmissions
                        );
                    const highestEmissionsRegion =
                        updatedSouthAfricaSubregionsCoordinatesAndEmissions[0]
                            ?.emissions;
                    const lowestEmissionsRegion =
                        updatedSouthAfricaSubregionsCoordinatesAndEmissions[
                            updatedSouthAfricaSubregionsCoordinatesAndEmissions.length -
                                1
                        ]?.emissions;
                    const range =
                        (highestEmissionsRegion - lowestEmissionsRegion) / 6;
                    const updatedRegionColorsEmissions =
                        regionColorsEmissions.map((emission, i) => {
                            const legendFirstRange =
                                lowestEmissionsRegion + range * (i - 1);
                            const legendFirstRangeSquared =
                                handleLegendFormat(legendFirstRange);
                            const legendSecondRange =
                                lowestEmissionsRegion + range * i;
                            const legendSecondRangeSquared =
                                handleLegendFormat(legendSecondRange);

                            const legendRange = `${formatDigits(
                                legendFirstRangeSquared
                            )} - ${formatDigits(legendSecondRangeSquared)}`;
                            return {
                                ...emission,
                                text: i === 0 ? "NA" : legendRange
                            };
                        });
                    updatedSouthAfricaSubregionsCoordinatesAndEmissions =
                        updatedSouthAfricaSubregionsCoordinatesAndEmissions.map(
                            (region) => ({
                                ...region,
                                fillColor: getEmissionsColor(
                                    region.emissions,
                                    range,
                                    lowestEmissionsRegion
                                )
                            })
                        );
                    setPrevSouthAfricaSubregionsCoordinatesAndEmissions({
                        updatedRegionColorsEmissions:
                            southAfricaSubregionsCoordinatesAndEmissions.updatedRegionColorsEmissions,
                        features:
                            southAfricaSubregionsCoordinatesAndEmissions.features
                    });
                    setSouthAfricaSubregionsCoordinatesAndEmissions({
                        updatedRegionColorsEmissions,
                        features:
                            updatedSouthAfricaSubregionsCoordinatesAndEmissions
                    });
                    setTimeout(() => setAreSubRegionsLoading(false), 5);
                }
            })
        );
    }, [activeScenario, selectedYear]);

    useEffect(() => {
        handleRegionsColor();
    }, [handleRegionsColor]);

    return {
        southAfricaSubregionsCoordinatesAndEmissions,
        handleRegionsColor,
        areSubRegionsLoading,
        prevSouthAfricaSubregionsCoordinatesAndEmissions
    };
};

export default useSouthAfricaEmissions;
