import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// GlobalStyles imported globally to make specific classes to work together with twin.macro
import { GlobalStyles } from "twin.macro";
import ErrorBoundary from "./components/ErrorBoundary";

ReactDOM.render(
    <StrictMode>
        <ErrorBoundary>
            <App />
            <GlobalStyles />
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
