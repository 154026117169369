import React from 'react';
import { Col } from '../../assets/styles/grid';
import { Drop } from '../Drop/Drop';
import { ButtonType } from '../Button/ButtonStyle';
import Slider from '../SliderRange/Slider';
import arrow from '../../assets/images/arrow.svg';
import warning from '../../assets/images/warning.svg';
import futureInfoIcon from '../../assets/images/infoIcon.svg';
import { handleHoverStyle } from '../../utils/common';
import {
  bool,
  func,
  string,
  shape,
  arrayOf,
  object,
  number,
  oneOfType,
} from 'prop-types';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const PredictFutureOpened = ({
  isFutureBarOpen,
  isScenarioDropOpen,
  activeScenario,
  handleSetActiveScenario,
  handleTimelineInteraction,
  handleSetIsScenarioDropOpen,
  handleSetIsFutureBarOpen,
  scenarioOptions,
  selectedYear,
  handleSetSelectedYear,
  globalCarbonEmissions,
  areCurrentYearEmissionsOverBudget,
  showWarning,
  width,
}) => (
  <>
    <Col as="div" col={5} md={4} tw="flex items-center md:hidden relative">
      <h2
        css={[
          tw`relative top-[-0.2rem] text-teal-800 font-bold text-xl max-w-[6.313rem]`,
          isFutureBarOpen && tw` h-[3.875rem] mr-[2.25rem] max-w-[6.313rem]`,
        ]}
      >
        See the future
      </h2>
      <div>
        <h4
          css={[
            tw`text-teal-800 font-bold`,
            !isFutureBarOpen && tw`relative left-[4.375rem]`,
          ]}
        >
          Showing the future:
        </h4>
        <div css={[!isFutureBarOpen && tw`hidden`]}>
          <div
            tw="flex border-b border-b-blue width[fit-content] pt-1 items-center cursor-pointer"
            onClick={handleSetIsScenarioDropOpen}
          >
            <p tw="font-semiBold text-teal-800 text-sm inline-block">
              Scenario:
            </p>
            <button tw="font-medium text-gray-800 ml-1">
              {activeScenario.title}
            </button>
            <img
              alt="Arrow"
              src={arrow}
              css={[
                tw`duration-500 pl-4`,
                isFutureBarOpen ? tw`rotate-0` : tw`rotate-180`,
              ]}
            />
          </div>
          {activeScenario.title === 'We achieve the goal' ? (
            <p tw="text-xs text-gray-800 pt-2">
              {activeScenario.text}
              <span
                css={[tw`cursor-pointer`, handleHoverStyle(769, true)]}
                message="This scenario is not based on a fair- share consideration but shows the most cost-effective way to reach the Paris Agreement goal of limitting global warming to 1.5 °C."
              >
                <img
                  src={futureInfoIcon}
                  alt="Information Icon"
                  tw="inline pl-1"
                />
                <span tw="hidden">
                  This scenario is not based on a fair- share consideration but
                  shows the most cost-effective way to reach the Paris Agreement
                  goal of limitting global warming to 1.5 °C.
                </span>
              </span>
            </p>
          ) : (
            <p tw="text-xs text-gray-800 pt-2">{activeScenario.text}</p>
          )}
        </div>
      </div>
      <span css={[isFutureBarOpen && tw`border border-teal-200`]}></span>
      <button
        tw="border border-teal-500 rounded-[0.25rem] p-[0.5rem] hidden"
        onClick={handleSetIsFutureBarOpen}
      >
        <img
          alt="Arrow"
          src={arrow}
          css={[
            tw`duration-500`,
            isFutureBarOpen ? tw`rotate-180` : tw`rotate-0`,
          ]}
        />
      </button>
    </Col>
    <Col
      as="div"
      col={6}
      md={4}
      tw="text-sm flex justify-start text-gray-800 relative md:hidden"
    >
      <div tw="flex items-center">
        <p css={[isFutureBarOpen && tw`hidden`]}>Scenario:</p>
        <button
          onClick={handleSetIsScenarioDropOpen}
          css={[
            isFutureBarOpen
              ? tw`hidden`
              : tw`font-medium underline cursor-pointer ml-1`,
          ]}
        >
          {activeScenario.title}
        </button>
      </div>
      <div tw="flex items-center">
        <p css={[isFutureBarOpen ? tw`hidden` : tw`ml-[3rem]`]}>Year: </p>
        <button
          onClick={handleSetIsFutureBarOpen}
          css={[
            isFutureBarOpen
              ? tw`hidden`
              : tw`font-medium underline cursor-pointer ml-1`,
          ]}
        >
          {selectedYear}
        </button>
      </div>
      <div
        css={[
          tw`w-full m-[0 2.62rem] hidden`,
          isFutureBarOpen && tw`flex justify-center flex-col`,
        ]}
      >
        {isFutureBarOpen && (
          <Slider
            currentYear={selectedYear}
            handleSetSelectedYear={handleSetSelectedYear}
            handleTimelineInteraction={handleTimelineInteraction}
            globalCarbonEmissions={globalCarbonEmissions}
            activeScenario={activeScenario}
            areCurrentYearEmissionsOverBudget={
              areCurrentYearEmissionsOverBudget
            }
          />
        )}
      </div>
      <Drop
        isScenarioDropOpen={isScenarioDropOpen}
        isFutureBarOpen={isFutureBarOpen}
      >
        {scenarioOptions.map(({ id, title }, i) => (
          <button
            tw="py-2.5 w-full"
            onClick={handleSetActiveScenario}
            key={id}
            value={id}
            css={[
              scenarioOptions.length - 1 !== i && tw`border-b border-gray-100`,
              activeScenario.title === title && ButtonType['focus'],
            ]}
          >
            {title}
          </button>
        ))}
      </Drop>
    </Col>
    <Col as="div" col={1} md={2} tw="flex flex-col items-end md:hidden">
      <button
        tw="border border-teal-500 rounded-[0.25rem] p-[0.5rem]"
        onClick={handleSetIsFutureBarOpen}
      >
        <img
          alt="Arrow"
          src={arrow}
          css={[
            tw`duration-500`,
            isFutureBarOpen ? tw`rotate-180` : tw`rotate-0`,
          ]}
        />
      </button>
      {showWarning && (
        <img
          alt="Warning"
          src={warning}
          css={[tw`hidden mt-[1rem]`, isFutureBarOpen && tw`block`]}
        />
      )}
    </Col>
  </>
);

PredictFutureOpened.propTypes = {
  scenarioOptions: arrayOf(object),
  selectedYear: number.isRequired,
  handleSetSelectedYear: func.isRequired,
  globalCarbonEmissions: oneOfType([
    shape({
      isOverBudget: bool.isRequired,
      percentage: string.isRequired,
      budget: string.isRequired,
      emissions: string.isRequired,
      isOverBudgetValue: string,
    }),
    object,
  ]).isRequired,
  areCurrentYearEmissionsOverBudget: arrayOf(object),
  showWarning: bool.isRequired,
  isFutureBarOpen: bool.isRequired,
  isScenarioDropOpen: bool.isRequired,
  activeScenario: shape({
    title: string,
    text: string,
  }).isRequired,
  handleSetActiveScenario: func.isRequired,
  handleSetIsScenarioDropOpen: func.isRequired,
  handleSetIsFutureBarOpen: func.isRequired,
  handleChange: func.isRequired,
};

PredictFutureOpened.defaultProps = {
  isFutureBarOpen: false,
  isScenarioDropOpen: false,
  selectedYear: new Date().getFullYear(),
  activeScenario: {
    id: 'base',
    text: 'In this scenario, states continue with their current trajectory',
    title: 'Business as usual',
  },
  areCurrentYearEmissionsOverBudget: [],
  showWarning: false,
  globalCarbonEmissions: {
    isOverBudget: false,
    percentage: '0',
    budget: '0',
    emissions: '0',
    isOverBudgetValue: null,
    width: null,
    toggleModal: () => {},
    selectedCountry: shape({
      iso3c: 'WLD',
    }),
  },
  scenarioOptions: [
    {
      id: 'base',
      title: 'Business as usual',
      text: 'In this scenario, states continue with their current trajectory',
    },
    {
      id: 'ndc',
      title: 'Do as we promised',
      text: 'In this scenario, states follow the NDCs, the commitments they made to fight climate change.',
    },
    {
      id: 'o_1p5c',
      title: 'We achieve the goal',
      text: 'In this scenario, you will see what is necessary to reach the the Paris Agreement goal',
    },
  ],
  handleSetActiveScenario: () => {},
  handleSetIsScenarioDropOpen: () => {},
  handleSetIsFutureBarOpen: () => {},
  handleChange: () => {},
  handleSetSelectedYear: () => {},
};

export default PredictFutureOpened;
