import { useEffect, useState, useCallback } from 'react';
import { filterCountriesByYearAndEmissions } from '../utils/dataFormatting';
import { handleGlobalCarbonEmissions } from '../utils/globalCarbonEmissions';
import { handleTemperature } from '../utils/temperature';

const useLoadEmissionsWorld = (
  selectedYear,
  activeScenario,
  emissions_world,
  temperature_increase
) => {
  const [globalCarbonEmissions, setGlobalCarbonEmissions] = useState({});
  const [temperature, setTemperature] = useState(null);
  const [
    areCurrentYearEmissionsOverBudget,
    setAreCurrentYearEmissionsOverBudget,
  ] = useState([]);
  const [showWarning, setShowWarning] = useState(false);

  const updatedGlobalCarbonEmissions = filterCountriesByYearAndEmissions(
    selectedYear,
    emissions_world,
    activeScenario,
    'globalCarbonEmissions'
  );

  const emissionsLimit = emissions_world
    .filter((emissions) => emissions.gas === 'CO2')
    .reduce((accumulator, itemB) => accumulator + itemB['o_1p5c'], 0);

  const handleAreEmissionsOverBudget = useCallback(() => {
    const areCurrentYearEmissionsOverBudget = [];
    const total = filterCountriesByYearAndEmissions(
      selectedYear,
      emissions_world,
      activeScenario,
      'totalEmissions'
    ).reduce((previousValue, currentValue) => {
      const sum = previousValue + currentValue[activeScenario];
      areCurrentYearEmissionsOverBudget.push({
        year: currentValue.year,
        isOverBudget:
          previousValue + currentValue[activeScenario] > emissionsLimit,
      });
      return sum;
    }, 0);
    const showWarning = areCurrentYearEmissionsOverBudget.some(
      (year) => year.year === selectedYear && year.isOverBudget
    );
    setAreCurrentYearEmissionsOverBudget(areCurrentYearEmissionsOverBudget);
    setShowWarning(showWarning);
    return total;
  }, [selectedYear, activeScenario, emissions_world, emissionsLimit]);

  useEffect(() => {
    setGlobalCarbonEmissions(
      handleGlobalCarbonEmissions(updatedGlobalCarbonEmissions, emissionsLimit)
    );
    setTemperature(
      handleTemperature(temperature_increase, selectedYear, activeScenario)
    );
  }, [
    updatedGlobalCarbonEmissions,
    emissionsLimit,
    temperature_increase,
    selectedYear,
    activeScenario,
  ]);

  useEffect(() => {
    handleAreEmissionsOverBudget();
  }, [handleAreEmissionsOverBudget]);

  return {
    globalCarbonEmissions,
    showWarning,
    areCurrentYearEmissionsOverBudget,
    temperature,
  };
};

export default useLoadEmissionsWorld;
