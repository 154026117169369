import arrow from '../../../../assets/images/arrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { isNotEmptyObject, handleHoverStyle } from '../../../../utils/common';
import tooltipIcon from '../../../../assets/images/tooltipIcon.svg';
// import Chart from '../../../Chart/Chart';
import useWindowSize from '../../../../custom-hooks/useWindowsSize';
import Chart from '../../../Chart/Chart';

const EmissionPersonTitle = ({
  firstCountry,
  secondCountry,
  handleToggle,
  isOpen,
  toggleModal,
  emissions_main,
}) => {
  const { width } = useWindowSize();
  let results = emissions_main.reduce((results, item) => {
    (results[item.year] = results[item.year] || []).push(item);
    return results;
  }, []);
  results = results.filter((item) => item !== undefined);
  const resultsFirst = results.map((item) =>
    item.filter(({ iso3c }) => iso3c === firstCountry.iso3c)
  );
  const resultsSecond = results.map((item) =>
    item.filter(({ iso3c }) => iso3c === secondCountry.iso3c)
  );
  const businessAsUsual = {
    countryOne: resultsFirst.map((item) => {
      return [item[0].year, item.reduce((acc, curr) => acc + curr.base, 0)];
    }),
    countryTwo: resultsSecond.map((item) => {
      return [item[0].year, item.reduce((acc, curr) => acc + curr.base, 0)];
    }),
  };
  const doAsWePromised = {
    countryOne: resultsFirst.map((item) => {
      return [item[0].year, item.reduce((acc, curr) => acc + curr.ndc, 0)];
    }),
    countryTwo: resultsSecond.map((item) => {
      return [item[0].year, item.reduce((acc, curr) => acc + curr.ndc, 0)];
    }),
  };

  const weAchieveTheGoal = {
    countryOne: resultsFirst.map((item) => {
      return [item[0].year, item.reduce((acc, curr) => acc + curr.o_1p5c, 0)];
    }),
    countryTwo: resultsSecond.map((item) => {
      return [item[0].year, item.reduce((acc, curr) => acc + curr.o_1p5c, 0)];
    }),
  };

  const implementationGap = {
    countryOne: businessAsUsual.countryOne.map((v, i) => {
      return [v[0], v[1] - doAsWePromised.countryOne[i][1]];
    }),
    countryTwo: businessAsUsual.countryTwo.map((v, i) => {
      return [v[0], v[1] - doAsWePromised.countryTwo[i][1]];
    }),
  };

  const ambitionGap = {
    countryOne: doAsWePromised.countryOne.map((v, i) => {
      return [v[0], v[1] - weAchieveTheGoal.countryOne[i][1]];
    }),
    countryTwo: doAsWePromised.countryTwo.map((v, i) => {
      return [v[0], v[1] - weAchieveTheGoal.countryTwo[i][1]];
    }),
  };

  return (
    <>
      <div tw="flex justify-between text-sm items-center md:flex-wrap md:justify-between md:gap-[2rem]">
        <div tw="w-[40%] border border-gray-100 rounded-[0.5rem] overflow-hidden md:w-full">
          <Chart
            businessAsUsual={businessAsUsual}
            doAsWePromised={doAsWePromised}
            weAchieveTheGoal={weAchieveTheGoal}
            country="countryOne"
            implementationGap={implementationGap}
            ambitionGap={ambitionGap}
            countryName={firstCountry.name}
            width={width}
          />
        </div>
        <div tw="text-align[left] w-[14rem] pl-[2.75rem] pr-[2.188rem] md:m-auto">
          <div tw="text-[0.625rem] border-b-2 border-gray-100">
            <h4 tw="font-semiBold pb-[0.5rem] ">Emissions through time:</h4>
            <span tw="flex items-center text-[#5C5C5C] pb-[0.5rem]">
              <span tw="bg-orange-500 w-[0.836rem] h-[0.2rem] block mr-[0.289rem]"></span>
              Business as usual
            </span>
            <span tw="flex items-center text-[#5C5C5C] pb-[0.5rem]">
              <span tw="bg-[#375EC0] w-[0.795rem] h-[0.2rem] block mr-[0.289rem]"></span>
              Do as we promise
            </span>
            <span tw="flex items-center text-[#5C5C5C] pb-[0.5rem]">
              <span tw="bg-[#186B3E] w-[0.75rem] h-[0.2rem] block mr-[0.289rem]"></span>
              We achieve the goal
            </span>
          </div>
          <div>
            <div tw="flex items-center gap-[1.25rem] text-[0.625rem] pt-[0.5rem]">
              <h4 tw="font-semiBold">Emission gaps</h4>
              <span
                css={[tw`cursor-pointer relative`, handleHoverStyle(769)]}
                title="Emissions gap"
                message="
                About the gaps
                Implementation gap:
                This gap shows how much emissions we are suposed to reduce if we implement the NDCs for each country.
                Ambition gap:
                This is the gap that shows how much emissions we are supose to reduce if we want to achieve the Paris Agreement Goals
              "
                onClick={width <= 768 ? toggleModal : undefined}
              >
                <img alt="Tooltip icon" src={tooltipIcon} width={16} />
                <span tw="hidden">
                  <h4 tw="pb-[0.375rem] font-semiBold">About the gaps</h4>
                  <h5 tw="pb-[0.375rem] pt-[0.375rem] font-medium">
                    Implementation gap:
                  </h5>
                  <p tw="border-b border-b-gray-50 pb-[0.375rem]">
                    This gap shows by how much GHG emissions would be reduced if
                    countries fully implement their NDCs compared to
                    business-as-usual.
                  </p>
                  <h5 tw="pb-[0.375rem] pt-[0.375rem] font-medium">
                    Ambition gap:
                  </h5>
                  <p>
                    This gap shows by how much GHG emissions would need to be
                    reduced further to achieve the Paris Agreement goals
                    compared to when NDCs are fully implemented
                  </p>
                </span>
              </span>
            </div>
            <span tw="flex items-center text-[#5C5C5C] pb-[0.5rem] pt-[0.813rem] text-[0.625rem]">
              <span tw="bg-[#FFCDB4] w-[0.563rem] h-[0.563rem] block mr-[0.289rem] rounded-[50%]"></span>
              Implementation gap
            </span>
            <span tw="flex items-center text-[#5C5C5C] text-[0.625rem]">
              <span tw="bg-[#ACE3DA] w-[0.563rem] h-[0.563rem] block mr-[0.289rem] rounded-[50%]"></span>
              Ambition gap
            </span>
          </div>
        </div>
        <div tw="w-[40%] border border-gray-100 rounded-[0.5rem] overflow-hidden md:w-full">
          <Chart
            businessAsUsual={businessAsUsual}
            doAsWePromised={doAsWePromised}
            weAchieveTheGoal={weAchieveTheGoal}
            country="countryTwo"
            implementationGap={implementationGap}
            ambitionGap={ambitionGap}
            countryName={secondCountry.name}
            width={width}
          />
        </div>
      </div>
      <div tw="flex justify-around m-[1.5rem auto 1.5rem] w-full">
        <p tw="text-[2.25rem] font-semiBold text-orange-400 md:text-lg">
          {firstCountry?.emissions_per_person?.toFixed(1)}{' '}
          {firstCountry?.emissions_per_person && 'tons'}
        </p>
        <span tw="border border-gray-100"></span>
        <p tw="text-[2.25rem] font-semiBold text-orange-400 md:text-lg">
          {secondCountry?.emissions_per_person?.toFixed(1)}{' '}
          {secondCountry?.emissions_per_person && 'tons'}
        </p>
      </div>
      {isNotEmptyObject(firstCountry) && isNotEmptyObject(secondCountry) && (
        <button
          tw="flex justify-center cursor-pointer items-center m-[0 auto] max-w-[37rem] w-full"
          onClick={handleToggle}
        >
          <span tw="text-teal-500 font-regular mr-[0.625rem] text-sm">
            View sectors comparison
          </span>
          <img
            alt="Arrow"
            width={14}
            src={arrow}
            tw="transition duration-500"
            style={{ transform: isOpen && `rotate(180deg)` }}
          />
        </button>
      )}
    </>
  );
};

export default EmissionPersonTitle;
