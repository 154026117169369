import { Col } from '../../assets/styles/grid';
import DynamicSvg from '../DynamicSvg/DynamicSvg';
import { yearRange } from '../../utils/slider';
import { Button } from '../Button/Button';
import { ButtonType } from '../Button/ButtonStyle';
import { Drop } from '../Drop/Drop';
import arrow from '../../assets/images/arrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const PredictFutureOpenedMobile = ({
  activeScenario,
  handleSetIsFutureBarOpen,
  selectedYear,
  handleSetActiveScenario,
  isScenarioDropOpen,
  handleSetIsScenarioDropOpen,
  scenarioOptions,
  isFutureBarOpen,
  handleSetSelectedYear,
  selectedYearMobile,
}) => (
  <Col
    as="div"
    md={4}
    css={[
      tw`hidden md:block text-gray-500`,
      !isFutureBarOpen && tw`h-[100vh] pt-[4.18rem]`,
    ]}
  >
    <div css={[tw`block`, !isFutureBarOpen && tw`hidden`]}>
      <div tw=" md:flex md:justify-between font-regular text-sm border-b border-gray-200 p-[0.85rem 1rem]">
        <p>Scenario: {activeScenario.title}</p>
        <p>Year: {selectedYear} </p>
      </div>
      <Button
        tw="flex items-center justify-between p-[1.2rem] font-regular max-w-[15.65rem] m-[0.85rem auto]"
        onClick={handleSetIsFutureBarOpen}
      >
        See the future <DynamicSvg iconName="gear" />
      </Button>
    </div>
    <div css={[tw`hidden`, !isFutureBarOpen && tw`block`]}>
      <h3 tw="text-xl font-bold text-teal-500 p-[1rem] border-b border-gray-200">
        See the future
      </h3>
      <div tw="flex justify-between items-center p-[1rem] border-b border-gray-200 relative">
        <div>
          <p tw="text-[1.125rem] text-gray-800 font-bold">
            Scenario: <span tw="font-medium">{activeScenario.title}</span>
          </p>
          <p tw="text-gray-500 text-sm">{activeScenario.text}</p>
        </div>
        <div>
          <button onClick={handleSetIsScenarioDropOpen}>
            <img alt="Arrow" src={arrow} />
          </button>
        </div>
        <Drop isScenarioDropOpen={isScenarioDropOpen} tw="text-sm">
          {scenarioOptions.map(({ id, title }, i) => (
            <button
              tw="py-2.5 w-full"
              onClick={handleSetActiveScenario}
              key={id}
              value={id}
              css={[
                scenarioOptions.length - 1 !== i &&
                  tw`border-b border-gray-100`,
                activeScenario.title === title && ButtonType['focus'],
              ]}
            >
              {title}
            </button>
          ))}
        </Drop>
      </div>
      <p tw="text-[1.125rem] text-gray-800 font-bold p-[1rem 1rem 0]">
        Year: <span tw="font-medium">{selectedYear}</span>
      </p>
      <p tw="text-gray-500 text-sm p-[0 1rem]">
        See emissions related to the selected year
      </p>
      <input
        tw="border border-gray-100 rounded-[0.5rem] text-teal-500 text-[1.125rem] max-w-[4.375rem] text-center m-[0.5rem 1rem] p-[0.85rem 0]"
        type="number"
        value={selectedYearMobile}
        onChange={({ currentTarget }) =>
          handleSetSelectedYear(parseFloat(currentTarget.value))
        }
        title="Selected Year"
        aria-label="Selected Year"
      />
      <input
        tw="hidden md:flex w-[calc(100% - 2rem)] md:m-[2rem auto 0]"
        type="range"
        min={yearRange.start}
        max={yearRange.end}
        value={selectedYear}
        onChange={({ currentTarget }) =>
          handleSetSelectedYear(parseFloat(currentTarget.value))
        }
        title="Range"
        aria-label="Range"
      />
    </div>
    <div
      css={[
        tw`hidden bg-white`,
        !isFutureBarOpen &&
          tw`flex flex-col p-[1.5rem] items-center border-t border-gray-200 w-full absolute bottom-0`,
      ]}
    >
      <Button
        type="modal"
        onClick={handleSetIsFutureBarOpen}
        tw="max-w-[5.93rem]"
      >
        Close
      </Button>
    </div>
  </Col>
);

export default PredictFutureOpenedMobile;
