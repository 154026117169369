import pako from 'pako';
import { fetchOptions } from './common';

export const unzipFile = async (json_to_unzip) => {
  try {
    const res = await fetch(json_to_unzip, fetchOptions);
    const buf = await res.arrayBuffer();
    const outBuf = pako.inflate(buf);
    const str = new TextDecoder().decode(outBuf);
    const unzippedFile = JSON.parse(str);
    return unzippedFile[0];
  } catch (error) {
    console.error('unable to decompress', error);
    return error;
  }
};
