import { bool } from 'prop-types';
/** @jsxImportSource @emotion/react */
import tw, { styled, css } from 'twin.macro';

export const ModalMobile = styled.div(({ show }) => [
  tw`translate-y-full bg-white fixed w-full bottom-0 top-[4.18rem] text-gray-800 flex flex-col justify-between transition-all duration-1000`,
  css`
    ${show && tw`translate-y-0`}
  `,
]);

ModalMobile.propTypes = {
  show: bool.isRequired,
};

ModalMobile.defaultProps = {
  show: false,
};
