import React, { useCallback, useState } from 'react';
import { Grid } from '../../assets/styles/grid';
import PredictFutureOpened from './PredictFutureOpened';
import PredictFutureOpenedMobile from './PredictFutureOpenedMobile';
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { avoidBodyScrollOnMobile } from '../../utils/common';

const PredictFutureBar = ({
  selectedYear,
  handleSetSelectedYear,
  handleTimelineInteraction,
  selectedScenario,
  handleSetActiveScenario,
  activeScenario,
  scenarioOptions,
  handleSetIsScenarioDropOpen,
  isScenarioDropOpen,
  globalCarbonEmissions,
  areCurrentYearEmissionsOverBudget,
  showWarning,
  selectedYearMobile,
  width,
}) => {
  const [isFutureBarOpen, setIsFutureBarOpen] = useState(true);
  scenarioOptions = scenarioOptions.filter(
    ({ id }) => activeScenario.id !== id
  );

  const handleSetIsFutureBarOpen = useCallback(() => {
    setIsFutureBarOpen(!isFutureBarOpen);
    isFutureBarOpen
      ? avoidBodyScrollOnMobile(767, 'hidden')
      : avoidBodyScrollOnMobile(767, 'scroll');
  }, [isFutureBarOpen]);

  return (
    <Grid cols={12} tw="md:p-0">
      <PredictFutureOpened
        selectedScenario={selectedScenario}
        handleSetActiveScenario={handleSetActiveScenario}
        handleTimelineInteraction={handleTimelineInteraction}
        activeScenario={activeScenario}
        isFutureBarOpen={isFutureBarOpen}
        isScenarioDropOpen={isScenarioDropOpen}
        handleSetIsScenarioDropOpen={handleSetIsScenarioDropOpen}
        handleSetIsFutureBarOpen={handleSetIsFutureBarOpen}
        scenarioOptions={scenarioOptions}
        selectedYear={selectedYear}
        handleSetSelectedYear={handleSetSelectedYear}
        globalCarbonEmissions={globalCarbonEmissions}
        areCurrentYearEmissionsOverBudget={areCurrentYearEmissionsOverBudget}
        showWarning={showWarning}
        width={width}
      />
      <PredictFutureOpenedMobile
        handleSetActiveScenario={handleSetActiveScenario}
        activeScenario={activeScenario}
        handleSetIsFutureBarOpen={handleSetIsFutureBarOpen}
        selectedYear={selectedYear}
        isScenarioDropOpen={isScenarioDropOpen}
        handleSetIsScenarioDropOpen={handleSetIsScenarioDropOpen}
        scenarioOptions={scenarioOptions}
        isFutureBarOpen={isFutureBarOpen}
        handleSetSelectedYear={handleSetSelectedYear}
        selectedYearMobile={selectedYearMobile}
      />
    </Grid>
  );
};

export default PredictFutureBar;
