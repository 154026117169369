import React, { useCallback, useState } from 'react';
import { Col } from '../../../../assets/styles/grid';
import EmissionsPersonTitle from './EmissionPersonTitle';
import EmissionPersonDetails from './EmissionPersonDetails';
import { string, shape, number, arrayOf, object } from 'prop-types';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionsPersonCard = ({
  firstCountry,
  secondCountry,
  activeScenario,
  emissions_main,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <Col
      as="article"
      col={12}
      md={4}
      tw="flex w-full md:flex-none p-[1rem] sm:p-0 max-w-[80rem] m-[0 auto] rounded-[0.5rem] shadow sm:shadow-none flex-col text-center"
    >
      <EmissionsPersonTitle
        firstCountry={firstCountry}
        secondCountry={secondCountry}
        handleToggle={handleToggle}
        isOpen={isOpen}
        emissions_main={emissions_main}
      />
      {isOpen && (
        <EmissionPersonDetails
          firstCountry={firstCountry}
          secondCountry={secondCountry}
          activeScenario={activeScenario}
        />
      )}
    </Col>
  );
};

EmissionsPersonCard.propTypes = {
  firstCountry: shape({
    emissions: number.isRequired,
    emissions_per_person: number.isRequired,
    emissions_per_second: number.isRequired,
    file_url: string.isRequired,
    iso3c: string.isRequired,
    license: string.isRequired,
    name: string.isRequired,
    population: number.isRequired,
    sectors: arrayOf(object).isRequired,
    year: number.isRequired,
  }),
  secondCountry: shape({
    emissions: number.isRequired,
    emissions_per_person: number.isRequired,
    emissions_per_second: number.isRequired,
    file_url: string.isRequired,
    iso3c: string.isRequired,
    license: string.isRequired,
    name: string.isRequired,
    population: number.isRequired,
    sectors: arrayOf(object).isRequired,
    year: number.isRequired,
  }),
};

EmissionsPersonCard.defaultProps = {
  firstCountry: {},
  secondCountry: {},
};

export default EmissionsPersonCard;
