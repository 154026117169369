import puff from '../../../assets/images/section1Globe/puff.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockGlobePuff = ({ spinningSpeed, countrySectorPairs }) => (
  <img //h-[60vh]
    tw="w-[3vh] m-[0 auto 3rem] z-[2] relative md:w-[1.375rem] sm:w-[1rem] opacity-0 animate-fadeInUpPuff"
    alt="Puff"
    src={puff}
    style={{
      animationDuration: `${spinningSpeed / countrySectorPairs}s`,
    }}
  />
);

export default EmissionClockGlobePuff;
