import { useState, useEffect, useCallback } from "react";
import { avoidBodyScrollOnMobile } from "../utils/common";
import useWindowSize from "./useWindowsSize";
import { southAfricaCenterMap, getSubregionsInfo } from "../utils/leafletMap";

const useSelectedRegion = (
    selectedYear,
    activeScenario,
    southAfricaSubregionsCoordinatesAndEmissions,
    mapRef
) => {
    const [subRegionCardInfo, setSubRegionCardInfo] = useState({});
    const [selectedRegion, setSelectedRegion] = useState({});
    const { width } = useWindowSize();
    const [
        areSouthAfricaSubnationalsActive,
        setAreSouthAfricaSubnationalsActive
    ] = useState(false);
    const [targetMapSub, setTargetMapSub] = useState(null);

    const handleToggleSwitch = useCallback(() => {
        setAreSouthAfricaSubnationalsActive(
            (prevStateareSouthAfricaSubnationalsActive) => {
                !prevStateareSouthAfricaSubnationalsActive &&
                    setSelectedRegion({});
                return !prevStateareSouthAfricaSubnationalsActive;
            }
        );
    }, [areSouthAfricaSubnationalsActive]);

    const zoomToSubRegions = ({ target }) => {
        setTargetMapSub(target);
        mapRef.target.fitBounds(target.getBounds());
        const selectedRegion = getSubregionsInfo(
            target.feature,
            selectedYear,
            activeScenario.id
        );
        selectedRegion && setSubRegionCardInfo(selectedRegion);
        selectedRegion && setSelectedRegion(selectedRegion);
    };

    const handleResetRegion = useCallback(() => {
        setSelectedRegion({});
        mapRef.target.panTo(southAfricaCenterMap);
        setTimeout(() => mapRef.target.setZoom(5), 150);
    }, [mapRef]);

    const handleResetRegionFromParent = useCallback(() => {
        setSelectedRegion({});
    }, [mapRef]);

    const handleSubregionsEmissions = useCallback(
        (subRegionID) => {
            const selectedSubRegion =
                southAfricaSubregionsCoordinatesAndEmissions.find(
                    (subRegion) => subRegion.properties.CC_1 === subRegionID
                );
            if (selectedSubRegion) {
                const selectedRegionInfo = getSubregionsInfo(
                    selectedSubRegion,
                    selectedYear,
                    activeScenario.id
                );
                const targetMap = Object.values(mapRef.target._targets).find(
                    ({ feature }) =>
                        feature?.properties.CC_1 === selectedRegionInfo.iso3c
                );
                targetMap && mapRef.target.fitBounds(targetMap.getBounds());
                setSubRegionCardInfo(selectedRegionInfo);
                setSelectedRegion(selectedRegionInfo);
                avoidBodyScrollOnMobile(width, "hidden");
            }
        },
        [
            mapRef,
            selectedYear,
            activeScenario,
            southAfricaSubregionsCoordinatesAndEmissions,
            width
        ]
    );

    useEffect(() => {
        handleSubregionsEmissions();
    }, [handleSubregionsEmissions]);

    return {
        selectedRegion,
        subRegionCardInfo,
        zoomToSubRegions,
        handleToggleSwitch,
        handleResetRegion,
        handleSubregionsEmissions,
        areSouthAfricaSubnationalsActive,
        setAreSouthAfricaSubnationalsActive,
        targetMapSub,
        handleResetRegionFromParent
    };
};

export default useSelectedRegion;
