import React, { useRef, useCallback, useState, useEffect } from "react";
import { Col } from "../../../assets/styles/grid";
import { AnimatedSector } from "./AnimatedSector";
import EmissionClockCardDetails from "./DetailsCard/EmissionClockCardDetails";
import EmissionClockSectorsCategories from "./EmissionClockSectorsCategories";
import {
    handleImage,
    squaresAspectRatio
} from "../../../utils/sectorsEmissions";
import useWindowSize from "../../../custom-hooks/useWindowsSize";
import { formatEmissions } from "../../../utils/common";
import {
    oneOfType,
    func,
    shape,
    string,
    number,
    arrayOf,
    object
} from "prop-types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const EmissionClockAnimatedSectors = ({
    handleSelectedSector,
    handleActiveSectorType,
    selectedSectorList,
    selectedSector,
    totalEmissions,
    closeDetailsCard,
    subSectors,
    sortedRankings,
    activeScenario,
    isTimelineClicked,
    countries_maximums_emissions
}) => {
    const { width } = useWindowSize();
    const [aspectRatio, setAspectRatio] = useState({});
    const [totalEmissionsStable, setTotalEmissionsStable] = useState(
        !isTimelineClicked ? totalEmissions : {}
    );
    const animatedSectorIconHeight = width > 768 ? 231 : 135;
    const scrollableCategories = useRef();
    const scrollableSectors = useRef();

    const onScrollSectors = useCallback(() => {
        scrollableCategories.current.scrollLeft =
            scrollableSectors.current.scrollLeft;
    }, []);

    const onScrollCategories = useCallback(() => {
        scrollableSectors.current.scrollLeft =
            scrollableCategories.current.scrollLeft;
    }, []);

    useEffect(() => {
        if (!isTimelineClicked || aspectRatio === {}) {
            setTotalEmissionsStable(totalEmissions);
            const aspectRatio = squaresAspectRatio(
                totalEmissions.iso3c,
                totalEmissions.sectors,
                animatedSectorIconHeight,
                countries_maximums_emissions
            );
            setAspectRatio(aspectRatio["verticalTranslate"]);
        }
    }, [totalEmissions, isTimelineClicked]);

    return (
        <Col as="article" col={12} md={4} tw="relative lg:mb-[3rem] md:m-0">
            <div
                className="scrollableHorizontal scrollableSectors"
                tw="grid grid-cols-5 md:flex md:overflow-x-auto md:overflow-y-hidden md:px-[1rem]"
                ref={scrollableSectors}
                onScroll={onScrollSectors}
            >
                {totalEmissionsStable.sectors.map(
                    ({ isPlant, emissions }, i) => {
                        return (
                            <div key={i} tw="md:flex-[0 0 30%]">
                                <p
                                    css={[
                                        tw`text-orange-500 font-medium mb-[0.75rem] text-center transition duration-1000 md:text-[0.6rem]`,
                                        isPlant && tw`text-teal-500`
                                    ]}
                                    style={{
                                        transform: `translateY(${
                                            aspectRatio[i] >
                                            animatedSectorIconHeight
                                                ? 0
                                                : animatedSectorIconHeight -
                                                  aspectRatio[i]
                                        }px)`
                                    }}
                                >
                                    {`${formatEmissions(emissions, true)}`}
                                </p>
                                <div tw="h-[14.43rem] overflow-hidden flex justify-center border-b border-gray-200 md:h-auto">
                                    <AnimatedSector
                                        key={i}
                                        alt="Sector Emissions"
                                        src={handleImage(isPlant)}
                                        translate={
                                            !isNaN(
                                                aspectRatio[i] >
                                                    animatedSectorIconHeight
                                                    ? 0
                                                    : animatedSectorIconHeight -
                                                          aspectRatio[i]
                                            )
                                                ? aspectRatio[i] >
                                                  animatedSectorIconHeight
                                                    ? 0
                                                    : animatedSectorIconHeight -
                                                      aspectRatio[i]
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
            <EmissionClockSectorsCategories
                onScrollCategories={onScrollCategories}
                ref={scrollableCategories}
                handleSelectedSector={handleSelectedSector}
                totalEmissions={totalEmissionsStable}
            />
            <EmissionClockCardDetails
                totalEmissions={totalEmissionsStable}
                closeDetailsCard={closeDetailsCard}
                handleActiveSectorType={handleActiveSectorType}
                selectedSectorList={selectedSectorList}
                handleSelectedSector={handleSelectedSector}
                subSectors={subSectors}
                selectedSector={selectedSector}
                sortedRankings={sortedRankings}
                activeScenario={activeScenario}
            />
        </Col>
    );
};

EmissionClockAnimatedSectors.propTypes = {
    handleSelectedSector: func.isRequired,
    handleActiveSectorType: func.isRequired,
    closeDetailsCard: func.isRequired,
    totalEmissions: oneOfType([
        shape({
            emissions: number.isRequired,
            emissions_per_person: number.isRequired,
            emissions_per_second: number.isRequired,
            file_url: string.isRequired,
            iso3c: string.isRequired,
            license: string.isRequired,
            name: string.isRequired,
            population: number.isRequired,
            sectors: arrayOf(object),
            year: number.isRequired
        }),
        object
    ]),
    activeScenario: string.isRequired,
    selectedSectorList: string.isRequired,
    selectedSector: oneOfType([
        shape({
            country: string,
            iso3c: string,
            population: number,
            type: string.isRequired
        }),
        object
    ]),
    sortedRankings: arrayOf(object),
    subSectors: oneOfType([
        shape({
            categories: arrayOf(object).isRequired,
            country: string,
            emissions: number.isRequired,
            population: number.isRequired,
            sector: string.isRequired,
            year: number.isRequired
        }),
        object
    ])
};

EmissionClockAnimatedSectors.defaultProps = {
    handleSelectedSector: () => {},
    handleActiveSectorType: () => {},
    closeDetailsCard: () => {},
    totalEmissions: {},
    activeScenario: "base",
    selectedSectorList: "Subsector",
    selectedSector: {},
    sortedRankings: [],
    subSectors: {}
};

export default EmissionClockAnimatedSectors;
