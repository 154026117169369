import React, { forwardRef } from 'react';
import DynamicSvg from '../../DynamicSvg/DynamicSvg';
import { Button } from '../../Button/Button';
import { func } from 'prop-types';
/** @jsxImportSource @emotion/react */
import { theme } from 'twin.macro';

const EmissionClockSectorsCategories = forwardRef(
  (
    { onScrollCategories, handleSelectedSector, totalEmissions },
    scrollableCategories
  ) => {
    return (
      <div
        className="scrollableHorizontal scrollableSectors"
        tw="grid grid-cols-5 md:flex md:overflow-x-auto md:px-[1rem]"
        ref={scrollableCategories}
        onScroll={onScrollCategories}
      >
        {totalEmissions.sectors.map(({ name }) => (
          <div key={name} tw="flex justify-center md:flex-[0 0 30%]">
            <Button
              type="sectors"
              tw="flex flex-col items-center p-[1rem] mt-2"
              name={name}
              onClick={handleSelectedSector}
            >
              <DynamicSvg
                iconName={name}
                size={52}
                fill={`${theme`colors.orange.400`}`}
              />
              <p tw="font-semiBold text-orange-400 text-sm md:text-[0.6rem]">
                {name === 'Energy' ? 'Energy Systems' : name}{' '}
                <span tw="hidden md:block">{'>'}</span>
              </p>
              <span tw="text-teal-800 text-sm md:hidden underline">
                Learn more {'>'}
              </span>
            </Button>
          </div>
        ))}
      </div>
    );
  }
);

EmissionClockSectorsCategories.propTypes = {
  onScrollCategories: func.isRequired,
  handleSelectedSector: func.isRequired,
};

EmissionClockSectorsCategories.defaultProps = {
  onScrollCategories: () => {},
  handleSelectedSector: () => {},
};

export default EmissionClockSectorsCategories;
