import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Col } from '../../../assets/styles/grid';
import EmissionPersonCard from './EmissionsPersonCard/EmissionsPersonCard';
import { handleCountryEmissionsDetails } from '../../../utils/countries';
import {
  filterCountriesByYearAndEmissions,
  groupByCountryAndSumSectors,
} from '../../../utils/dataFormatting';
import InputSearch from '../../Search/InputSearch';
import { isNotEmptyObject } from '../../../utils/common';
import arrow from '../../../assets/images/arrow.svg';
import reset from '../../../assets/images/reset.svg';
import lowEmissions from '../../../assets/images/section4Compare/lowEmissions.gif';
import highEmissions from '../../../assets/images/section4Compare/highEmissions.gif';
import { string, shape, number } from 'prop-types';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockCompareResults = ({
  selectedYear,
  activeScenario,
  countries_flags,
  emissions_main,
  countries_stats,
}) => {
  const [firstCountry, setFirstCountry] = useState({});
  const [secondCountry, setSecondCountry] = useState({});
  const [searchTermFirstCountry, setSearchTermFirstCountry] = useState('');
  const [searchTermSecondCountry, setSearchTermSecondCountry] = useState('');
  const [activeIconFirstCountry, setActiveIconFirstCountry] = useState(arrow);
  const [activeIconSecondCountry, setActiveIconSecondCountry] = useState(arrow);
  const searchRefFirstCountry = useRef();
  const searchRefSecondCountry = useRef();

  const handleSearch = useCallback((name, value) => {
    if (name === 'firstCountry') {
      setSearchTermFirstCountry(value);
      searchRefFirstCountry.current(value);
    } else {
      setSearchTermSecondCountry(value);
      searchRefSecondCountry.current(value);
    }
  }, []);

  const resetSearch = useCallback(({ target }) => {
    if (target.getAttribute('name') === 'firstCountry') {
      setSearchTermFirstCountry('');
      searchRefFirstCountry.current('');
      setFirstCountry({});
      setActiveIconFirstCountry(arrow);
    } else {
      setSearchTermSecondCountry('');
      searchRefSecondCountry.current('');
      setSecondCountry({});
      setActiveIconSecondCountry(arrow);
    }
  }, []);

  const handleSearchFirstCountry = useCallback(
    ({ currentTarget }) => {
      const { value, name } = currentTarget;
      setActiveIconFirstCountry(value === '' ? arrow : reset);
      handleSearch(name, value);
    },
    [handleSearch]
  );

  const handleSearchSecondCountry = useCallback(
    ({ currentTarget }) => {
      const { value, name } = currentTarget;
      setActiveIconSecondCountry(value === '' ? arrow : reset);
      handleSearch(name, value);
    },
    [handleSearch]
  );

  const handleSelectedFirstCountry = useCallback(
    ({ currentTarget }) => {
      const selectedCountry = handleCountryEmissionsDetails(
        currentTarget,
        countries_flags,
        emissions_main,
        selectedYear,
        activeScenario.id,
        countries_stats
      );
      selectedCountry && setFirstCountry(selectedCountry);
    },
    [
      activeScenario,
      selectedYear,
      countries_flags,
      countries_stats,
      emissions_main,
    ]
  );

  const handleSelectedSecondCountry = useCallback(
    ({ currentTarget }) => {
      const selectedCountry = handleCountryEmissionsDetails(
        currentTarget,
        countries_flags,
        emissions_main,
        selectedYear,
        activeScenario.id,
        countries_stats
      );
      selectedCountry && setSecondCountry(selectedCountry);
    },
    [
      activeScenario,
      selectedYear,
      countries_flags,
      countries_stats,
      emissions_main,
    ]
  );

  const handleEmissionsIcon = (selectedCountryEmissions) =>
    selectedCountryEmissions < 8 ? lowEmissions : highEmissions;

  const sortCoutries = useCallback(() => {
    let sortedCountries = filterCountriesByYearAndEmissions(
      selectedYear,
      emissions_main
    );
    sortedCountries = groupByCountryAndSumSectors(
      sortedCountries,
      'iso3c',
      selectedYear,
      activeScenario.id
    );
    const globalEmissions = sortedCountries.find(({ id }) => id === 'WLD');
    const unitedStates = sortedCountries.find(({ id }) => id === 'USA');
    const selectedFirstCountry = handleCountryEmissionsDetails(
      isNotEmptyObject(firstCountry) ? firstCountry.iso3c : globalEmissions.id,
      countries_flags,
      emissions_main,
      selectedYear,
      activeScenario.id,
      countries_stats
    );
    const selectedSecondCountry = handleCountryEmissionsDetails(
      isNotEmptyObject(secondCountry) ? secondCountry.iso3c : unitedStates.id,
      countries_flags,
      emissions_main,
      selectedYear,
      activeScenario.id,
      countries_stats
    );
    setFirstCountry(selectedFirstCountry);
    setSecondCountry(selectedSecondCountry);
    setSearchTermFirstCountry(globalEmissions.id);
    setSearchTermSecondCountry(unitedStates.id);
    setActiveIconFirstCountry(reset);
    setActiveIconSecondCountry(reset);
  }, [
    activeScenario,
    selectedYear,
    countries_flags,
    countries_stats,
    emissions_main,
  ]);

  useEffect(() => sortCoutries(), [sortCoutries]);

  return (
    <>
      <Col as="article" col={12} md={4} tw="mt-[2.375rem]">
        <div tw="flex justify-between max-w-[43.75rem] items-center m-[0 auto] sm:flex-wrap md:gap-[1.25rem] md:max-w-full">
          <InputSearch
            countries_flags={countries_flags}
            handleSearch={handleSearchFirstCountry}
            searchTerm={searchTermFirstCountry}
            selectedCountry={firstCountry}
            name="firstCountry"
            handleCountryEmissions={handleSelectedFirstCountry}
            activeIcon={activeIconFirstCountry}
            isNotFromMap={true}
            ref={searchRefFirstCountry}
            resetSearch={resetSearch}
          />
          <span tw="border border-teal-200 text-teal-600 p-[1.25rem] font-semiBold rounded-[50%] text-regular m-auto">
            VS
          </span>
          <InputSearch
            countries_flags={countries_flags}
            handleSearch={handleSearchSecondCountry}
            searchTerm={searchTermSecondCountry}
            selectedCountry={secondCountry}
            name="secondCountry"
            handleCountryEmissions={handleSelectedSecondCountry}
            activeIcon={activeIconSecondCountry}
            isNotFromMap={true}
            ref={searchRefSecondCountry}
            resetSearch={resetSearch}
          />
        </div>
      </Col>
      <>
        <Col as="article" col={12} md={4}>
          <div tw="flex justify-between max-w-[43.75rem] items-center m-[0 auto] md:gap-[1.25rem] md:max-w-full">
            <div tw="w-[13.375rem] flex flex-col items-center h-[12.5rem]">
              {firstCountry?.emissions && (
                <>
                  <img
                    tw="m-auto"
                    src={handleEmissionsIcon(firstCountry.emissions)}
                    alt="First country missions per person"
                  />
                  <h3 tw="hidden md:flex text-orange-400">
                    {firstCountry.name}
                  </h3>
                </>
              )}
            </div>
            <div tw="w-[13.375rem] flex flex-col items-center h-[12.5rem]">
              {secondCountry?.emissions && (
                <>
                  <img
                    tw="m-auto"
                    src={handleEmissionsIcon(secondCountry.emissions)}
                    alt="Second country emissions per person"
                  />
                  <h3 tw="hidden md:flex text-orange-400">
                    {secondCountry.name}
                  </h3>
                </>
              )}
            </div>
          </div>
        </Col>
        {isNotEmptyObject(firstCountry) && isNotEmptyObject(secondCountry) && (
          <EmissionPersonCard
            firstCountry={firstCountry}
            secondCountry={secondCountry}
            activeScenario={activeScenario}
            emissions_main={emissions_main}
          />
        )}
      </>
    </>
  );
};

EmissionClockCompareResults.propTypes = {
  selectedYear: number.isRequired,
  activeScenario: shape({
    id: string.isRequired,
    text: string.isRequired,
    title: string.isRequired,
  }).isRequired,
};

EmissionClockCompareResults.defaultProps = {
  selectedYear: new Date().getFullYear(),
  activeScenario: {
    id: 'base',
    text: 'In this scenario, states continue with their current trajectory',
    title: 'Business as usual',
  },
};

export default EmissionClockCompareResults;
