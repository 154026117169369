import { bool } from 'prop-types';
import { ButtonType } from '../Button/ButtonStyle';
/** @jsxImportSource @emotion/react */
import tw, { styled, css } from 'twin.macro';

export const Drop = styled.div(({ isScenarioDropOpen, isFutureBarOpen }) => [
  tw`hidden text-black text-center border border-teal-800 rounded-[0.5rem] overflow-hidden absolute top[-5.25rem] w-[13.56rem] bg-white`,
  css`
    ${isScenarioDropOpen && tw`block md:block md:top-[4rem] md:left-[1rem]`}
    ${isFutureBarOpen && tw`top-[-6rem] left-[-70%]`}
    & button:hover {
      ${ButtonType['hover']}
    }

    & button:focus {
      ${ButtonType['focus']}
    }
  `,
]);

Drop.propTypes = {
  isScenarioDropOpen: bool.isRequired,
  isFutureBarOpen: bool.isRequired,
};

Drop.defaultProps = {
  isScenarioDropOpen: false,
  isFutureBarOpen: true,
};
