import React, { useState, useEffect } from 'react';
import pointer from '../../../../assets/images/section1Globe/pointer.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockGlobeSpinningWords = ({
  spinningSpeed,
  countrySectorPairs,
  fullDegrees,
  oneSecond,
}) => {
  const [activeElement, setActiveElement] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      const activeElement = document.querySelector('[data-active="true"]');
      activeElement &&
        setActiveElement({
          name: activeElement.getAttribute('data-name'),
          sector: activeElement.getAttribute('data-sector'),
        });
    }, (spinningSpeed / fullDegrees) * oneSecond);
    return () => clearInterval(interval);
  }, [spinningSpeed, fullDegrees, oneSecond]);
  return (
    <div tw="absolute right-0 top-[-5%] flex md:text-xs md:right-[1rem] md:justify-end w-[50%] sm:top-[-10%]">
      <img tw="md:hidden" alt="Pointer" src={pointer} />
      <div tw="overflow-hidden relative top-[-0.8rem] left-[0.625rem] md:left-0 md:top-0">
        <p
          tw="flex text-black"
          style={{
            animationDuration: `${spinningSpeed / countrySectorPairs}s`,
            animationDelay: `1ms`,
          }}
        >
          {activeElement.sector}{' '}
          <span tw="text-orange-500 ml-[0.625rem]">{activeElement.name}</span>
        </p>
      </div>
    </div>
  );
};

export default EmissionClockGlobeSpinningWords;
