import { comparedCountriesEmissionsPercentage } from '../../../../utils/sectorsEmissions';
import { ProgressBar } from '../../../ProgressBar/ProgressBar';
// import plant from '../../../../assets/images/section3Sectors/plant.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const EmissionPersonDetails = ({
  firstCountry,
  secondCountry,
  activeScenario,
}) => {
  const divideSectorsEmissionsByPopulation = (sector, country) =>
    (sector[activeScenario.id] / country.population).toFixed(1);
  const isGoodEmission = (sector, country) =>
    Math.sign(divideSectorsEmissionsByPopulation(sector, country));

  return (
    <>
      <div tw="flex justify-around items-center m-[2rem 0 1.75rem]">
        <div tw="flex gap-[0 1rem]">
          <img width={28} src={firstCountry.file_url} alt="First country" />
          <p>{firstCountry.name}</p>
        </div>
        <h4 tw="text-gray-500 font-semiBold">Sector</h4>
        <div tw="flex gap-[0 1rem]">
          <img width={28} src={secondCountry.file_url} alt="First country" />
          <p>{secondCountry.name}</p>
        </div>
      </div>
      <div tw="w-full">
        {firstCountry?.sectors?.map((sector, i) => (
          <div
            key={firstCountry.iso3c + i}
            css={[
              tw`w-full mx-auto my-[1rem] sm:justify-center text-orange-400 flex justify-between sm:flex sm:grid-cols-none rounded-[0.5rem] border border-gray-100 xxs:border-0 hover:bg-[#FFCDB4] hover:border-orange-500 hover:text-gray-800`,
              sector.sector === 'Energy' && tw`xs:my-[1.5rem]`,
            ]}
          >
            <div tw="flex items-center justify-between text-left xs:text-center pl-[0.875rem] flex-1">
              <p
                css={[
                  tw`leading-[1.125rem] sm:text-sm w-[10rem]`,
                  isGoodEmission(sector, firstCountry) === -1 &&
                    tw`text-teal-500`,
                ]}
              >
                {divideSectorsEmissionsByPopulation(sector, firstCountry)} tons
              </p>
              <ProgressBar
                tw="h-[0.625rem] ml-[0.625rem] rounded-l-[0.5rem] sm:hidden"
                percentage={
                  (divideSectorsEmissionsByPopulation(sector, firstCountry) /
                    comparedCountriesEmissionsPercentage(
                      [firstCountry, secondCountry],
                      activeScenario
                    )) *
                  100
                }
                isFromCompare={true}
              />
            </div>
            <div tw="flex justify-center capitalize text-gray-500 text-sm w-[24%] sm:text-sm">
              <p tw="my-[1rem]">
                {sector.sector === 'Energy' ? 'Energy Systems' : sector.sector}
              </p>
            </div>
            <div
              key={`${secondCountry?.sectors[i]?.sector}  ${secondCountry.iso3c}`}
              css={[
                tw`flex items-center justify-between my-[1rem] sm:justify-center pr-[0.875rem] flex-1 text-right xs:text-center`,
                secondCountry?.sectors[i]?.sector === 'Energy' &&
                  tw`xs:my-[1.5rem]`,
              ]}
            >
              <ProgressBar
                tw="h-[0.625rem] mr-[0.625rem] rounded-r-[0.5rem] sm:hidden"
                percentage={
                  (divideSectorsEmissionsByPopulation(
                    secondCountry?.sectors[i],
                    secondCountry
                  ) /
                    comparedCountriesEmissionsPercentage(
                      [firstCountry, secondCountry],
                      activeScenario
                    )) *
                  100
                }
                isFromCompare={true}
              />
              <p
                css={[
                  tw`leading-[1.125rem] sm:text-sm w-[10rem]`,
                  isGoodEmission(secondCountry?.sectors[i], secondCountry) ===
                    -1 && tw`text-teal-500`,
                ]}
              >
                {divideSectorsEmissionsByPopulation(
                  secondCountry?.sectors[i],
                  secondCountry
                )}{' '}
                tons
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EmissionPersonDetails;
