import React, {
    useState,
    useMemo,
    useCallback,
    useRef,
    useEffect
} from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, ZoomControl, GeoJSON } from "react-leaflet";
import { Grid, Col } from "../../../assets/styles/grid";
import EmissionClockMapDetailsHeader from "./Card/EmissionClockMapDetailsHeader";
import EmissionClockMapDetailsSearch from "./Card/EmissionClockMapDetailsSearch";
import GlobalCarbonBudget from "../../GlobalCarbonBudget/GlobalCarbonBudget";
import TooltipContainer from "./Map/TooltipContainer";
import useSubSectors from "../../../custom-hooks/useSubSectors";
import useSelectedRegion from "../../../custom-hooks/useSelectedRegion";
import {
    avoidBodyScrollOnMobile,
    formatDigits,
    formatEmissions,
    isNotEmptyObject
} from "../../../utils/common";
import {
    centerMap,
    southAfricaCenterMap,
    countryStyle,
    setOpacityMap,
    countryColorsEmissions,
    getCountryInfo,
    getSubregionsInfo,
    matrixClassificationsIncomeBoxes,
    matrixClassificationsIncomeText,
    matrixClassificationsText,
    findMatchingIds
} from "../../../utils/leafletMap";
import useWindowSize from "../../../custom-hooks/useWindowsSize";
import search from "../../../assets/images/search.svg";
import reset from "../../../assets/images/reset.svg";
import arrow from "../../../assets/images/arrow.svg";
import emissions from "../../../assets/images/emissions.svg";
import income from "../../../assets/images/income.svg";
import {
    oneOfType,
    func,
    string,
    shape,
    instanceOf,
    number,
    arrayOf,
    object
} from "prop-types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const EmissionClockMap = ({
    southAfricaSubregionsCoordinatesAndEmissions,
    globalCarbonEmissions,
    toggleModal,
    selectedYear,
    activeScenario,
    countries_flags,
    countries_stats,
    emissions_main,
    subsectors_all,
    sectors_compositions,
    typeOfMapEmissions,
    handleTypeOfMapEmissions,
    countryCoordinatesAndEmissions,
    prevCountryCoordinatesAndEmissions,
    isLoading,
    emissions_cumulative,
    temperature,
    areSubRegionsLoading,
    prevSouthAfricaSubregionsCoordinatesAndEmissions,
    classifications_income
}) => {
    const [mapRef, setMapRef] = useState(null);
    const { width } = useWindowSize();
    const [tooltipInfo, setTooltipInfo] = useState(null);
    const [targetMap, setTargetMap] = useState(null);
    const [cardInfo, setCardInfo] = useState({});
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedSector, setSelectedSector] = useState({});
    const { subSectors } = useSubSectors(
        selectedYear,
        activeScenario.id,
        selectedSector,
        selectedCountry,
        countryCoordinatesAndEmissions,
        countries_stats,
        subsectors_all,
        sectors_compositions
    );
    const {
        selectedRegion,
        subRegionCardInfo,
        zoomToSubRegions,
        handleToggleSwitch,
        handleResetRegion,
        handleSubregionsEmissions,
        areSouthAfricaSubnationalsActive,
        setAreSouthAfricaSubnationalsActive,
        handleResetRegionFromParent
    } = useSelectedRegion(
        selectedYear,
        activeScenario,
        southAfricaSubregionsCoordinatesAndEmissions.features,
        mapRef
    );
    const [searchTerm, setSearchTerm] = useState("");
    const [isMatrixTableOpen, setIsMatrixTableOpen] = useState(false);

    const [activeIcon, setActiveIcon] = useState(search);
    const searchRef = useRef();
    const geoJsonRef = useRef();
    const geoJsonSouthAfricaRef = useRef();

    const highlightFeature = ({ target }) => {
        let selectedCountryInfo = getCountryInfo(
            target.feature.id,
            selectedYear,
            activeScenario.id,
            emissions_main,
            countries_flags,
            countries_stats,
            classifications_income
        );

        if (selectedCountryInfo) {
            selectedCountryInfo = {
                ...selectedCountryInfo,
                income: target.feature.income,
                classifications: target.feature.classifications
            };

            setTooltipInfo(selectedCountryInfo);
        }
    };

    const highlightFeatureSubRegions = ({ target }) => {
        const selectedSubRegionInfo = getSubregionsInfo(
            target.feature,
            selectedYear,
            activeScenario.id
        );
        selectedSubRegionInfo && setTooltipInfo(selectedSubRegionInfo);
    };

    const resetHighlight = () => setTooltipInfo(null);

    useEffect(
        () =>
            !areSouthAfricaSubnationalsActive &&
            targetMap &&
            zoomToCountry({ target: targetMap }),
        [selectedYear, activeScenario.id]
    );

    const zoomToCountry = ({ target }) => {
        setTargetMap(target);
        mapRef.target.fitBounds(target.getBounds());
        const selectedCountry = getCountryInfo(
            target.feature.id,
            selectedYear,
            activeScenario.id,
            emissions_main,
            countries_flags,
            countries_stats,
            classifications_income
        );
        selectedCountry && setSearchTerm(selectedCountry.name);
        selectedCountry && setActiveIcon(reset);
        selectedCountry && setCardInfo(selectedCountry);
        selectedCountry && setSelectedCountry(selectedCountry);
    };

    const mapInteractions = (_, layer) => {
        layer.on({
            mouseover: highlightFeature,
            mouseout: resetHighlight,
            click: zoomToCountry
        });
    };

    const groupedIncome = useMemo(() => {
        const groupedIncomeMap = new Map();

        for (const e of countryCoordinatesAndEmissions.features) {
            let thisList = groupedIncomeMap.get(e.income);
            if (thisList === undefined) {
                thisList = [];
                groupedIncomeMap.set(e.income, thisList);
            }
            thisList.push(e);
        }

        return groupedIncomeMap;
    }, [countryCoordinatesAndEmissions.features]);

    const groupedClassifications = useMemo(() => {
        const groupedClassificationsMap = new Map();

        for (const e of countryCoordinatesAndEmissions.features) {
            let thisList = groupedClassificationsMap.get(e.classifications);
            if (thisList === undefined) {
                thisList = [];
                groupedClassificationsMap.set(e.classifications, thisList);
            }
            thisList.push(e);
        }

        return groupedClassificationsMap;
    }, [countryCoordinatesAndEmissions.features]);

    const mapInteractionsSouthAfrica = (_, layer) => {
        layer.on({
            mouseover: highlightFeatureSubRegions,
            mouseout: resetHighlight,
            click: zoomToSubRegions
        });
    };

    const handleReset = useCallback(() => {
        setSelectedCountry({});
        setTargetMap(null);
        mapRef.target.panTo(centerMap);
        setTimeout(() => mapRef.target.setZoom(1), 200);
    }, [mapRef]);

    const handleSearch = useCallback(({ target }) => {
        const { value } = target;
        value === "" && setTargetMap(null);
        setActiveIcon(value === "" ? search : reset);
        setSearchTerm(value);
        searchRef.current(value);
    }, []);

    const resetSearch = useCallback(() => {
        setActiveIcon(search);
        setSearchTerm("");
        setAreSouthAfricaSubnationalsActive(false);
        searchRef.current("");
        handleReset();
        handleResetRegionFromParent();
    }, [handleReset]);

    const handleMouseEnterLegend = ({ currentTarget }) => {
        const targetsMap = Object.values(mapRef.target._targets).filter(
            ({ feature }) =>
                feature?.fillColor === currentTarget.getAttribute("name")
        );
        setOpacityMap(geoJsonRef.current || geoJsonSouthAfricaRef.current);
        return targetsMap.forEach((target) =>
            target.setStyle(countryStyle(target.feature))
        );
    };

    const handleMouseLeaveLegend = () =>
        setOpacityMap(
            geoJsonRef.current || geoJsonSouthAfricaRef.current,
            "reset"
        );

    const legendListEmissionsColors = (colorsEmissions) =>
        colorsEmissions.map(({ color }) => (
            <span
                key={color}
                name={color}
                onMouseEnter={handleMouseEnterLegend}
                onMouseLeave={handleMouseLeaveLegend}
                tw="first-of-type:rounded-l-[0.5rem] last-of-type:rounded-r-[0.5rem] w-[14.28%] block h-[0.56rem] cursor-pointer"
                style={{ background: color }}
            ></span>
        ));

    const legendListEmissionsValues = (colorsEmissions) =>
        colorsEmissions.map(({ name, text, color }) => (
            <li
                key={name}
                name={color}
                onMouseEnter={handleMouseEnterLegend}
                onMouseLeave={handleMouseLeaveLegend}
                tw="text-center text-black text-[0.60rem] w-[14.28%] sm:text-[0.4rem] cursor-pointer"
            >
                {text === "NaN - NaN" ? "" : text}
            </li>
        ));

    const handleCountryEmissions = useCallback(
        (currentTarget) => {
            const countryID =
                typeof currentTarget === "string"
                    ? currentTarget
                    : currentTarget.currentTarget.getAttribute("name");
            const selectedCountryInfo = getCountryInfo(
                countryID,
                selectedYear,
                activeScenario.id,
                emissions_main,
                countries_flags,
                countries_stats,
                classifications_income
            );
            if (selectedCountry.iso3c !== "WLD") {
                const targetMap = Object.values(mapRef.target._targets).find(
                    ({ feature }) => feature?.id === selectedCountryInfo.iso3c
                );
                targetMap && mapRef.target.fitBounds(targetMap.getBounds());
            }
            selectedCountryInfo && setCardInfo(selectedCountryInfo);
            selectedCountryInfo && setSelectedCountry(selectedCountryInfo);
            avoidBodyScrollOnMobile(width, "hidden");
        },
        [
            mapRef,
            selectedYear,
            activeScenario,
            emissions_main,
            width,
            countries_flags,
            countries_stats,
            selectedCountry.iso3c
        ]
    );

    const closeDetailsCard = useCallback(() => {
        setSearchTerm("");
        searchRef.current("");
        setActiveIcon(search);
        handleReset();
        avoidBodyScrollOnMobile(width, "unset");
    }, [width, handleReset]);

    const handleSelectedSector = useCallback(
        ({ currentTarget }) => {
            const selectedSector = {
                type: currentTarget.name,
                iso3c: selectedCountry.iso3c,
                country: selectedCountry.name,
                population: selectedCountry.population
            };
            setSelectedSector(selectedSector);
        },
        [selectedCountry]
    );

    useEffect(() => {
        if (!areSouthAfricaSubnationalsActive) {
            handleResetRegionFromParent();
        }
    }, [areSouthAfricaSubnationalsActive]);

    useEffect(() => {
        if (areSouthAfricaSubnationalsActive && selectedRegion.iso3c) {
            handleSubregionsEmissions(selectedRegion.iso3c);
        } else if (selectedCountry.iso3c)
            handleCountryEmissions(selectedCountry.iso3c);
    }, [
        areSouthAfricaSubnationalsActive,
        handleSubregionsEmissions,
        selectedYear,
        activeScenario,
        handleCountryEmissions,
        selectedCountry.iso3c,
        selectedRegion.iso3c
    ]);

    useEffect(() => {
        if (width <= 768) {
            mapRef?.target._handlers.forEach((handler) => handler.disable());
            setSelectedCountry({});
            setCardInfo({});
            avoidBodyScrollOnMobile(width, "unset");
        } else {
            setSelectedCountry(
                getCountryInfo(
                    "WLD",
                    selectedYear,
                    activeScenario.id,
                    emissions_main,
                    countries_flags,
                    countries_stats,
                    classifications_income
                )
            );
            setCardInfo(
                getCountryInfo(
                    "WLD",
                    selectedYear,
                    activeScenario.id,
                    emissions_main,
                    countries_flags,
                    countries_stats,
                    classifications_income
                )
            );
        }
    }, [mapRef, width]);

    return (
        <Grid
            as="section"
            cols={12}
            md={4}
            css={[
                tw`relative bg-white !pt-[7rem] p-[1rem 11.3125rem] md:!pt-[7rem] auto-rows-fr sm:p-0 md:auto-rows-auto sm:mt-[-4rem] overflow-hidden`,
                isNotEmptyObject(selectedCountry) && tw`md:p-0`
            ]}
            id="map"
        >
            <Col
                as="article"
                col={4}
                md={4}
                css={[
                    tw`flex flex-col max-w-[20rem] bg-white shadow rounded-[0.5rem] relative overflow-hidden md:shadow-none md:rounded-none md:max-w-full md:p-0`,
                    isNotEmptyObject(selectedCountry) &&
                        tw`md:fixed md:top-[2.1rem] md:bottom-0 md:w-full md:z-[4500]`
                ]}
            >
                <EmissionClockMapDetailsSearch
                    handleSearch={handleSearch}
                    searchTerm={searchTerm}
                    selectedCountry={selectedCountry}
                    handleCountryEmissions={handleCountryEmissions}
                    handleSubregionsEmissions={handleSubregionsEmissions}
                    activeIcon={activeIcon}
                    ref={searchRef}
                    handleResetRegion={handleResetRegion}
                    activeScenario={activeScenario}
                    handleSelectedSector={handleSelectedSector}
                    subSectors={subSectors}
                    closeDetailsCard={closeDetailsCard}
                    resetSearch={resetSearch}
                    countries_flags={countries_flags}
                    cardInfo={cardInfo}
                    typeOfMapEmissions={typeOfMapEmissions}
                    emissions_cumulative={emissions_cumulative}
                    southAfricaSubregionsCoordinatesAndEmissions={
                        southAfricaSubregionsCoordinatesAndEmissions
                    }
                    areSouthAfricaSubnationalsActive={
                        areSouthAfricaSubnationalsActive
                    }
                    handleToggleSwitch={handleToggleSwitch}
                    setAreSouthAfricaSubnationalsActive={
                        setAreSouthAfricaSubnationalsActive
                    }
                    selectedRegion={selectedRegion}
                    subRegionCardInfo={subRegionCardInfo}
                />
            </Col>
            <Col as="article" col={8} md={4} tw="flex flex-col">
                {isNotEmptyObject(globalCarbonEmissions) && (
                    <GlobalCarbonBudget
                        globalCarbonEmissions={globalCarbonEmissions}
                        toggleModal={toggleModal}
                        width={width}
                        selectedCountry={selectedCountry}
                        temperature={temperature}
                    />
                )}
                <EmissionClockMapDetailsHeader
                    handleTypeOfMapEmissions={handleTypeOfMapEmissions}
                    typeOfMapEmissions={typeOfMapEmissions}
                    areSouthAfricaSubnationalsActive={
                        areSouthAfricaSubnationalsActive
                    }
                />
                <div
                    className="scrollable scrollableHorizontal"
                    css={[
                        tw`h-[20.75rem] relative flex items-center justify-center m-0 md:m-[1.25rem 0] md:px-[1rem] md:flex-col`,
                        typeOfMapEmissions === "income" && tw`h-[27.75rem]`,
                        isMatrixTableOpen &&
                            typeOfMapEmissions === "income" &&
                            tw`overflow-auto`
                    ]}
                >
                    <MapContainer
                        center={
                            areSouthAfricaSubnationalsActive
                                ? southAfricaCenterMap
                                : centerMap
                        }
                        zoom={1}
                        minZoom={1}
                        maxZoom={6}
                        tw="bg-white w-full h-full overflow-hidden"
                        whenReady={setMapRef}
                        zoomControl={false}
                    >
                        {areSouthAfricaSubnationalsActive ? (
                            areSubRegionsLoading ? (
                                <>
                                    <GeoJSON
                                        data={
                                            prevSouthAfricaSubregionsCoordinatesAndEmissions.features
                                                ? prevSouthAfricaSubregionsCoordinatesAndEmissions.features
                                                : southAfricaSubregionsCoordinatesAndEmissions.features
                                        }
                                        style={(country) =>
                                            countryStyle(country)
                                        }
                                        onEachFeature={mapInteractions}
                                        ref={geoJsonRef}
                                    />
                                </>
                            ) : (
                                <GeoJSON
                                    data={
                                        southAfricaSubregionsCoordinatesAndEmissions.features
                                    }
                                    style={(country) => countryStyle(country)}
                                    onEachFeature={mapInteractionsSouthAfrica}
                                    ref={geoJsonSouthAfricaRef}
                                />
                            )
                        ) : null}
                        {!areSouthAfricaSubnationalsActive ? (
                            isLoading ? (
                                <>
                                    <GeoJSON
                                        data={
                                            prevCountryCoordinatesAndEmissions.features
                                                ? prevCountryCoordinatesAndEmissions.features
                                                : countryCoordinatesAndEmissions.features
                                        }
                                        style={(country) =>
                                            countryStyle(country)
                                        }
                                        onEachFeature={mapInteractions}
                                        ref={geoJsonRef}
                                    />
                                </>
                            ) : (
                                <GeoJSON
                                    data={
                                        countryCoordinatesAndEmissions.features
                                    }
                                    style={(country) => countryStyle(country)}
                                    onEachFeature={mapInteractions}
                                    ref={geoJsonRef}
                                />
                            )
                        ) : null}
                        {width > 768 && <ZoomControl position="bottomright" />}
                        {width > 768 && (
                            <TooltipContainer
                                tooltipInfo={tooltipInfo}
                                activeScenario={activeScenario}
                                typeOfMapEmissions={typeOfMapEmissions}
                                areSouthAfricaSubnationalsActive={
                                    areSouthAfricaSubnationalsActive
                                }
                            />
                        )}
                    </MapContainer>
                    {typeOfMapEmissions === "income" && (
                        <div
                            css={[
                                tw`shadow flex absolute bottom-[0.5rem] z-[1004] left-[0.5rem] md:m-auto bg-white flex-col p-[0.5rem] rounded-[0.5rem] max-w-[max-content] md:relative md:bottom-2 md:left-0`
                            ]}
                        >
                            <div tw="flex justify-between text-[#252525] text-xs gap-[0.5rem]">
                                Classifying countries by{" "}
                                <br css={[isMatrixTableOpen && tw`hidden`]} />{" "}
                                income and emissions
                                <button
                                    tw="border border-teal-500 rounded-[0.25rem] p-[0.75rem 0.25rem]"
                                    onClick={() =>
                                        setIsMatrixTableOpen(
                                            (prevState) => !prevState
                                        )
                                    }
                                >
                                    <img
                                        css={[
                                            tw`duration-300 transition -rotate-90`,
                                            isMatrixTableOpen && tw`rotate-90`
                                        ]}
                                        alt="Arrow"
                                        src={arrow}
                                    />
                                </button>
                            </div>
                            <div>
                                {!isMatrixTableOpen ? (
                                    <img
                                        tw="m-[0.5rem 0 0.5rem 2rem]"
                                        alt="Income"
                                        src={income}
                                    />
                                ) : (
                                    <div tw="font-semiBold mt-[0.5rem] text-gray-800 text-xs grid grid-cols-[repeat(3, 1fr)] ml-[10.9rem]">
                                        {matrixClassificationsIncomeText.map(
                                            ({ text, id }) => (
                                                <div
                                                    key={text}
                                                    tw="w-[10.9rem] flex flex-col pl-[0.25rem] border-r border-r-gray-100 last-of-type:border-0"
                                                >
                                                    <span>{text}</span>
                                                    <p tw="font-light">
                                                        {formatDigits(
                                                            groupedIncome
                                                                .get(id)
                                                                .reduce(
                                                                    (
                                                                        acc,
                                                                        curr
                                                                    ) =>
                                                                        acc +
                                                                        curr.population,
                                                                    0
                                                                ),
                                                            true
                                                        )}{" "}
                                                        people in{" "}
                                                        {
                                                            groupedIncome.get(
                                                                id
                                                            ).length
                                                        }{" "}
                                                        countries
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                                <div
                                    css={[
                                        tw`flex gap-[0.5rem]`,
                                        isMatrixTableOpen && tw`gap-0`
                                    ]}
                                >
                                    {!isMatrixTableOpen ? (
                                        <img alt="Emissions" src={emissions} />
                                    ) : (
                                        <div tw="w-[10.9rem] font-semiBold text-gray-800 text-xs grid grid-rows-[repeat(3, 1fr)]">
                                            {matrixClassificationsText.map(
                                                ({ text, id }) => (
                                                    <div
                                                        key={text}
                                                        tw="flex flex-col h-full border-b border-b-gray-100 last-of-type:border-0"
                                                    >
                                                        <span>{text}</span>
                                                        <p tw="font-light">
                                                            {formatDigits(
                                                                groupedClassifications
                                                                    .get(id)
                                                                    .reduce(
                                                                        (
                                                                            acc,
                                                                            curr
                                                                        ) =>
                                                                            acc +
                                                                            curr.population,
                                                                        0
                                                                    ),
                                                                true
                                                            )}{" "}
                                                            people in{" "}
                                                            {
                                                                groupedClassifications.get(
                                                                    id
                                                                ).length
                                                            }{" "}
                                                            countries
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                    <ul
                                        css={[
                                            tw`grid grid-cols-[repeat(3, 1fr)] rounded-[0.5rem] overflow-hidden`,
                                            isMatrixTableOpen &&
                                                tw`overflow-auto rounded-none pointer-events-none`
                                        ]}
                                    >
                                        {matrixClassificationsIncomeBoxes.map(
                                            ({ color, id, text }) => (
                                                <li
                                                    style={{
                                                        backgroundColor: color
                                                    }}
                                                    onMouseEnter={
                                                        handleMouseEnterLegend
                                                    }
                                                    onMouseLeave={
                                                        handleMouseLeaveLegend
                                                    }
                                                    name={color}
                                                    css={[
                                                        tw`font-semiBold pl-[0.25rem] text-gray-800 text-xs transition duration-300 w-[2rem] h-[2rem] cursor-pointer`,
                                                        isMatrixTableOpen &&
                                                            tw`w-[10.9rem]`,
                                                        (id === "LE_HI" ||
                                                            id === "HE_MI" ||
                                                            id === "HE_HI") &&
                                                            tw`text-white`
                                                    ]}
                                                    key={id}
                                                >
                                                    {isMatrixTableOpen && (
                                                        <>
                                                            <span>
                                                                {formatEmissions(
                                                                    findMatchingIds(
                                                                        groupedClassifications.get(
                                                                            text.split(
                                                                                "/"
                                                                            )[0]
                                                                        ),
                                                                        groupedIncome.get(
                                                                            text.split(
                                                                                "/"
                                                                            )[1]
                                                                        )
                                                                    ).reduce(
                                                                        (
                                                                            acc,
                                                                            curr
                                                                        ) =>
                                                                            acc +
                                                                            curr.emissionsNotSquared,
                                                                        0
                                                                    ),
                                                                    true
                                                                )}
                                                            </span>
                                                            <p tw="font-light">
                                                                {formatDigits(
                                                                    findMatchingIds(
                                                                        groupedClassifications.get(
                                                                            text.split(
                                                                                "/"
                                                                            )[0]
                                                                        ),
                                                                        groupedIncome.get(
                                                                            text.split(
                                                                                "/"
                                                                            )[1]
                                                                        )
                                                                    ).reduce(
                                                                        (
                                                                            acc,
                                                                            curr
                                                                        ) =>
                                                                            acc +
                                                                            curr.population,
                                                                        0
                                                                    ),
                                                                    true
                                                                )}{" "}
                                                                people in{" "}
                                                                {
                                                                    findMatchingIds(
                                                                        groupedClassifications.get(
                                                                            text.split(
                                                                                "/"
                                                                            )[0]
                                                                        ),
                                                                        groupedIncome.get(
                                                                            text.split(
                                                                                "/"
                                                                            )[1]
                                                                        )
                                                                    ).length
                                                                }{" "}
                                                                countries
                                                            </p>
                                                        </>
                                                    )}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                                {isMatrixTableOpen && (
                                    <p tw="text-xs mt-[0.5rem]">
                                        <strong>Source:</strong> World
                                        Development Indicators, World Bank,
                                        World Emissions Clock, World Data Lab
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {typeOfMapEmissions !== "income" && (
                    <>
                        <div tw="m-[0 auto 1rem] flex w-full max-w-[30.625rem] md:p-[0 1rem]">
                            {legendListEmissionsColors(
                                areSouthAfricaSubnationalsActive
                                    ? southAfricaSubregionsCoordinatesAndEmissions.updatedRegionColorsEmissions
                                    : countryColorsEmissions
                            )}
                        </div>
                        <ul tw="m-[0 auto] flex w-full max-w-[30.625rem] md:p-[0 1rem]">
                            {legendListEmissionsValues(
                                areSouthAfricaSubnationalsActive
                                    ? southAfricaSubregionsCoordinatesAndEmissions.updatedRegionColorsEmissions
                                    : countryColorsEmissions
                            )}
                        </ul>
                    </>
                )}
                <EmissionClockMapDetailsHeader
                    handleTypeOfMapEmissions={handleTypeOfMapEmissions}
                    typeOfMapEmissions={typeOfMapEmissions}
                    mobile={true}
                    areSouthAfricaSubnationalsActive={
                        areSouthAfricaSubnationalsActive
                    }
                />
            </Col>
        </Grid>
    );
};

EmissionClockMap.propTypes = {
    mapSectionRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
    toggleModal: func,
    selectedYear: number.isRequired,
    activeScenario: shape({
        id: string.isRequired,
        text: string.isRequired,
        title: string.isRequired
    }).isRequired,
    typeOfMapEmissions: string.isRequired,
    handleTypeOfMapEmissions: func.isRequired,
    regionColorsEmissions: shape({
        features: arrayOf(object).isRequired,
        minimumEmissions: number.isRequired,
        range: number.isRequired
    }),
    countryColorsEmissions: shape({
        features: arrayOf(object).isRequired,
        minimumEmissions: number.isRequired,
        range: number.isRequired
    }),
    prevCountryCoordinatesAndEmissions: shape({
        features: arrayOf(object),
        minimumEmissions: number,
        range: number
    })
};

EmissionClockMap.defaultProps = {
    mapSectionRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
    toggleModal: () => {},
    selectedYear: new Date().getFullYear(),
    activeScenario: {
        id: "base",
        text: "In this scenario, states continue with their current trajectory",
        title: "Business as usual"
    },
    typeOfMapEmissions: "total",
    handleTypeOfMapEmissions: () => {},
    regionColorsEmissions: {
        features: [],
        minimumEmissions: 0,
        range: 0
    },
    countryColorsEmissions: {
        features: [],
        minimumEmissions: 0,
        range: 0
    },
    prevCountryCoordinatesAndEmissions: {
        features: undefined,
        minimumEmissions: undefined,
        range: undefined
    }
};

export default EmissionClockMap;
