import { useState, useRef } from "react";

const useOutsideClickSubregions = () => {
    const [isVisibleSubRegions, setIsVisibleSubRegions] = useState(false);
    const resultsRefSubRegions = useRef();

    return [resultsRefSubRegions, isVisibleSubRegions, setIsVisibleSubRegions];
};

export default useOutsideClickSubregions;
