import React from "react";
import reset from "../../assets/images/reset.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Selected = ({ selectedCountry, resetSearch, name, isNotFromMap }) => (
    <div
        className="selected"
        css={[
            tw`flex absolute left-0 top-0 bottom-0 bg-white items-center right-0 justify-between border-b border-gray-700 p-[0.8rem]`,
            !isNotFromMap &&
                tw`left-[1rem] top-[1.81rem] right-[1rem] bottom-[1.18rem] md:left-0 md:right-0 md:top-0 md:bottom-0`
        ]}
    >
        <div tw="flex">
            {selectedCountry?.file_url && (
                <img
                    tw="mr-[1.125rem]"
                    width={28}
                    alt="Country flag"
                    src={selectedCountry.file_url}
                />
            )}
            <p
                style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                css={[
                    tw`overflow-hidden`,
                    !isNotFromMap && tw`w-[14rem] md:w-full`
                ]}
            >
                {selectedCountry?.name}
            </p>
        </div>
        <img
            css={[tw`cursor-pointer`, !isNotFromMap && tw`md:hidden`]}
            tw="cursor-pointer"
            name={name}
            onClick={resetSearch}
            src={reset}
            alt="Reset search"
        />
    </div>
);

export default Selected;
