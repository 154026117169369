import { formatEmissions, formatDigits } from './common';

export const handleGlobalCarbonEmissions = (
  globalCarbonEmissions,
  emissionsLimit
) => {
  const isOverBudget = globalCarbonEmissions > emissionsLimit ? true : false;
  const percentage = isOverBudget
    ? ((globalCarbonEmissions - emissionsLimit) / emissionsLimit) * 100
    : (globalCarbonEmissions / emissionsLimit) * 100;
  const updatedGlobalCarbonEmissions = {
    isOverBudget,
    isOverBudgetValue: isOverBudget
      ? formatEmissions(globalCarbonEmissions - emissionsLimit)
      : null,
    percentage: percentage.toFixed(0),
    budget: formatDigits(emissionsLimit),
    emissions: formatEmissions(globalCarbonEmissions),
  };
  return updatedGlobalCarbonEmissions;
};
