import { Grid, Col } from '../../../assets/styles/grid';
import { Button } from '../../Button/Button';
import EmissionClockMoreHeader from './EmissionClockMoreHeader';
import { list } from './EmissionClockMoreList';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockMore = () => (
  <Grid
    as="section"
    cols={12}
    md={4}
    id="whatNow"
    tw="!pt-[7rem] md:!pt-[7rem]"
  >
    <EmissionClockMoreHeader />
    {list.map(({ title, text, image, button, link }) => (
      <Col key={title} as="article" col={4} md={4} tw="text-center">
        <img tw="m-[2.25rem auto]" src={image} alt={title} />
        <h3 tw="text-teal-500 font-semiBold text-[1.125rem]">{title}</h3>
        <p tw="text-gray-500 m-[0.5rem 1rem 2.5rem] min-h-[3.75rem] md:min-h-0">
          {text}
        </p>
        <Button
          as="a"
          href={link}
          tw="max-w-[11.5rem] flex items-center justify-center m-auto"
        >
          {button}
        </Button>
      </Col>
    ))}
  </Grid>
);

export default EmissionClockMore;
