import { useState, useEffect } from 'react';

const useActiveNavbarLink = () => {
  const [activeLink, setActiveLink] = useState('emissions');

  const handleActiveLink = () => {
    const observable = {
      emissions: document.querySelector('#emissions'),
      map: document.querySelector('#map'),
      sectors: document.querySelector('#sectors'),
      comparison: document.querySelector('#comparison'),
      whatNow: document.querySelector('#whatNow'),
    };
    const pos = {
      emissions: observable.emissions.getBoundingClientRect(),
      map: observable.map.getBoundingClientRect(),
      sectors: observable.sectors.getBoundingClientRect(),
      comparison: observable.comparison.getBoundingClientRect(),
      whatNow: observable.whatNow.getBoundingClientRect(),
    };

    if (pos.emissions.top < window.innerHeight && pos.emissions.bottom > 1)
      setActiveLink('emissions');
    else if (pos.map.top < window.innerHeight && pos.map.bottom > 1)
      setActiveLink('map');
    else if (pos.sectors.top < window.innerHeight && pos.sectors.bottom > 1)
      setActiveLink('sectors');
    else if (
      pos.comparison.top < window.innerHeight &&
      pos.comparison.bottom > 1
    )
      setActiveLink('comparison');
    else if (pos.whatNow.top < window.innerHeight && pos.whatNow.bottom > 1)
      setActiveLink('whatNow');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleActiveLink);
    return () => window.removeEventListener('scroll', handleActiveLink);
  }, []);

  return activeLink;
};

export default useActiveNavbarLink;
