import learnMore from '../../../assets/images/section5More/learnMore.svg';
import takeAction from '../../../assets/images/section5More/takeAction.svg';
import followUp from '../../../assets/images/section5More/followUp.svg';

export const list = [
  {
    title: 'Learn more',
    text: 'Detailed information about the methods used for this project',
    image: learnMore,
    button: 'Our methodology',
    link: 'Methodology/methodology.html',
  },
  {
    title: 'Take action',
    text: 'Find out what others are doing to promote a better climate future',
    image: takeAction,
    button: 'Further information',
    link: 'TakeAction/TakeAction.html',
  },
  {
    title: 'Follow up',
    text: 'See what legislators in your country are doing to reduce emissions.',
    image: followUp,
    button: 'Check NDCs',
    link: 'https://unfccc.int/',
  },
];
