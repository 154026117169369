import { useState, useRef } from "react";

const useOutsideClick = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    return [ref, isVisible, setIsVisible];
};

export default useOutsideClick;
