import React from 'react';
import bmz from '../../assets/images/footer/bmz.svg';
import giz from '../../assets/images/footer/giz.svg';
import mcgovern from '../../assets/images/footer/mcgovern.png';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const FooterSupported = () => (
  <>
    <h2 tw="text-black text-xs mb-[-1.5rem] pt-[1.5rem] ml-[0.5rem]">Supported by the</h2>
    <div tw="flex mt-[1.5rem] flex-wrap gap-[1.25rem]">
      <img
        tw="mr-[1.5rem]"
        width={214}
        height={65}
        src={bmz}
        alt="Federal Ministry for Economic Cooperation and Development"
      />
      <img width={186} height={48} src={giz} alt="GIZ" />
      <img width={200} height={48} src={mcgovern} alt="GIZ" />
    </div>
  </>
);

export default FooterSupported;
