/** @jsxImportSource @emotion/react */
import tw, { css, styled, theme } from 'twin.macro';

export const ProgressBarContainer = styled.div(({ isOverBudget }) => [
  tw`h-[0.93rem] w-[15.75rem] border border-gray-100 rounded-[6.68rem] overflow-hidden m-5 md:w-full`,
  css`
    background-color: ${isOverBudget && theme`colors.orange.400`};
  `,
]);

export const ProgressBar = styled.div(
  ({ percentage, isOverBudget, isFromCompare }) => [
    tw`h-full transition-all duration-1000`,
    css`
      max-width: 100%;
      width: ${Math.sign(percentage) === 1
        ? percentage
        : Math.abs(percentage)}%;
      background-color: ${isFromCompare && Math.sign(percentage) === 1
        ? theme`colors.orange.400`
        : isFromCompare && Math.sign(percentage) === -1
        ? theme`colors.teal.500`
        : isOverBudget
        ? theme`colors.red`
        : theme`colors.orange.400`};
    `,
  ]
);
