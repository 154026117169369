import React, { forwardRef } from "react";
import {
    oneOfType,
    func,
    shape,
    instanceOf,
    string,
    arrayOf,
    object,
    bool
} from "prop-types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Results = forwardRef(
    (
        {
            suggestions,
            handleCountryEmissions,
            errorMsg,
            isNotFromMap,
            handleClickToCountry,
            hideResults
        },
        searchRef
    ) => (
        <ul
            onClick={handleClickToCountry}
            className="scrollable"
            ref={searchRef}
            css={[
                tw`rounded-[0.5rem] mt-[0.5rem] w-[calc(100% - 2rem)] overflow-y-scroll max-h-[14.43rem] bg-white absolute shadow z-[1] md:w-full md:border-0 md:z-[401] md:rounded-none`,
                isNotFromMap && tw`w-full`
            ]}
        >
            <div onClick={hideResults}>
                {errorMsg && <li>{errorMsg && <p>{errorMsg}</p>}</li>}
                {suggestions.map(({ name, file_url, iso3c }, index) => (
                    <li
                        tw="text-sm text-gray-700 border-b border-gray-200 mr-[1rem]"
                        key={index}
                        name={iso3c}
                        onClick={(e) => {
                            isNotFromMap
                                ? handleCountryEmissions(e)
                                : handleCountryEmissions(iso3c);
                        }}
                    >
                        <button tw="flex p-[1rem] w-full items-center text-left">
                            {file_url && (
                                <img
                                    width={28}
                                    tw="mr-[1.125rem]"
                                    alt="Country Flag"
                                    src={file_url}
                                />
                            )}
                            <p
                                style={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}
                                tw="overflow-hidden"
                            >
                                {name}
                            </p>
                        </button>
                    </li>
                ))}
            </div>
        </ul>
    )
);

Results.propTypes = {
    handleCountryEmissions: func.isRequired,
    suggestions: arrayOf(object).isRequired,
    errorMsg: string,
    isNotFromMap: bool.isRequired,
    searchRef: oneOfType([func, shape({ current: instanceOf(Element) })])
};

Results.defaultProps = {
    handleCountryEmissions: () => {},
    suggestions: [{}],
    isNotFromMap: false,
    errorMsg: "An error has occurred"
};

export default Results;
