import {
  filterCountriesByYearAndEmissions,
  groupSubSectors,
  sortByEmissions,
} from './dataFormatting';

export const handleSubSectorsData = (
  data,
  selectedYear,
  activeScenario,
  selectedSector,
  sectorsCompositions
) => {
  let subSectors = filterCountriesByYearAndEmissions(
    selectedYear,
    data,
    activeScenario
  )
    .filter(({ sector }) => sector === selectedSector.type)
    .map((item) => {
      const findSubSector = sectorsCompositions.find(
        ({ subsector_short_name }) => subsector_short_name === item.subsector
      );
      return {
        ...item,
        subsector_long_name: findSubSector?.subsector_long_name,
        subsector_short_name: findSubSector?.subsector_short_name,
        emissions: item[activeScenario],
      };
    });
  const groupedSubSectors = groupSubSectors(
    subSectors,
    'subsector_long_name',
    activeScenario
  );
  const sortedCategories = sortByEmissions(groupedSubSectors);
  return (subSectors = {
    sector: selectedSector.type,
    categories: sortedCategories,
    emissions: subSectors.reduce((acc, curr) => acc + curr[activeScenario], 0),
    year: selectedYear,
    population: selectedSector.population,
    country: selectedSector.country,
  });
};
