import { Grid } from '../../../assets/styles/grid';
import EmissionClockCompareHeader from './EmissionClockCompareHeader';
import EmissionClockCompareResults from './EmissionClockCompareResults';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockCompare = ({
  selectedYear,
  activeScenario,
  emissions_main,
  countries_flags,
  countries_stats,
}) => (
  <Grid
    as="section"
    cols={12}
    md={4}
    id="comparison"
    tw="!pt-[7rem] md:!pt-[7rem]"
  >
    <EmissionClockCompareHeader />
    <EmissionClockCompareResults
      selectedYear={selectedYear}
      activeScenario={activeScenario}
      emissions_main={emissions_main}
      countries_flags={countries_flags}
      countries_stats={countries_stats}
    />
  </Grid>
);

export default EmissionClockCompare;
