import { useState } from "react";
import { ProgressBarContainer, ProgressBar } from "../ProgressBar/ProgressBar";
import Temperature from "../Temperature/Temperature";
import useScrollPosition from "../../custom-hooks/useScrollPosition";
import { handleHoverStyle } from "../../utils/common";
import { bool, shape, string, number, func } from "prop-types";
import tooltipIcon from "../../assets/images/tooltipIcon.svg";
import back from "../../assets/images/arrow-widget.svg";
import eye from "../../assets/images/eye.svg";
import thermometer from "../../assets/images/thermometer.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const GlobalCarbonBudget = ({
    isNotFromMap,
    globalCarbonEmissions,
    toggleModal,
    width,
    temperature
}) => {
    const verticalPosition = useScrollPosition();
    const [isGlobalCarbonBudgetActive, setIsGlobalCarbonBudgetActive] =
        useState(false);
    const [isTemperatureBoxOpen, setIsTemperatureBoxOpen] = useState(false);

    const handleToggleGlobalCarbonBudget = () =>
        setIsGlobalCarbonBudgetActive(!isGlobalCarbonBudgetActive);

    const handleToggleTemperatureBox = () => {
        setIsTemperatureBoxOpen(!isTemperatureBoxOpen);
        setIsGlobalCarbonBudgetActive(false);
    };

    return (
        <>
            <button
                onClick={handleToggleTemperatureBox}
                css={[
                    tw`fixed top-[5.8rem] z-[1001] right-[1.56rem] bg-white shadow rounded-[50%] border border-orange-400 hover:border-2 w-[2.75rem] h-[2.75rem] flex justify-center items-center sm:top-[12rem] sm:right-[1rem]`,
                    isTemperatureBoxOpen && tw`display[none]`
                ]}
            >
                <img src={thermometer} alt="Show temperture icon" />
            </button>
            {isTemperatureBoxOpen && (
                <div
                    style={{
                        transform: `rotateX(0) perspective(1000px) rotateY(${
                            isGlobalCarbonBudgetActive ? "180deg" : "0"
                        })`,
                        transformStyle: "preserve-3d"
                    }}
                    css={[
                        tw`flex md:opacity-100 transition duration-1000 flex-col rounded-[0.5rem] max-w-[21.375rem] p-[0.6rem 1rem] shadow bg-white md:hidden fixed right-[1%] top-[15vh] z-index[1001] w-full h-[11rem]`,
                        isNotFromMap && tw`shadow-none m-0 p-0 md:hidden`,
                        !isNotFromMap &&
                            tw`md:flex md:fixed md:max-w-full md:w-full md:top-[4.18rem] md:left-0 md:right-0 md:rounded-none md:z-[1001]`
                    ]}
                >
                    <div
                        style={{
                            backfaceVisibility: "hidden",
                            transform: "rotateX(0)",
                            transitionDuration: "600ms"
                        }}
                        css={[tw`absolute bg-white pr-[1rem]`]}
                    >
                        <button
                            tw="flex items-center gap-[0.231rem] text-orange-400"
                            onClick={handleToggleTemperatureBox}
                        >
                            Hide
                            <img src={eye} alt="eye" />
                        </button>
                        <h3 tw="text-gray-800 font-bold text-sm">
                            Global temperature above pre-industrial era
                        </h3>
                        <Temperature
                            temperature={temperature.temperature}
                            percentage={temperature.percentage}
                            isOverTemperature={temperature.isOverTemperature}
                        />
                        <p tw="text-xs font-medium text-gray-500">
                            The Paris Agreement aims to limit global warming in
                            2100 to 1.5°C
                        </p>
                        <button
                            tw="flex items-center gap-[0.5rem] text-xs font-medium ml-auto text-[#375EC0] mt-[0.5rem]"
                            onClick={handleToggleGlobalCarbonBudget}
                        >
                            Show global carbon budget
                            <img src={back} width={11} alt="back" />
                        </button>
                    </div>
                    <div
                        style={{
                            transform: "rotateX(0) rotateY(180deg)",
                            backfaceVisibility: "hidden"
                        }}
                        css={[tw`absolute bg-white w-[calc(100% - 2rem)]`]}
                    >
                        <div>
                            <button
                                tw="flex items-center gap-[0.231rem] text-orange-400"
                                onClick={handleToggleTemperatureBox}
                            >
                                Hide
                                <img src={eye} alt="eye" />
                            </button>
                            <div tw="flex items-center gap-[1.25rem]">
                                <h3 tw="text-gray-800 font-bold text-sm">
                                    Global Carbon Budget
                                </h3>
                                <span
                                    css={[
                                        tw`cursor-pointer relative`,
                                        handleHoverStyle(width)
                                    ]}
                                    title="Global Carbon Budget"
                                    message="The carbon budget refers to the amount of GHG emissions (Gigatons CO₂ equivalent) we can emit globally until the year 2100 which would allow us to achieve the Paris agreement of limiting global warming to 1.5 degrees above pre-industrial levels."
                                    onClick={
                                        width <= 768 ? toggleModal : undefined
                                    }
                                >
                                    <img alt="Tooltip icon" src={tooltipIcon} />
                                    <span tw="hidden">
                                        The carbon budget refers to the amount
                                        of GHG emissions (Gigatons CO₂
                                        equivalent) we can emit globally until
                                        the year 2100 which would allow us to
                                        achieve the Paris agreement of limiting
                                        global warming to 1.5 degrees above
                                        pre-industrial levels.
                                    </span>
                                </span>
                            </div>
                        </div>
                        <ProgressBarContainer
                            tw="m-[0.25rem 0 0.25rem] text-white text-center text-xs line-height[0.75rem]"
                            isOverBudget={globalCarbonEmissions.isOverBudget}
                        >
                            <ProgressBar
                                isOverBudget={
                                    globalCarbonEmissions.isOverBudget
                                }
                                percentage={globalCarbonEmissions.percentage}
                            >
                                {`${globalCarbonEmissions.percentage}%`}
                            </ProgressBar>
                        </ProgressBarContainer>
                        <p tw="text-xs font-medium text-gray-500">
                            {globalCarbonEmissions.isOverBudgetValue
                                ? "How much we passed the budget"
                                : "How much of the global carbon budget has already been used up"}
                        </p>
                        <button
                            tw="flex items-center gap-[0.5rem] text-xs font-medium ml-auto text-[#375EC0] absolute right-0 bottom-[-3.7rem]"
                            onClick={handleToggleGlobalCarbonBudget}
                        >
                            Show global temperature
                            <img src={back} width={11} alt="back" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

GlobalCarbonBudget.propTypes = {
    isNotFromMap: bool.isRequired,
    globalCarbonEmissions: shape({
        isOverBudget: bool.isRequired,
        percentage: string.isRequired,
        budget: string.isRequired,
        emissions: string.isRequired,
        isOverBudgetValue: string
    }).isRequired,
    width: number,
    toggleModal: func,
    selectedCountry: shape({
        iso3c: string
    })
};

GlobalCarbonBudget.defaultProps = {
    isNotFromMap: false,
    globalCarbonEmissions: {
        isOverBudget: false,
        percentage: "0",
        budget: "0",
        emissions: "0",
        isOverBudgetValue: null,
        width: null,
        toggleModal: () => {},
        selectedCountry: shape({
            iso3c: "WLD"
        })
    }
};

export default GlobalCarbonBudget;
