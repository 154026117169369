import { secondsPerYear } from "./common";

export const handleCountryEmissionsDetails = (
    currentTarget,
    countriesFlags,
    emissionsMain,
    selectedYear,
    activeScenario,
    countriesStats
) => {
    const countryID =
        typeof currentTarget === "string"
            ? currentTarget
            : currentTarget.getAttribute("name");
    let selectedCountry = countriesFlags.find(
        ({ iso3c }) => iso3c === countryID
    );
    const selectedCountrySectors = emissionsMain.filter(
        ({ year, iso3c }) =>
            year === selectedYear && iso3c === selectedCountry.iso3c
    );
    const selectedCountryPopulation = countriesStats.filter(
        ({ year, iso3c }) =>
            year === selectedYear && iso3c === selectedCountry.iso3c
    )[0]?.pop;
    const selectedCountryEmissions = selectedCountrySectors.reduce(
        (itemA, itemB) => itemA + itemB[activeScenario],
        0
    );
    return (selectedCountry = {
        ...selectedCountry,
        sectors: selectedCountrySectors.map((item) => {
            return {
                ...item,
                name: item.sector,
                isPlant: Math.sign(item[activeScenario]) === 1 ? false : true,
                emissions: item[activeScenario],
                population: selectedCountryPopulation
            };
        }),
        emissions: selectedCountryEmissions,
        emissions_per_person:
            selectedCountryEmissions / selectedCountryPopulation,
        emissions_per_second: selectedCountryEmissions / secondsPerYear,
        population: selectedCountryPopulation,
        year: selectedYear
    });
};

export const blockedTransportCountries = [
    "FJI",
    "HKG",
    "MAC",
    "MDV",
    "PNG",
    "SLB",
    "STP",
    "TLS",
    "TON",
    "VUT",
    "WSM"
];
