import { Col } from '../../../assets/styles/grid';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockCompareHeader = () => (
  <Col as="article" col={5} md={4}>
    <h2 tw="text-teal-500 font-semiBold text-[1.75rem] md:text-lg">
      Compare countries
    </h2>
    <p tw="mt-[1rem] text-gray-800 md:text-xs">
      Compare GHG emissions in CO₂eq across countries and discover their
      implementation and ambition gaps
    </p>
  </Col>
);

export default EmissionClockCompareHeader;
