import smoke from "../assets/images/section3Sectors/smoke.gif";
import plant from "../assets/images/section3Sectors/plant.gif";

export const comparedCountriesEmissionsPercentage = (
    comparedCountriesEmissionsPercentage,
    activeScenario
) => {
    comparedCountriesEmissionsPercentage =
        comparedCountriesEmissionsPercentage.map((comparedCountry) =>
            comparedCountry.sectors
                .map((sector) =>
                    Math.abs(
                        sector[activeScenario.id] / comparedCountry.population
                    )
                )
                .reduce((acc, itemB) => acc + itemB, 0)
        ) || "";
    return (comparedCountriesEmissionsPercentage = Math.max(
        ...comparedCountriesEmissionsPercentage
    ));
};

export const handleImage = (isPlant) => (isPlant ? plant : smoke);

export const handleEmissions = (obj, updatedSectors, index) =>
    Math.sign(obj.emissions < 0) ===
    Math.sign(updatedSectors[index].emissions < 0);

export const squaresAspectRatio = (
    country,
    sectors,
    animatedSectorIconHeight,
    countries_maximums_emissions
) => {
    const localMax = countries_maximums_emissions[country];
    const verticalTranslate = sectors.map(({ emissions }) => {
        const position = (animatedSectorIconHeight * emissions) / localMax;
        return position < 0 ? 231 : Math.round(position);
    });
    return { verticalTranslate, localMax };
};
