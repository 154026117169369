import { string } from "prop-types";
import { ButtonType, ButtonSize } from "./ButtonStyle";
/** @jsxImportSource @emotion/react */
import tw, { styled, css } from "twin.macro";

export const Button = styled.button(({ type, size }) => [
    tw`text-white font-semiBold rounded-[3.81rem] w-full h-[2.25rem]`,
    css`
        ${ButtonType[type]}
        ${ButtonSize[size]}

    &:hover {
            ${ButtonType[
                type === "emission" || type === "emissionFocus"
                    ? "emissionFocus"
                    : type === "sectors"
                    ? "sectorsFocus"
                    : "hover"
            ]}
        }

        &:focus {
            ${ButtonType[
                type === "emission" || type === "emissionFocus"
                    ? "emissionFocus"
                    : type === "sectors"
                    ? "sectorsFocus"
                    : "focus"
            ]}
        }
    `
]);

Button.propTypes = {
    type: string.isRequired,
    size: string
};

Button.defaultProps = {
    type: "primary",
    size: ""
};
