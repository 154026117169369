import { unzipFile } from "../utils/unzip";
import { useState, useEffect } from "react";

const useFetch = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const unzipAndfetch = async () => {
        try {
            const emissions_main = await unzipFile(
                "data/emissions_main.json.gz"
            );
            const emissions_world = await unzipFile(
                "data/emissions_world.json.gz"
            );
            const countries_stats = await unzipFile(
                "data/countries_stats.json.gz"
            );
            const country_sector_probabilities = await unzipFile(
                "data/country_sector_probabilities.json.gz"
            );
            const sectors_compositions = await unzipFile(
                "data/sectors_compositions.json.gz"
            );
            const subsectors_all = await unzipFile(
                "data/subsectors_all.json.gz"
            );
            const countries_geojson = await unzipFile(
                "data/map/countries_geojson.json.gz"
            );
            const south_africa_geojson = await unzipFile(
                "data/map/south_africa_region_geojson.json.gz"
            );
            const countries_maximums_emissions = await unzipFile(
                "data/countries_maximums_emissions.json.gz"
            );
            const emissions_cumulative = await unzipFile(
                "data/emissions_cumulative.json.gz"
            );
            const temperature_increase = await unzipFile(
                "data/temperature_increase.json.gz"
            );
            const classifications_income = await unzipFile(
                "data/classifications/income.json.gz"
            );
            let countries_flags = await unzipFile(
                "data/search/countries_flags.json.gz"
            );
            const countriesWithData = new Set(
                emissions_main.data.map(({ iso3c }) => iso3c)
            );
            countries_flags = [...countriesWithData]
                .map((countryWithData) => {
                    return countries_flags.data.filter(
                        ({ iso3c }) => iso3c === countryWithData
                    );
                })
                .flat();
            countries_flags = [countries_flags.pop(), ...countries_flags];
            countries_flags = {
                name: "countries_flag",
                data: countries_flags.flat()
            };
            setResponse({
                emissions_main,
                emissions_world,
                countries_stats,
                country_sector_probabilities,
                sectors_compositions,
                subsectors_all,
                countries_flags,
                countries_geojson,
                countries_maximums_emissions,
                emissions_cumulative,
                temperature_increase,
                south_africa_geojson,
                classifications_income
            });
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => unzipAndfetch(), []);

    return [response, loading, error];
};

export default useFetch;
