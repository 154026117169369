import React, { useState, useCallback, useEffect, useRef } from 'react';
import { SliderLi, SliderIndicator } from './StyledSlider';
import { yearRange } from '../../utils/slider';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

let offset = {};

const Slider = ({
  currentYear,
  handleSetSelectedYear,
  areCurrentYearEmissionsOverBudget,
  handleTimelineInteraction,
}) => {
  const [isButtonClicked, setButtonClicked] = useState(false);
  const container = useRef();
  const indicator = useRef();

  const handleMouseDown = () => setButtonClicked(true);

  const handleMouseUp = () => setButtonClicked(false);

  const handleMouseMove = useCallback(
    (e) => {
      if (!isButtonClicked) return;

      let left = e.clientX
        ? e.clientX - offset.left
        : e.touches[0].clientX - offset.left;
      left = left < 0 ? 0 : left;
      left = parseFloat(left > offset.width ? offset.width : left);
      indicator.current.style.left = `${left}px`;
      let year =
        yearRange.start +
        parseInt(
          Math.floor(((yearRange.end - yearRange.start) * left) / offset.width)
        );

      handleSetSelectedYear(year);
    },
    [isButtonClicked, handleSetSelectedYear]
  );

  useEffect(() => {
    offset = container.current.getBoundingClientRect();
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchup', handleMouseUp);
    window.addEventListener('touchmove', handleMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.addEventListener('touchup', handleMouseUp);
      window.addEventListener('touchmove', handleMouseMove);
    };
  }, [handleMouseMove]);

  return (
    <div
      tw="relative"
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      ref={container}
    >
      <ul tw="flex text-teal-300">
        {areCurrentYearEmissionsOverBudget.map((yearlyScenarioEmission) => (
          <SliderLi
            key={yearlyScenarioEmission.year}
            isOverBudget={yearlyScenarioEmission.isOverBudget}
          >
            <span
              css={[
                tw`w-full h-[0.125rem]`,
                yearlyScenarioEmission.isOverBudget
                  ? tw`bg-red `
                  : tw`bg-teal-300 `,
              ]}
            ></span>
            <span
              css={[
                tw`w-full mt-[0.6rem] absolute top-full left-[-50%] text-center text-gray-800 hidden`,
                yearlyScenarioEmission.isOverBudget && tw`text-red `,
              ]}
            >
              {yearlyScenarioEmission.year}
            </span>
          </SliderLi>
        ))}
      </ul>
      <SliderIndicator
        left={`${
          ((currentYear - yearRange.start) /
            (yearRange.end - yearRange.start)) *
          100
        }%`}
        ref={indicator}
      >
        <span tw="p-[0.56rem 0.68rem] absolute left-[50%] bottom-[3rem] bg-white text-blue translate-x-[-50%] border border-teal-200 rounded-[0.23rem] text-sm font-semiBold">
          {currentYear}
        </span>
        <button
          tw="flex justify-around p-[0.625rem] w-[2rem] h-[2rem] bg-white rounded-[50vw] shadow border border-teal-500"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStartCapture={handleMouseDown}
          onTouchEndCapture={handleMouseUp}
          aria-label="Slider button"
        >
          <span tw="h-[0.625rem] border-l border-l-gray-200"></span>
          <span tw="h-[0.625rem] border-l border-l-gray-200"></span>
        </button>
      </SliderIndicator>
    </div>
  );
};

export default Slider;
