import useFetch from "./custom-hooks/useFetch";
import Loader from "./components/Loader/Loader";
import EmissionClock from "./views/EmissionClock/EmissionClock";
import "./App.css";
import TagManager from "react-gtm-module";
import ErrorFallback from "./components/ErrorBoundary/ErrorFallback";

const tagManagerArgs = {
    gtmId: "G-XV9E5QKE1S"
};

TagManager.initialize(tagManagerArgs);

const App = () => {
    window.dataLayer.push({
        event: "pageview"
    });

    const [response, loading, error] = useFetch();

    return error ? (
        <ErrorFallback />
    ) : loading ? (
        <Loader type="main" />
    ) : (
        <EmissionClock
            emissions_world={response.emissions_world.data}
            emissions_main={response.emissions_main.data}
            countries_geojson={response.countries_geojson.features}
            south_africa_geojson={response.south_africa_geojson.features}
            countries_flags={response.countries_flags.data}
            country_sector_probabilities={
                response.country_sector_probabilities.data
            }
            sectors_compositions={response.sectors_compositions.data}
            subsectors_all={response.subsectors_all.data}
            countries_stats={response.countries_stats.data}
            temperature_increase={response.temperature_increase.data}
            countries_maximums_emissions={
                response.countries_maximums_emissions.data[0]
            }
            emissions_cumulative={response.emissions_cumulative.data}
            classifications_income={response.classifications_income.data}
        />
    );
};

export default App;
