/** @jsxImportSource @emotion/react */
import tw, { css, styled, theme } from 'twin.macro';

export const SliderLi = styled.li(({ isOverBudget }) => [
  tw`flex-1 flex items-end h-[0.31rem] relative border-l-0 text-sm`,
  css`
    &:last-child {
      flex: 0;

      span {
        display: none;
      }
    }

    &:nth-of-type(5n + 1) {
      border-left: 1.5px solid
        ${isOverBudget ? `${theme`colors.red`}` : `${theme`colors.teal.300`}`};

      span {
        display: inline-flex;
        justify-content: center;
      }
    }
  `,
]);

export const SliderIndicator = styled.div(({ left }) => [
  tw`m-[-1.25em] absolute top-[50%]`,
  css`
    left: ${left};
  `,
]);
