import { useState, useCallback, useEffect } from "react";
import FlipNumbers from "react-flip-numbers";
import useWindowSize from "../../../custom-hooks/useWindowsSize";
import {
    formatEmissions,
    currentYear,
    secondsPerYear
} from "../../../utils/common";
import { filterCountriesByYearAndEmissions } from "../../../utils/dataFormatting";
import { theme } from "twin.macro";

const now = Math.round(Date.now() / 1000);
const beginningCurrentYear = new Date(currentYear, 0, 0).getTime() / 1000;
const secondsUntilNow = now - beginningCurrentYear;

const EmissionsClockCounter = ({
    activeScenario,
    selectedYear,
    emissions_world,
    globalTons
}) => {
    const { width } = useWindowSize();
    const [currentYearEmissions, setCurrentYearEmissions] = useState(null);

    const calculateCurrentYearEmissions = useCallback(() => {
        const emissionsCurrentYear = filterCountriesByYearAndEmissions(
            selectedYear,
            emissions_world,
            activeScenario.id
        ).reduce((acc, item) => {
            if (currentYear === selectedYear) {
                return acc + item.base;
            } else {
                return acc + item[activeScenario.id];
            }
        }, 0);

        const emissionsPerSeconds = Math.trunc(
            emissionsCurrentYear / secondsPerYear
        );

        const secondsPerEmission = (1 / emissionsPerSeconds) * globalTons;

        const emissionsCurrentYearUntilNow =
            secondsUntilNow * emissionsPerSeconds;

        return {
            emissionsPerSeconds,
            secondsPerEmission,
            emissionsCurrentYearUntilNow
        };
    }, [activeScenario, selectedYear, emissions_world, globalTons]);

    useEffect(() => {
        setCurrentYearEmissions(
            calculateCurrentYearEmissions().emissionsCurrentYearUntilNow
        );
        const interval = setInterval(
            () =>
                setCurrentYearEmissions(
                    (prevEmissions) => prevEmissions + globalTons
                ),
            calculateCurrentYearEmissions().secondsPerEmission * 1000
        );

        return () => clearInterval(interval);
    }, [calculateCurrentYearEmissions, globalTons]);

    const styles = {
        color: theme`colors.orange.400`,
        fontSize: width <= 768 ? "2rem" : "3.875rem",
        fontWeight: 600,
        fontFamily: "Work Sans"
    };

    return (
        currentYearEmissions && (
            <FlipNumbers
                height={width <= 768 ? 30 : 50}
                width={width <= 768 ? 20 : 40}
                color={theme`colors.orange.400`}
                play
                perspective={100000}
                numbers={`${formatEmissions(currentYearEmissions).slice(
                    0,
                    -4
                )},000`}
                numberStyle={styles}
                nonNumberStyle={styles}
            />
        )
    );
};

export default EmissionsClockCounter;
