/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Temperature = ({ temperature, percentage, isOverTemperature }) => (
  <div tw="flex items-center m-[0.5rem 0]">
    <div tw="flex items-center">
      <div tw="h-[2.85rem] w-[2.85rem] p-[0.35rem] border border-gray-100 rounded-[50%]">
        <span
          css={[
            tw`w-full h-full block rounded-[50%] transition duration-700`,
            isOverTemperature ? tw`bg-red` : tw`bg-orange-400`,
          ]}
        ></span>
      </div>
    </div>
    <div tw="w-full border-t border-b border-t-gray-100 border-b-gray-100 border-r border-r-gray-100 h-[1.125rem] ml-[-0.5rem] rounded-r-[0.5rem] p-[0.22rem 0.22rem 0.22rem 0] relative">
      <div tw="absolute bg-white w-[0.25rem] h-[0.22rem] top-0 left-[0.25rem]"></div>
      <div
        css={[
          tw`h-full rounded-r-[0.5rem] transition duration-700`,
          isOverTemperature ? tw`bg-red` : tw`bg-orange-400`,
        ]}
        style={{ width: `${percentage}%` }}
      ></div>
      <div tw="absolute bg-white w-[0.25rem] h-[0.22rem] bottom-0 left-[0.25rem]"></div>
      <span
        style={{ width: `${percentage < 15 ? '16' : percentage}%` }}
        css={[
          tw`absolute max-w-[100%] text-xs top-[1rem] text-right transition duration-700`,
          isOverTemperature ? tw`text-red` : tw`text-orange-400`,
        ]}
      >
        {temperature} °C
      </span>
    </div>
  </div>
);

export default Temperature;
