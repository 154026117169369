export const handleTemperature = (
  temperature_increase,
  selectedYear,
  activeScenario
) => {
  const temperatureSelectedYearAndScenario = temperature_increase.find(
    ({ year }) => year === selectedYear
  )[activeScenario];
  const highestTemperatureSelectedYearAndScenario = temperature_increase.map(
    (item) => item[activeScenario]
  );
  const isOverTemperature = temperatureSelectedYearAndScenario.toFixed(2) > 1.5;
  const temperatureLimitHighest = 2;
  const temperatureLimitLowest = Math.min(
    ...highestTemperatureSelectedYearAndScenario
  );
  const percentage = (
    ((temperatureSelectedYearAndScenario - temperatureLimitLowest) * 100) /
    (temperatureLimitHighest - temperatureLimitLowest)
  ).toFixed(0);
  return {
    isOverTemperature,
    temperature: temperatureSelectedYearAndScenario.toFixed(2),
    percentage,
  };
};
