import React from 'react';
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import logoFooter from '../../assets/images/footer/logoFooter.png';

const FooterSignature = () => (
  <>
    <h2 tw="text-[2rem] font-bold w-[9.37rem] leading-[2.37rem] mr-[5.56rem]">
      World Emissions Clock
      <span tw="text-regular block mb-[0.75rem]">by</span>
      <span tw="text-[0.625rem] flex items-center">
        <img
          tw="mr-[0.93rem]"
          src={logoFooter}
          alt="World Emission Clock Footer"
        />
        world data lab
      </span>
    </h2>
  </>
);

export default FooterSignature;
