import DynamicSvg from '../../../DynamicSvg/DynamicSvg';
/** @jsxImportSource @emotion/react */
import { theme } from 'twin.macro';
import { formatEmissions } from '../../../../utils/common';

const Subsectors = ({ subSectors }) => (
  <ul
    tw="flex flex-col mt-[1.375rem] overflow-auto max-h-[14rem] pr-[1rem] sm:m-0 sm:p-[1rem] sm:max-h-[10rem]"
    className="scrollable scrollableSectors"
  >
    {subSectors.categories.map(
      ({ subsector_long_name, emissions, subsector, subsector_short_name }) =>
        subsector_long_name && emissions !== 0 && (
          <li
            key={subsector_short_name || subsector}
            tw="flex items-center justify-between mb-[1.625rem] gap-[0 1rem]"
          >
            <p tw="flex items-center text-gray-50 text-sm gap-[0 1.31rem]">
              <DynamicSvg
                iconName={subsector}
                size={24}
                fill={`${theme`colors.white`}`}
              />
              <span>
                {subsector_long_name === 'Other (industry)'
                  ? 'Other industries'
                  : subsector_long_name === 'Off-Road'
                  ? 'Rail & others'
                  : subsector_long_name}
              </span>
            </p>
            <p tw="font-bold text-gray-50">
              {formatEmissions(emissions, true)}
            </p>
          </li>
        )
    )}
  </ul>
);

export default Subsectors;
