import React, { useState, useEffect, useCallback } from 'react';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';
import DynamicSvg from '../../../DynamicSvg/DynamicSvg';
import useArrayOfScenarioProbs from '../../../../custom-hooks/useArrayOfScenarioProbs';
import {
  calculateDegrees,
  pickRandomElement,
} from '../../../../utils/globeSpinning';

const EmissionClockGlobeSpinningElement = ({
  country_sector_probabilities,
  index,
  degrees,
  selectedYear,
  activeScenario,
  countrySectorPairs,
  countries_flags,
}) => {
  const [groupedCountries, arrayOfProbabilities] = useArrayOfScenarioProbs(
    selectedYear,
    activeScenario,
    country_sector_probabilities,
    countries_flags
  );
  const [element, setElement] = useState(
    pickRandomElement(groupedCountries, arrayOfProbabilities)
  );

  const handleActiveElement = useCallback(() => {
    if (degrees > 352 || degrees < 7) {
      if (element.isActive === false) {
        setElement({
          ...element,
          isActive: true,
          isChangeble: true,
        });
      }
    } else {
      if (element.isActive === true) {
        setElement({
          ...element,
          isActive: false,
        });
      }
    }
  }, [degrees, element]);

  useEffect(
    () => handleActiveElement(),
    [
      handleActiveElement,
      arrayOfProbabilities,
      index,
      degrees,
      groupedCountries,
      element,
    ]
  );

  return (
    <>
      <DynamicSvg
        view="SpinningGlobe"
        i={index}
        iconName={element.iso3c === 'WLD' ? 'transport_ships' : element.sector}
        size={64}
        fill={`${
          degrees >= 0 && degrees < 180
            ? theme`colors.orange.400`
            : theme`colors.gray.100`
        }`}
      />
      {element.file_url && (
        <img
          data-active={element.isActive}
          data-sector={element.sector}
          data-name={element.name}
          data-ischangeble={element.isChangeble}
          data-angle={degrees}
          src={element.file_url}
          style={{
            filter: degrees >= 0 && degrees < 180 ? 'none' : 'grayscale(100%)',
          }}
          css={[
            tw`bottom-[50%] origin-[center bottom] absolute object-contain object-[0 0] h-[calc(62.125rem - 57%)] w-[1.56rem] left-[50%] md:w-[0.875rem] md:h-[calc(35.75rem - 57%)] xs:h-[calc(25.75rem - 57%)] xxs:h-[calc(22.75rem - 57%)]`,
            `transform: translate(-50%) rotate(calc(${calculateDegrees(
              countrySectorPairs,
              index
            )}deg))`,
          ]}
          alt="Country Flag"
        />
      )}
    </>
  );
};

export default EmissionClockGlobeSpinningElement;
