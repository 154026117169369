import React, { useState, useEffect, useCallback } from "react";
import DynamicSvg from "../../../DynamicSvg/DynamicSvg";
import SectorsDetails from "./SectorsDetails";
import { Button } from "../../../Button/Button";
import { formatEmissions, isNotEmptyObject } from "../../../../utils/common";
import arrow from "../../../../assets/images/arrow.svg";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

let timeout;

const SubRegionDetails = ({
    selectedSubRegion,
    activeScenario,
    closeDetailsCard
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOverviewActive, setIsOverviewActive] = useState(true);
    const [subSectors, setSubSectors] = useState({});

    useEffect(() => {
        if (isNotEmptyObject(subSectors)) {
            const updatedSubSectors = {
                sector: subSectors.sector,
                year: selectedSubRegion.year,
                emissions: selectedSubRegion.groupedSectors.find(
                    (item) => item.name === subSectors.sector
                ).total,
                region: selectedSubRegion.name,
                categories: [
                    ...selectedSubRegion.groupedSectors.find(
                        (item) => item.name === subSectors.sector
                    ).grouped
                ]
            };
            setSubSectors(updatedSubSectors);
        }
    }, [selectedSubRegion]);

    const showSectorDetails = (sector) => {
        const subSectors = {
            sector: sector.grouped[0].sector,
            year: selectedSubRegion.year,
            emissions: sector.total,
            region: selectedSubRegion.name,
            categories: [...sector.grouped]
        };
        setSubSectors(subSectors);
        timeout = setTimeout(() => setIsOpen(true), 100);
    };
    const hideSectorDetails = useCallback(() => setIsOpen(false), []);

    useEffect(() => {
        setIsOpen(false);
        return () => clearTimeout(timeout);
    }, [selectedSubRegion.name]);

    const toggleOverview = () => setIsOverviewActive((prevState) => !prevState);

    return (
        <div
            className="scrollable"
            css={[
                isNotEmptyObject(selectedSubRegion) &&
                    tw`md:max-h-[calc(100vh - 202px)]`
            ]}
        >
            <div tw="flex">
                <button
                    disabled={isOverviewActive}
                    css={[
                        tw`h-[3rem] w-full hover:bg-teal-100 border-gray-100 border-b hover:border-b-2`,
                        isOverviewActive &&
                            tw`border-teal-800 border-b-2 bg-[#F5FDFC] font-semiBold text-teal-800`
                    ]}
                    onClick={toggleOverview}
                >
                    Overview
                </button>
                <button
                    disabled={!isOverviewActive}
                    css={[
                        tw`h-[3rem] w-full hover:bg-teal-100 border-gray-100 border-b hover:border-teal-800 hover:border-b-2`,
                        !isOverviewActive &&
                            tw`border-teal-800 border-b-2 bg-[#F5FDFC] font-semiBold text-teal-800`
                    ]}
                    onClick={toggleOverview}
                >
                    Sectors
                </button>
            </div>
            {isOverviewActive ? (
                <div tw="p-[1rem]">
                    <div tw="rounded-[0.5rem] border border-gray-100 p-[0.5rem] flex flex-col gap-[0.5rem] text-xs text-gray-800">
                        <h3>
                            GHG emissions in C0₂eq {selectedSubRegion.year}:
                        </h3>
                        <span tw="font-semiBold text-lg text-orange-400">
                            {formatEmissions(selectedSubRegion.emissions, true)}
                        </span>
                    </div>

                    <div tw="rounded-[0.5rem] border border-gray-100 p-[0.5rem] flex flex-col gap-[0.5rem] text-xs text-gray-800 mt-[1rem]">
                        <div tw="flex flex-col gap-[0.5rem]">
                            <h4>Main emitting sector:</h4>
                            <div tw="flex items-center gap-[0.5rem]">
                                <DynamicSvg
                                    iconName={
                                        selectedSubRegion.highest_emissions.name
                                    }
                                    size={24}
                                    fill={`${theme`colors.orange.400`}`}
                                />
                                <p tw="font-semiBold text-orange-400 text-regular">
                                    {formatEmissions(
                                        selectedSubRegion.highest_emissions
                                            .total,
                                        true
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <h3 tw="font-semiBold text-xs text-gray-500 mt-[0.85rem] pl-[1rem]">
                        Emissions per sector:
                    </h3>
                    {selectedSubRegion.groupedSectors.map((sector) => (
                        <button
                            tw="flex items-center justify-between p-[1.06rem 1rem] border-b border-gray-200 w-full text-sm text-gray-700 capitalize"
                            key={sector.grouped[0].sector}
                            name={sector.grouped[0].sector}
                            onClick={() => showSectorDetails(sector)}
                        >
                            <div tw="flex items-center">
                                <DynamicSvg
                                    iconName={sector.grouped[0].sector}
                                    size={32}
                                    fill={`${theme`colors.black`}`}
                                />
                                <h4 tw="ml-[1.2rem] text-gray-800">
                                    {sector.grouped[0].sector === "Energy"
                                        ? "Energy Systems"
                                        : sector.grouped[0].sector}
                                </h4>
                            </div>
                            <div tw="flex font-semiBold text-orange-400">
                                <p>{formatEmissions(sector.total, true)} </p>
                                <img
                                    tw="rotate-[-90deg] ml-[1rem]"
                                    alt="Arrow"
                                    src={arrow}
                                />
                            </div>
                        </button>
                    ))}
                </div>
            )}
            <div tw="hidden md:flex flex-col p-[1.5rem] items-center border-t border-gray-200 md:w-full md:absolute md:bottom-0">
                <Button
                    type="modal"
                    onClick={closeDetailsCard}
                    tw="max-w-[5.93rem]"
                >
                    Close
                </Button>
            </div>
            {isNotEmptyObject(subSectors) && (
                <SectorsDetails
                    isOpen={isOpen}
                    subSectors={subSectors}
                    hideSectorDetails={hideSectorDetails}
                    isForSubRegion={true}
                    activeScenario={activeScenario}
                />
            )}
        </div>
    );
};

export default SubRegionDetails;
