import twitter from '../../assets/images/sidebar/twitter.svg';
import facebook from '../../assets/images/sidebar/facebook.svg';
import linkedIn from '../../assets/images/sidebar/linkedIn.svg';
import email from '../../assets/images/sidebar/email.svg';
import whatsapp from '../../assets/images/sidebar/whatsapp.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const SocialSidebar = () => (
  <aside tw="md:hidden flex justify-evenly flex-col z-[1002] top-[43%] gap-[1rem] rounded-tl-2xl rounded-bl-2xl bg-white border border-gray-100 p-[0.625rem 0.3125rem] fixed right-0">
    <img
      tw="cursor-pointer opacity-70 hover:opacity-100"
      target="popup"
      onClick={() =>
        window.open(
          'https://twitter.com/intent/tweet?url=https://worldemissions.io&text=World%20Emission%20Clock,%20discover%20how%20much%20your%20country%20is%20emitting&via='
        )
      }
      src={twitter}
      alt="twitter"
    />
    <img
      tw="cursor-pointer opacity-75 hover:opacity-100"
      target="popup"
      onClick={() =>
        window.open(
          'https://www.facebook.com/sharer/sharer.php?u=https://worldemissions.io'
        )
      }
      src={facebook}
      alt="facebook"
    />
    <img
      tw="cursor-pointer opacity-75 hover:opacity-100"
      onClick={() =>
        window.open(
          'https://www.linkedin.com/shareArticle?url=https://worldemissions.io&title=World%20Emission%20Clock,%20discover%20how%20much%20your%20country%20is%20emitting'
        )
      }
      src={linkedIn}
      alt="linkedIn"
    />
    <a href="mailto:?subject=World%20Emission%20Clock%2C%20discover%20how%20much%20your%20country%20is%20emitting&body=https:%2F%2Fworldemissions.io%2F">
      <img
        tw="cursor-pointer opacity-75 hover:opacity-100"
        src={email}
        alt="email"
      />
    </a>
    {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
      navigator.userAgent
    ) && (
      <img
        tw="cursor-pointer opacity-75 hover:opacity-100"
        target="popup"
        onClick={() =>
          window.open(
            'https://api.whatsapp.com/send?text=World Emission Clock, discover how much your country is emitting https://worldemissions.io/ ',
            'Popup',
            'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=680, height=400, top=30'
          )
        }
        src={whatsapp}
        alt="whatsapp"
      />
    )}
  </aside>
);

export default SocialSidebar;
