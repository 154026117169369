import React, { forwardRef } from 'react';
import { Col } from '../../../assets/styles/grid';
import InputSearch from '../../Search/InputSearch';
import {
  oneOfType,
  func,
  shape,
  instanceOf,
  string,
  number,
  arrayOf,
  object,
  bool,
} from 'prop-types';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockSectorsHeader = forwardRef(
  (
    {
      countries_flags,
      handleSearch,
      handleCountryEmissions,
      activeIcon,
      searchTerm,
      totalEmissions,
      globalCarbonEmissions,
      resetSearch,
    },
    searchRef
  ) => (
    <>
      <Col as="article" col={4} md={4} tw="md:p-[1rem]" id="SectorsHeader">
        <h2 tw="text-teal-500 font-semiBold text-[1.75rem] md:text-lg md:mb-[0.75rem]">
          Sectors Emissions
        </h2>
        <p tw="text-gray-500 text-sm md:text-xs">
          See in which sectors most GHG emissions are occurring
        </p>
        <div>
          <h4 tw="font-semiBold text-gray-800 pt-[2rem] pb-[1rem]">
            Search for a country
          </h4>
          <InputSearch
            countries_flags={countries_flags}
            handleSearch={handleSearch}
            searchTerm={searchTerm}
            selectedCountry={totalEmissions}
            handleCountryEmissions={handleCountryEmissions}
            activeIcon={activeIcon}
            isNotFromMap={true}
            ref={searchRef}
            resetSearch={resetSearch}
          />
        </div>
      </Col>
    </>
  )
);

EmissionClockSectorsHeader.propTypes = {
  totalEmissions: oneOfType([
    shape({
      emissions: number.isRequired,
      emissions_per_person: number.isRequired,
      emissions_per_second: number.isRequired,
      file_url: string.isRequired,
      iso3c: string.isRequired,
      license: string.isRequired,
      name: string.isRequired,
      population: number.isRequired,
      sectors: arrayOf(object),
      year: number.isRequired,
    }),
    object,
  ]),
  resetSearch: func.isRequired,
  handleSearch: func.isRequired,
  handleCountryEmissions: func.isRequired,
  activeIcon: string.isRequired,
  searchTerm: string.isRequired,
  searchRef: oneOfType([
    func,
    shape({ current: instanceOf(Element).isRequired }),
  ]),
  globalCarbonEmissions: oneOfType([
    shape({
      isOverBudget: bool.isRequired,
      percentage: string.isRequired,
      budget: string.isRequired,
      emissions: string.isRequired,
      isOverBudgetValue: string,
    }).isRequired,
    object,
  ]),
};

EmissionClockSectorsHeader.defaultProps = {
  handleSearch: () => {},
  handleCountryEmissions: () => {},
  activeIcon: 'search',
  searchTerm: '',
  globalCarbonEmissions: {
    isOverBudget: false,
    percentage: '0',
    budget: '0',
    emissions: '0',
    isOverBudgetValue: null,
  },
  totalEmissions: {},
};

export default EmissionClockSectorsHeader;
