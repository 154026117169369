import { useEffect, useState } from 'react';

const useOnWindowBlur = () => {
  const [isWindowOnBlur, setIsWindowOnBlur] = useState(false);

  useEffect(() => {
    window.onblur = () => setIsWindowOnBlur(true);
    window.onfocus = () => setIsWindowOnBlur(false);
  }, []);

  return isWindowOnBlur;
};

export default useOnWindowBlur;
