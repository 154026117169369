import React, { useState, useCallback, useRef } from 'react';
import { Grid } from '../../../assets/styles/grid';
import EmissionClockSectorsHeader from './EmissionClockSectorsHeader';
import EmissionClockAnimatedSectors from './EmissionClockAnimatedSectors';
import { handleCountryEmissionsDetails } from '../../../utils/countries';
import useLoadCountriesEmissions from '../../../custom-hooks/useLoadCountriesEmissions';
import { isNotEmptyObject } from '../../../utils/common';
import useSubSectors from '../../../custom-hooks/useSubSectors';
import search from '../../../assets/images/search.svg';
import reset from '../../../assets/images/reset.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const EmissionClockSectors = ({
  globalCarbonEmissions,
  selectedYear,
  isTimelineClicked,
  activeScenario,
  countries_geojson,
  countries_flags,
  emissions_main,
  countries_stats,
  subsectors_all,
  sectors_compositions,
  countries_maximums_emissions,
}) => {
  const searchRef = useRef();
  const [selectedSector, setSelectedSector] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const { totalEmissions, countryCoordinatesAndEmissions } =
    useLoadCountriesEmissions(
      selectedYear,
      activeScenario.id,
      selectedCountry,
      countries_geojson,
      emissions_main,
      countries_flags,
      countries_stats
    );
  const { subSectors, sortedRankings } = useSubSectors(
    selectedYear,
    activeScenario.id,
    selectedSector,
    selectedCountry,
    countryCoordinatesAndEmissions,
    countries_stats,
    subsectors_all,
    sectors_compositions
  );
  const [selectedSectorList, setSelectedSectorList] = useState('Subsector');
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIcon, setActiveIcon] = useState(search);

  const closeDetailsCard = useCallback(() => {
    setSelectedSector({});
    setSelectedSectorList('Subsector');
  }, []);

  const handleActiveSectorType = useCallback(
    ({ currentTarget }) => setSelectedSectorList(currentTarget.name),
    []
  );

  const handleSearch = useCallback(({ target }) => {
    const { value } = target;
    setActiveIcon(value === '' ? search : reset);
    setSearchTerm(value);
    searchRef.current(value);
  }, []);

  const resetSearch = useCallback(() => {
    document.querySelector('#SectorsHeader .selected').style.display = 'none';
    setSelectedCountry({});
    setSearchTerm('');
    setActiveIcon(search);
    searchRef.current('');
    closeDetailsCard();
  }, [closeDetailsCard]);

  const handleCountryEmissions = ({ currentTarget }) => {
    setSelectedSector({});
    const selectedCountry = handleCountryEmissionsDetails(
      currentTarget,
      countries_flags,
      emissions_main,
      selectedYear,
      activeScenario,
      countries_stats
    );
    selectedCountry && setSelectedCountry(selectedCountry);
    setTimeout(
      () =>
        (document.querySelector('#SectorsHeader .selected').style.display =
          'flex'),
      150
    );
  };

  const handleSelectedSector = useCallback(
    ({ currentTarget }) => {
      const selectedSector = {
        type: currentTarget.name,
        iso3c: selectedCountry.iso3c,
        country: selectedCountry.name,
        population: selectedCountry.population,
      };
      setSelectedSector(selectedSector);
    },
    [selectedCountry]
  );

  return (
    <Grid
      as="section"
      cols={12}
      md={4}
      id="sectors"
      tw="bg-white relative !pt-[7rem] md:!pt-[7rem] md:p-0"
    >
      <EmissionClockSectorsHeader
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        totalEmissions={totalEmissions}
        handleCountryEmissions={handleCountryEmissions}
        activeIcon={activeIcon}
        ref={searchRef}
        globalCarbonEmissions={globalCarbonEmissions}
        resetSearch={resetSearch}
        countries_flags={countries_flags}
      />
      {isNotEmptyObject(totalEmissions) && (
        <EmissionClockAnimatedSectors
          handleSelectedSector={handleSelectedSector}
          handleActiveSectorType={handleActiveSectorType}
          selectedSectorList={selectedSectorList}
          totalEmissions={totalEmissions}
          closeDetailsCard={closeDetailsCard}
          activeScenario={activeScenario.id}
          subSectors={subSectors}
          selectedSector={selectedSector}
          sortedRankings={sortedRankings}
          isTimelineClicked={isTimelineClicked}
          countries_maximums_emissions={countries_maximums_emissions}
        />
      )}
    </Grid>
  );
};

export default EmissionClockSectors;
