import { InputType } from './InputStyle';
import { string } from 'prop-types';
/** @jsxImportSource @emotion/react */
import tw, { css, styled } from 'twin.macro';

export const Input = styled.input(({ type, bgImage }) => [
  tw`w-full border-b border-gray-700`,
  css`
    ${InputType[type]}
    background: ${type === 'search' &&
    `transparent url(${bgImage}) no-repeat calc(100% - 0.9rem)`}
  `,
]);

Input.propTypes = {
  type: string.isRequired,
  bgImage: string,
};

Input.defaultProps = {
  type: '',
  bgImage: '',
};
