import DynamicSvg from "../../../DynamicSvg/DynamicSvg";
import {
    formatEmissions,
    isNotEmptyObject,
    secondsPerYear
} from "../../../../utils/common";
import back from "../../../../assets/images/back.svg";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";
import { Button } from "../../../Button/Button";

const SectorsDetails = ({
    isOpen,
    subSectors,
    hideSectorDetails,
    isForSubRegion = false,
    activeScenario
}) => (
    <aside
        css={[
            tw`top-0 absolute bg-white w-full h-full translate-x-[-100%] transition-all duration-500 rounded-[0.5rem] md:flex md:flex-col md:justify-between`,
            isOpen && tw`translate-x-0 md:top-[2rem] md:h-[calc(100% - 2rem)]`
        ]}
    >
        <div>
            <img
                tw="m-[1rem 1rem 0] cursor-pointer md:hidden"
                onClick={hideSectorDetails}
                alt="Back"
                src={back}
            />
            <div tw="flex p-[1rem] items-start">
                <DynamicSvg
                    iconName={subSectors.sector}
                    size={32}
                    fill={`${theme`colors.gray.800`}`}
                />
                <h4 tw="text-gray-800 font-semiBold text-xl ml-[0.5rem]">
                    {subSectors.sector === "Energy"
                        ? "Energy Systems"
                        : subSectors.sector}
                    <span tw="block text-gray-400 text-sm font-regular">
                        {isForSubRegion
                            ? `${subSectors.region} of South Africa`
                            : `of ${subSectors.country}`}
                    </span>
                </h4>
            </div>
            <div tw="rounded-[0.5rem] border border-gray-100 p-[0.5rem] m-[0 1rem] flex flex-col gap-[0.5rem] text-xs text-gray-800">
                <h3>GHG emissions in C0₂eq {subSectors.year}:</h3>
                <span tw="font-semiBold text-lg text-orange-400">
                    {formatEmissions(subSectors.emissions, true)}
                </span>
            </div>
            {!isForSubRegion && (
                <div tw="rounded-[0.5rem] border border-gray-100 p-[0.5rem] m-[0 1rem] flex flex-col gap-[0.5rem] text-xs text-gray-800 mt-[1rem]">
                    <div tw="flex justify-between">
                        <div tw="flex flex-col gap-[0.5rem]">
                            <h4>Emissions per-person:</h4>
                            <p tw="font-semiBold text-orange-400 text-regular">
                                {(
                                    subSectors.emissions / subSectors.population
                                ).toFixed(1)}{" "}
                                tons
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <h3 tw="font-bold text-regular text-gray-800 p-[0.93rem 1rem 0.5rem] mt-[1rem]">
                Sub-sectors
            </h3>
            {isForSubRegion && (
                <div
                    className="scrollable"
                    css={[
                        tw`max-h-[20rem] overflow-y-scroll`,
                        isNotEmptyObject(subSectors) &&
                            tw`md:max-h-[calc(100vh - 27.3rem)]`
                    ]}
                >
                    {subSectors.categories.map(
                        (item) =>
                            item[activeScenario.id] !== 0 && (
                                <div
                                    key={item.subsector}
                                    tw="p-[1rem] border-b border-gray-200 flex justify-between items-center"
                                >
                                    <div tw="text-gray-500 text-sm">
                                        <DynamicSvg
                                            iconName={item.subsector}
                                            size={32}
                                            fill={`${theme`colors.gray.800`}`}
                                        />
                                        <h4 tw="font-medium text-gray-800 mt-[0.5rem]">
                                            {item.subsector_long_name ===
                                            "Other (industry)"
                                                ? "Other industries"
                                                : item.subsector_long_name ===
                                                  "Off-Road"
                                                ? "Rail & others"
                                                : item.subsector_long_name}
                                        </h4>
                                        <p tw="text-sm font-regular text-orange-400">
                                            {(
                                                item[activeScenario.id] /
                                                secondsPerYear
                                            ).toFixed(1)}{" "}
                                            tons/sec
                                        </p>
                                    </div>
                                    <p tw="text-sm font-semiBold text-orange-400">
                                        {formatEmissions(
                                            item[activeScenario.id],
                                            true
                                        )}
                                    </p>
                                </div>
                            )
                    )}
                </div>
            )}
            {!isForSubRegion && (
                <div
                    className="scrollable"
                    css={[
                        tw`max-h-[13rem] overflow-y-scroll`,
                        isNotEmptyObject(subSectors) &&
                            tw`md:max-h-[calc(100vh - 27.3rem)]`
                    ]}
                >
                    {subSectors.categories.map(
                        ({ subsector, subsector_long_name, emissions }) =>
                            subsector_long_name &&
                            emissions !== 0 && (
                                <div
                                    key={subsector}
                                    tw="p-[1rem] border-b border-gray-200 flex justify-between items-center"
                                >
                                    <div tw="text-gray-500 text-sm">
                                        <DynamicSvg
                                            iconName={subsector}
                                            size={32}
                                            fill={`${theme`colors.gray.800`}`}
                                        />
                                        <h4 tw="font-medium text-gray-800 mt-[0.5rem]">
                                            {subsector_long_name ===
                                            "Other (industry)"
                                                ? "Other industries"
                                                : subsector_long_name ===
                                                  "Off-Road"
                                                ? "Rail & others"
                                                : subsector_long_name}
                                        </h4>
                                        <p tw="text-sm font-regular text-orange-400">
                                            {(
                                                emissions /
                                                subSectors.population
                                            ).toFixed(1)}{" "}
                                            tons/person
                                        </p>
                                    </div>
                                    <p tw="text-sm font-semiBold text-orange-400">
                                        {formatEmissions(emissions, true)}
                                    </p>
                                </div>
                            )
                    )}
                </div>
            )}
        </div>
        <div tw="hidden md:flex flex-col p-[1.5rem] items-center border-t border-gray-200 md:w-full md:absolute md:bottom-0 bg-white">
            <Button
                type="modal"
                onClick={hideSectorDetails}
                tw="max-w-[5.93rem]"
            >
                Back
            </Button>
        </div>
    </aside>
);

export default SectorsDetails;
