import { useEffect } from 'react';
import { formatEmissions } from '../../../../utils/common';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Rankings = ({ sortedRankings, totalEmissions }) => {
  useEffect(() => {
    const indexSelectedCountry = sortedRankings.findIndex(
      ({ id }) => id === totalEmissions.iso3c
    );
    const scrollToElement = document.querySelector(
      `[scrollhere="${indexSelectedCountry}"]`
    );
    const timeout = setTimeout(() => {
      if (scrollToElement)
        scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 500);

    return () => {
      window.scrollBy(0, 1);
      clearTimeout(timeout);
    };
  }, [totalEmissions.iso3c, sortedRankings]);

  return (
    <ul
      tw="flex flex-col mt-[1.375rem] overflow-auto max-h-[12rem] sm:px-[1rem] sm:max-h-[10rem]"
      className="scrollable scrollableSectors"
    >
      {sortedRankings.map(({ id, name, file_url, selectedSector }, i) => (
        <li
          key={id}
          scrollhere={i}
          css={[
            tw`flex items-center justify-between border-b  text-white border-b-white p-[0 0.5rem] mr-[0.5rem]`,
            totalEmissions.type !== 'global' &&
              totalEmissions.iso3c === id &&
              tw`bg-white text-gray-700`,
          ]}
        >
          <p tw="flex items-center font-semiBold text-sm">
            <span>{i + 1}</span>
            <img
              tw="m-[0.625rem]"
              width={24}
              src={file_url}
              alt="Country flag"
            />
            <span>{name}</span>
          </p>
          <p>{formatEmissions(selectedSector, true)}</p>
        </li>
      ))}
    </ul>
  );
};

export default Rankings;
