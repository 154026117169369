import React, { useState } from 'react';
import { links } from './NavbarList.js';
import { BurgerMenu, BurgeMenuLine } from './BurgerMenu';
import useActiveNavbarLink from '../../custom-hooks/useActiveNavbarLink';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const activeLink = useActiveNavbarLink();

  const handleClick = () => {
    setIsOpen(false);
    setTimeout(
      () =>
        window.history.replaceState(
          '',
          document.title,
          window.location.pathname
        ),
      1
    );
  };

  return (
    <>
      <ul
        css={[
          tw`flex justify-between md:absolute md:inset-0 md:flex-col md:justify-evenly md:text-center md:mt-[4.18rem] md:translate-x-[-100%] transition md:duration-500 bg-white md:h-[calc(100vh - 4.18rem)]`,
          isOpen && tw`md:translate-x-[0]`,
        ]}
      >
        {links.map(({ name, id }) => (
          <li tw="relative" key={id}>
            <a
              css={[
                tw`hover:text-teal-500`,
                activeLink === id && tw`text-teal-500`,
              ]}
              onClick={handleClick}
              href={`#${id}`}
            >
              {name}
            </a>
            {activeLink === id && (
              <span tw="absolute bottom-[-1.375rem] left-0 w-full h-[0.18rem] bg-teal-500 md:hidden"></span>
            )}
          </li>
        ))}
      </ul>
      <BurgerMenu
        tw="hidden md:flex"
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Mobile button"
      >
        <BurgeMenuLine />
        <BurgeMenuLine />
        <BurgeMenuLine />
      </BurgerMenu>
    </>
  );
};

export default Navbar;
