export const calculateDegrees = (emissionsDataLength, i) =>
  (i * 360) / emissionsDataLength;

export const pickRandomElement = (items, weights) => {
  for (let i = 0; i < weights.length; i++) weights[i] += weights[i - 1] || 0;
  const random = Math.random() * weights[weights.length - 1];
  return (items = {
    ...items[weights.findIndex((el) => el > random)],
    isActive: false,
    isChangeble: false,
  });
};
